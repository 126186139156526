import dayjs from "dayjs";

export const parseDatesInRow = ({windowData}) => {
  const columns = windowData.Columns;
  const newRow = {...windowData.Rows[0]};

  Object.entries(newRow).forEach(([key, value]) => {
    const column = columns[key];
    const isDateWithoutTime =
      column?.Type === "Date" ||
      column?.Editor === "dateCalendar" ||
      column?.Editor === "dateCalendarOpeningsDay";

    // Remove times from dates since we are working with whole days
    if (isDateWithoutTime && value?.includes("T")) {
      newRow[key] = value.split("T")[0];
    }

    // Convert date format from "DD-MM-YYYY" to "YYYY-MM-DD" because that's what the server uses
    if (isDateWithoutTime && dayjs(value, "DD-MM-YYYY", true).isValid()) {
      newRow[key] = dayjs(value, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
  });

  return newRow;
};
