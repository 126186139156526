import {setupCache} from "axios-cache-interceptor";
const axios = require("axios");
import store from "../../state/store";

const axiosInstance = axios.create({
  baseURL: "/",
});

const cachingNonActionAxiosInstance = setupCache(axiosInstance, {
  ttl: 1000 * 10,
});

cachingNonActionAxiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${store.state.accessToken}`;
  config.headers.SessionID = store.state.sessionId;
  config.headers["Content-Language"] = store.state.language;
  config.headers["NormalizeEnums"] = true;

  return config;
});

export {cachingNonActionAxiosInstance};
