export const handleReferencedWindowFormFieldChange = ({
  rWindowData,
  windowId,
  vueInstance,
}) => {
  vueInstance.rWindows = {
    ...vueInstance.rWindows,
    [windowId]: {...vueInstance.rWindows[windowId], data: rWindowData},
  };
};
