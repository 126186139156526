import {getFirstDirtyFieldInWindow} from "../fields/getFirstDirtyFieldInWindow";
import {getWindowFromWindowId} from "./getWindowFromWindowId";

export const focusDirtyWindow = ({window}) => {
  const fieldToFocus = getFirstDirtyFieldInWindow({
    window,
  });
  const legacyWindow = getWindowFromWindowId({
    windowId: window.id,
    session: global.session,
  });

  const windowToFocus = legacyWindow?.parent ?? legacyWindow;

  legacyWindow?.focus(windowToFocus, fieldToFocus?.Name);
};
