import {getTranslations} from "../../session/localstorage/getTranslations.js";
import {alertPopup} from "../../../interface/alertPopup/alertPopup.js";

export const alertSerialDateConflict = async ({
  serialId,
  row,
  conflictingRow,
}) => {
  const ranking = row.Ranking.Value;
  const conflictingRowRanking = conflictingRow.row.Ranking.Value;

  let message = `${getTranslations().serialRowsHaveDateConflict}`
    .replace("{ranking1}", ranking)
    .replace("{ranking2}", conflictingRowRanking)
    .replace("{serialId}", serialId);

  await alertPopup({
    icon: "warning",
    text: message,
  });
};
