export default function setDocumentTitle(title = "", warehouse = "") {
  const href = window.location.href ?? "";
  const safeTitle = String(title || "");
  const safeWarehouse = String(warehouse || "");
  let environment = "";

  if (href.includes("test")) {
    environment = "[TEST] ";
  }

  if (href.includes("localhost")) {
    environment = "🛠️ [DEVELOPMENT] ";
  }

  const formattedTitle = [environment, safeWarehouse, safeTitle, `| RentMagic`]
    .filter(Boolean)
    .join(" ");

  document.title = formattedTitle;
}
