import store from "../../../../state/store";

export const closeAllWindows = async ({vueInstance}) => {
  vueInstance.rWindows = {};

  global.session.tabs = [];
  global.session.windows = {};
  global.session.activeWindow = null;
  store.state.tabs = [];

  await store.commit("refreshTabs");
  store.commit("updateWindow");
};
