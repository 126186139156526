export const createAlertButtons = (translations) => ({
  confirmButton: {
    text: translations.Continue,
    value: true,
    variant: "primary",
  },
  cancelButton: {
    text: translations.Cancel,
    value: false,
    variant: "secondary",
  },
});
