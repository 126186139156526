import {getColumns} from "../../datagrid/columns/getColumns.js";
import {getTranslations} from "../../session/localstorage/getTranslations.js";

const translations = getTranslations();

export const generateEntityFieldFilter = async ({
  viewFilters,
  columns,
  columnsUnchanged,
}) => {
  if (!viewFilters[0].Selected) {
    const changedViewFilters = viewFilters.filter(
      (filter) => filter.Target !== "EntityField",
    );
    return {viewFilters: changedViewFilters, columns: []};
  }
  const tableName = viewFilters[0].Selected;
  const usedColumns =
    columnsUnchanged && columns.length
      ? columns
      : await getColumns({
          table: tableName,
          prefix: "Single",
          filterDTOProperties: true,
        });

  const options = usedColumns.map((column) => ({
    Title: column.Title,
    Key: column.Name,
  }));

  const selectedOptions =
    viewFilters.find((filter) => filter.Target === "EntityField")?.Selected ??
    [];

  const newViewFilters = viewFilters.filter(
    (filter) => filter.Target !== "EntityField",
  );

  newViewFilters.push({
    Target: "EntityField",
    Type: "List",
    title: translations["ValuesOnCards"],
    placeholder: translations["ValuesOnCards"],
    Selected: selectedOptions,
    Multiple: true,
    Options: options,
  });
  return {viewFilters: newViewFilters, columns: usedColumns};
};
