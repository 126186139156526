import {handleCloseAllWindows} from "./handleCloseAllWindows";
import setDocumentTitle from "../../../functions/setDocumentTitle";

export const handleWarehouseChange = async ({vueInstance, newWarehouse}) => {
  let windowsClosureApproved;

  if (Object.keys(vueInstance.rWindows).length > 0) {
    windowsClosureApproved = await handleCloseAllWindows({
      vueInstance,
      alwaysWarnAboutClosure: true,
    });

    if (!windowsClosureApproved) return;
  }

  vueInstance.$store.commit("setActiveWarehouse", {
    warehouse: newWarehouse,
  });
  setDocumentTitle(null, newWarehouse);
};
