import {fetchScannedValueRowData} from "@/functions/item-scanner/fetchScannedValueRowData";
import {processOrderItemRowMetaData} from "@/functions/datagrid/order-item/row/processOrderItemRowMetaData";
import {convertKeyValueRowToCellRow} from "../../row/convertKeyValueRowToCellRow";
import {addMissingPropsToRow} from "@/functions/datagrid/order-item/row/addMissingPropsToRow";
import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {setCorrectCompositionValue} from "./setCorrectCompositionValue";
import {setClientSideUUIDToRow} from "@/functions/datagrid/row/setClientSideUUIDToRow";
import store from "../../../../../state/store";
import dayjs from "dayjs";

export async function generateNewRowFromScanItem({
  item,
  ranking,
  rows,
  oldRow,
  order,
}) {
  const criteria = [
    {
      IsReplacement: false,
      OrderID: order.OrderID,
      Target: "Rental.Order",
    },
  ];
  const scannedRowData = await fetchScannedValueRowData({
    scannedValue: item.value,
    criteria,
  });

  return await Promise.all(
    scannedRowData.Rows.map(async (row, index) => {
      if (!ranking && rows.length > 0) {
        const rowWithHighestRanking = rows.reduce((prev, current) =>
          prev.Ranking.Value > current.Ranking.Value ? prev : current,
        );
        const currentRanking =
          parseInt(rowWithHighestRanking.Ranking.Value) + 10;
        const nextRanking = Math.floor(currentRanking / 10) * 10;

        ranking = nextRanking;
      }

      if (!ranking) {
        ranking = 10;
      }

      row.OrderItemID = null;
      row.WarehouseID =
        item.warehouseId ?? row.WarehouseID?.Value ?? row.WarehouseID;
      row.ReturnWarehouseID =
        item.warehouseId ?? row.WarehouseID?.Value ?? row.WarehouseID;

      row.DateTimeExpectedStart = dayjs(row.DateTimeExpectedStart).format(
        "YYYY-MM-DDTHH:MM:ss",
      );

      // row.ItemDescription = item.label;
      row.Ranking = ranking;

      if (index > 0) {
        row.Ranking = row.Ranking + index * 10;
      }

      const itemType = getRawValueFromDropdownValue(row.ItemType);

      if (itemType === "Item") {
        row.ItemID = item.value;
      }

      if (itemType === "ItemSerial") {
        row.SerialID = item.value;
      }

      row = convertKeyValueRowToCellRow(row);
      row = await processOrderItemRowMetaData({row, order});

      row = addMissingPropsToRow(row);

      if (oldRow?.ClientSideUUID?.Value) {
        row.ClientSideUUID = oldRow.ClientSideUUID;
        row.OrderItemID = oldRow.OrderItemID;
      } else {
        row = setClientSideUUIDToRow({row});
      }

      const cellItemType = getRawValueFromDropdownValue(row.ItemType.Value);

      if (cellItemType === "ItemSerial") {
        row.Amount.IsReadOnly = true;
      }

      if (cellItemType === "Free") {
        row.SubStatus.Value = {
          Key: "OK",
          Description: store.state.translations.SubStatusOK,
        };
      }

      if (row.Composition.Value) {
        row = setCorrectCompositionValue({row, rows});
      }

      row.ItemID.OriginalValue = row.ItemID.Value;
      return row;
    }),
  );
}
