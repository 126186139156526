<template>
  <div
    ref="r-select-wrapper"
    class="r-select-wrapper flex h-full"
    :class="[
      themeClasses,
      {
        '!bg-[#fff] !rounded-[3px] !border-solid border-[1px] border-[#ced4da] shadow-[1px_1px_1px_0px_rgba(0,0,0,0.09)]':
          defaultStyling,
        '!cursor-not-allowed': readOnly && defaultStyling,
        'allow-scrollable-container': multiple,
        'cursor-text': !readOnly,
      },
    ]"
    :title="tooltip"
  >
    <vue-select
      ref="r-select"
      class="r-select flex-grow h-full min-w-0"
      :class="{
        'hide-vs-search-element': !isSearchActive && !hideCursor,
        '!text-[#888]': readOnly,
        '!bg-[#eeeeee]': readOnly && defaultStyling,
      }"
      :value="selectedValue"
      :options="shownOptions"
      :label="label"
      :required="required"
      :placeholder="placeholder"
      :loading="loading"
      :disabled="readOnly"
      :clearable="clearable"
      :selectable="canSelectOptions"
      :filter-by="filterBy"
      :filter="optionsFilter"
      :no-drop="disableDropdown"
      :multiple="multiple"
      :taggable="taggable"
      :push-tags="pushTags"
      :auto-scroll="true"
      :append-to-body="appendToBody"
      :close-on-select="closeOnSelect"
      :clear-search-on-blur="clearSearchOnBlur"
      :clear-search-on-select="clearSearchOnSelect"
      :reset-on-options-change="resetOnOptionsChange"
      :dropdown-should-open="dropdownShouldOpen"
      :calculate-position="calculatedPosition"
      @focus="handleFocus"
      @search="handleSearch"
      @input="handleInput($event)"
      @open="handleDropdownOpened($event)"
      @select="$emit('value-change', $event)"
      @search:blur="handleFocusLoss"
      @keyup.backspace.native="$emit('keyup-backspace', $event)"
      @keyup.enter.native="$emit('keyup-enter', $event)"
      @option:selected="$emit('option-selected', $event)"
      @option:deselected="$emit('option-deselected', $event)"
    >
      <template #option="option">
        <div
          :title="
            option.Text +
            (option.SubDescription != null ? `\n${option.SubDescription}` : '')
          "
          @click="handleOptionClick(option)"
        >
          <slot name="select-option" :option="option">
            <div class="flex items-center">
              <div v-if="option?.Color">
                <span
                  class="inline-block !min-w-[13px] !min-h-[13px] rounded-full mt-1 mr-1.5"
                  :style="{'background-color': option.Color}"
                />
              </div>
              <span v-if="label === null">{{ option.null }}</span>
              <span v-else>{{ option.Text ?? option.Value ?? option }}</span>
            </div>
          </slot>
        </div>
      </template>

      <template #selected-option="option">
        <div v-if="selectedValueColor">
          <div class="flex items-center">
            <span
              class="inline-block !min-w-[13px] !min-h-[13px] rounded-full mb-1.5 mr-1.5"
              :style="{'background-color': selectedValueColor}"
            />
          </div>
        </div>
        <span v-if="Array.isArray(option)">
          {{ option.map((item) => extractSelectedOptionText(item)).join(", ") }}
        </span>
        <span v-else>{{ extractSelectedOptionText(option) }}</span>
      </template>

      <template #search="{attributes, events}">
        <input
          :id="inputId"
          class="vs__search"
          v-bind="attributes"
          v-on="events"
        />
        <input
          v-if="required"
          hidden
          :name="randomString"
          :required="
            Array.isArray(selectedValue)
              ? selectedValue.length < 1
              : !selectedValue
          "
        />
      </template>

      <template #no-options>
        <div class="py-1">
          {{ noOptionsFoundPlaceholder }}
        </div>
      </template>
    </vue-select>

    <div
      v-if="!hideAllButtons"
      class="flex items-center flex-shrink-0 bg-inherit pr-1"
      :class="{'!bg-[#eeeeee]': readOnly && defaultStyling}"
    >
      <r-select-buttons
        :show-create-button="showCreateButton"
        :show-ref-button="showRefButton"
        :is-dropdown-open="isSearchActive"
        :hide-buttons="hideAllButtons"
        :read-only="readOnly"
        :clearable="!!(clearable && selectedValue)"
        :loading="loading"
        @clear-selection="handleInput(null)"
        @open-indicator-click="handleOpenIndicatorClick"
        @open-create-entity="handleCreateEntityClick"
        @open-ref="handleOpenRefClick"
      />
    </div>
  </div>
</template>

<script>
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import {getSettings} from "../../util/getSettings";
import {withPopper} from "../../functions/elements/withPopper.js";
import {generateID} from "../../util/generateID";
import RSelectButtons from "./RSelectButtons.vue";
import {VueSelect} from "vue-select";

export default {
  name: "RSelect",
  components: {
    RSelectButtons,
    VueSelect,
  },
  props: {
    value: {
      type: [String, Number, Array, Object],
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    inputId: {
      type: String,
      required: false,
      default: () => generateID(6),
    },
    label: {
      type: [String, null],
      required: false,
      default: "Text",
    },
    themeClasses: {
      type: String,
      required: false,
      default: "",
    },
    defaultStyling: {
      type: Boolean,
      required: false,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    noOptionsFoundPlaceholder: {
      type: String,
      required: false,
      default: function () {
        return getTranslations().NoResults;
      },
    },
    filterBy: {
      type: Function,
      required: false,
      default(option, label, search) {
        return (
          (label || "")
            .toLocaleLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1
        );
      },
    },
    optionsFilter: {
      type: Function,
      required: false,
      default(options, search) {
        const {ScanFieldFilterValue} = getSettings();

        const actualSearchValue = search.replace(
          `${ScanFieldFilterValue ?? ""}`,
          "",
        );

        return options.filter((option) => {
          let label = this.getOptionLabel(option);
          if (typeof label === "number") {
            label = label.toString();
          }
          return this.filterBy(option, label, actualSearchValue);
        });
      },
    },
    taggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    pushTags: {
      type: Boolean,
      required: false,
      default: false,
    },
    customRef: {
      type: String,
      required: false,
      default: null,
    },
    showRefButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCursor: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearSearchOnBlur: {
      type: Function,
      required: false,
      default: () => false,
    },
    clearSearchOnSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetOnOptionsChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableDropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    dropdownShouldOpen: {
      type: Function,
      required: false,
      default: ({noDrop, open}) => {
        return noDrop ? false : open;
      },
    },
    hasCalculatedPosition: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    scannerMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isSearchActive: false,
      hideAllButtons: this.scannerMode,
      randomString: Math.random().toString(36).substring(7),
    };
  },
  computed: {
    selectedValue() {
      const value = this.options.find((option) => option.Value === this.value);
      return value ?? this.value ?? "";
    },
    selectedValueColor() {
      return typeof this.selectedValue === "object" &&
        "Color" in this.selectedValue
        ? this.selectedValue.Color
        : null;
    },
    tooltip() {
      return this.selectedValue
        ? (this.selectedValue?.Text ?? this.selectedValue) +
            (this.selectedValue?.SubDescription
              ? `\n${this.selectedValue?.SubDescription}`
              : "")
        : "";
    },
    calculatedPosition() {
      return this.hasCalculatedPosition ? withPopper : () => {};
    },
    shownOptions() {
      if (!this.loading) return this.options;
      // only first 500
      return this.options.slice(0, 500);
    },
    canSelectOptions() {
      return () => !this.loading;
    },
  },
  mounted() {
    if (this.$refs["r-select-wrapper"].$el?.offsetWidth < 120) {
      this.hideAllButtons = true;
    }
  },
  methods: {
    handleSearch(event) {
      this.$emit("search", event);
    },
    handleInput(value) {
      this.clearSearchValue();
      if (value === null) this.$emit("clear-selection", value);
      if (value !== null && !this.multiple) this.isSearchActive = false;

      this.$emit("input", value);
    },
    handleOptionClick(value) {
      if (value !== null && !this.multiple) this.isSearchActive = false;
    },
    handleFocusLoss() {
      this.isSearchActive = false;
      this.clearSearchValue();
      this.$emit("focus-lost");
    },
    handleDropdownOpened($event) {
      this.isSearchActive = true;
      this.$emit("open", $event);
    },
    handleFocus(event) {
      this.$emit("focus", event);
    },
    handleOpenRefClick($event) {
      setTimeout(() => {
        this.$emit("open-ref", $event);
      }, 10);
    },
    handleCreateEntityClick($event) {
      setTimeout(() => {
        this.$emit("open-create-entity", $event);
      }, 10);
    },
    handleOpenIndicatorClick() {
      this.isSearchActive = true;
      const inputElement = document.getElementById(this.inputId);
      inputElement.focus();
    },
    clearSearchValue() {
      this.$refs["r-select"].search = "";
    },
    extractSelectedOptionText(option) {
      if (typeof option === "string") {
        return option;
      } else if (option.Text) {
        return option.Text;
      } else if (option.Value) {
        return option.Value;
      } else if (option.null) {
        return option.null;
      } else {
        return JSON.stringify(option); // Fallback for unhandled cases
      }
    },
    generateID,
  },
};
</script>

<style lang="scss">
.r-select {
  .vs__dropdown-toggle {
    border: none;
    border-radius: 0;
    box-shadow: none;
    overflow: hidden;
    cursor: inherit;
    background-color: inherit !important;
    margin-top: auto;
    margin-bottom: auto;
    height: 100% !important;
    padding: 0 5px 0 0 !important;
  }
  .hide-vs-search-element .vs__search {
    position: absolute;
    border: none !important;
    right: 0;
    padding: 0 !important;
  }

  .vs__search {
    margin: 2px 0 0 0 !important;
  }
  .vs__open-indicator {
    display: none !important;
  }
  .vs__actions {
    display: none !important;
  }
  .vs__spinner {
    display: none !important;
  }
  input {
    border: none !important;
  }

  .vs__selected {
    max-width: 100%;
    margin: 4px 2px 0 4px;
    white-space: nowrap;
    color: inherit;
    overflow: hidden;
    padding: 0;
  }
  .vs__selected-options {
    overflow: hidden;
    margin-bottom: 1px;
  }
}

.allow-scrollable-container .r-select {
  .vs__selected-options {
    overflow-y: auto !important;
  }
  .vs__selected {
    white-space: normal !important;
    overflow: visible !important;
  }
}

.vs__dropdown-menu {
  border: 1px solid #d5d5d5;
  border-radius: 3px !important;
  margin-top: -0.5px !important;
  padding: 0 !important;
}
.vs__dropdown-option:nth-child(n + 2) {
  margin-top: 2px;
}
.vs__dropdown-option--highlight {
  background-color: #e1e1e1 !important;
  color: black !important;
}
</style>
