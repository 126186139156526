import {processSidebarFields} from "./processSidebarFields.js";
import {findColumnValue} from "./findColumnValue.js";
import {findMetadataByColumnName} from "./findMetadataByColumnName.js";
import {findComboboxByColumnName} from "./findComboboxByColumnName.js";

export const getSidebarFields = ({columns, row, metadata}) => {
  return processSidebarFields({
    columns: Object.values(columns),
    getValueByColumnName: (name) => findColumnValue(row, name),
    getMetadataByColumnName: (name) => findMetadataByColumnName(metadata, name),
    getComboboxByColumnName: (name) => findComboboxByColumnName(row, name),
  });
};
