import {waitForTheElement} from "wait-for-the-element";
import store from "../../../state/store";
import Hook from "@/hook";

/**
 * Focus self
 * @see {@link Session.activeWindow}
 * @returns {Promise} Promise
 */
export default async function focus(window, fieldToFocus) {
  const targetWindow = window ?? this;

  // prevent if we are already the active window
  if (
    targetWindow.session.activeWindow &&
    targetWindow.session.activeWindow.id === targetWindow.id
  ) {
    await focusFormField(fieldToFocus);
    return null;
  }

  // hookable
  const focus = await Hook.run("focus", targetWindow, () => {
    this.session.activeWindow = targetWindow;
  });
  store.commit("updateWindow");

  const tabID = targetWindow.id;
  let tabs = targetWindow.session.tabs;

  if (!tabs.find((tab) => tab.id === tabID)) {
    tabs.unshift(targetWindow);
  }

  targetWindow.session.tabs = tabs;

  store.commit("setActiveWindowID", targetWindow.id);
  store.commit("updateWindow");

  global.eventBus.emit("afterFocus", targetWindow);
  await focusFormField(fieldToFocus);

  return focus;
}

const focusFormField = async function (fieldToFocus) {
  if (fieldToFocus) {
    const inputElement = await waitForTheElement(`#${fieldToFocus}`, {
      timeout: 5000,
    });

    await new Promise((resolve) => setTimeout(resolve, 0));
    inputElement.focus();
  }
};
