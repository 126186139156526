import {processFormFieldChangeWithDates} from "../change-handlers/field/processFormFieldChangeWithDates";
import {processShipmentCustomerId} from "../change-handlers/field/processShipmentCustomerId";

export const processReservationFormFieldChange = async ({
  windowData,
  changedField,
  windowId,
  vueComponent,
}) => {
  let newData = {...windowData};

  newData = await processFormFieldChangeWithDates({
    windowData: newData,
    changedField,
    windowId,
    vueComponent,
  });

  if (changedField?.Name === "ShipmentCustomerID")
    newData = await processShipmentCustomerId({
      windowData: newData,
      windowId,
      vueComponent,
    });

  return newData;
};
