<template>
  <div class="col-xs-12" :class="{hidden: field.IsVisible === false}">
    <div class="form-field block mb-[0.9rem]">
      <label
        class="flex !mb-0 py-[3px] px-2 !text-[#9e9e9e] !text-[13.2px] whitespace-nowrap hover:cursor-pointer"
        :for="fieldName"
        :title="title"
      >
        <div class="overflow-hidden text-ellipsis" v-html="title" />
        <span
          v-if="
            field.IsRequired &&
            !field.IsReadOnly &&
            field.Editor !== 'checkbox' &&
            field.Type !== 'Boolean'
          "
          class="text-danger font-black ml-1.5"
        >
          *</span
        >
      </label>

      <div
        :class="{'form-input px-2 py-[3px]': editor !== 'RichTextEditor'}"
        class="whitespace-nowrap text-ellipsis"
      >
        <component
          :is="fieldComponent"
          v-if="fieldComponent"
          :field="field"
          :row="row"
          :name="fieldName"
          @input="updateValue({field, value: $event})"
          @input:search-value="$emit('input:search-value', $event)"
        />

        <div v-if="field.Description || field.Comment">
          <small id="emailHelp" class="form-text text-muted whitespace-normal">
            <i class="fas fa-info"></i>
            {{ field.Description || field.Comment }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getTranslatedTitle} from "@/functions/dialog/getTranslatedTitle";
import {getFormFieldVueComponent} from "../../functions/form/r-form/getFormFieldVueComponent";
import {capitalizeFirstCharInString} from "../../interface/capitalizeFirstCharInString";
import {getFieldEditor} from "../../functions/fields/get-field-editor/getFieldEditor.js";

export default {
  name: "FormField",
  props: {
    value: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fieldComponent() {
      return getFormFieldVueComponent(this.editor);
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    editor() {
      return `${capitalizeFirstCharInString({
        string: getFieldEditor({field: this.value}),
      })}`;
    },
    title() {
      return `${this.field.IsDirty ? "✎" : ""}${getTranslatedTitle(
        this.field,
      )}:`;
    },
  },
  methods: {
    updateValue({field, value}) {
      if (value?.Value !== undefined) {
        this.$emit("input", {
          ...this.value,
          ...value,
        });

        this.$emit("change", this.value.Value);
        return;
      }

      field.Value = value;
      field.Label = value;

      this.$emit("input", field ?? null);
      this.$emit("change", value ?? null);
    },
  },
};
</script>

<style scoped></style>
