import {rowsDataChanged} from "../../order-item/rows/rows-process-webhook/rowsDataChanged.js";
import {processCompositionDefinition} from "../../row/processCompositionDefinition.js";
import {rowsSetMetadataForCompositionRows} from "../../rows/rowsSetMetadataForCompositionRows.js";
import {rowsSetNullValueForMissingProps} from "../../rows/rowsSetNullValueForMissingProps.js";
import {updateRows} from "../../rows/updateRows.js";
import {getExtraTotalValues} from "../rows/getExtraTotalValues.js";
import {rowsProcessWebhook} from "../rows/rowsProcessWebhook.js";

export const handleRowsUpdated = async ({rows, vueComponent, oldRows}) => {
  let rowsChanged = true;

  if (vueComponent.rentalQuotationItemPricesUpdatedDraftWebhookEvent) {
    // check with deep equal lodash if rows are equal asside from the rowMeta in each row
    rowsChanged = rowsDataChanged({
      oldRows: vueComponent.rows,
      currentRows: rows,
    });
  }
  vueComponent.rows = rows;

  let newRows = rows;
  if (vueComponent.rentalQuotationItemPricesUpdatedDraftWebhookEvent && rowsChanged) {
    newRows = await rowsProcessWebhook({
      rows: vueComponent.rows,
      quotation: vueComponent.quotation,
    });

    newRows = rowsSetMetadataForCompositionRows({rows: newRows});
    newRows = rowsSetNullValueForMissingProps({
      rows: newRows,
      columns: vueComponent.columns,
    });
    newRows = newRows.map((row) => processCompositionDefinition({row}));
  }

  updateRows({newRows, vueComponent, oldRows});

  vueComponent.extraTotalValues = {
    ...(await getExtraTotalValues({
      rows: vueComponent.rows.filter((row) => !row.rowMeta?.virtual),
      quotation: vueComponent.quotation,
      dataWebHooks: vueComponent.dataWebhooks,
    })),
  };
};
