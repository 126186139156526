import {alertSerialDateConflict} from "./alertSerialDateConflict.js";
import {getRawValueFromDropdownValue} from "../../../util/parsing/getRawValueFromDropdownValue";

export async function processRowConflicts({rowConflicts}) {
  const processedSerialConflicts = [];

  for (const rowConflict of rowConflicts) {
    const {row, reason} = rowConflict;
    const serialId = getRawValueFromDropdownValue(row.SerialID.Value);

    if (
      reason === "serialRowHasDateConflict" &&
      !processedSerialConflicts.includes(serialId)
    ) {
      const conflictingRow = rowConflicts.find((x) => {
        return (
          getRawValueFromDropdownValue(x.row.SerialID.Value) === serialId &&
          x.row.ClientSideUUID.Value !== row.ClientSideUUID.Value
        );
      });

      await alertSerialDateConflict({serialId, row, conflictingRow});

      processedSerialConflicts.push(serialId);
    }
  }
}
