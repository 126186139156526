export function handleSubStatusShipped({row}) {
  if (row.SubStatus?.Value?.Key === "Shipped") {
    const newRow = {...row};
    const exemptedColumns = [
      "Notes",
      "Ranking",
      "DateTimeRentalStart",
      "DateTimeBusinessStart",
      "DateTimeBusinessEnd",
      "DateTimeExpectedEnd",
      "Discount",
      "Period",
      "PeriodAmount",
      "PeriodPrice",
      "VisibilityPolicy",
      "PriceCalcReason",
      "VATCodeID",
    ];

    for (const column of Object.values(newRow)) {
      if (column && !exemptedColumns.includes(column.Name)) {
        column.IsReadOnly = true;
      }
    }
    return newRow;
  }
  return row;
}
