var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"datagrid-standalone w-full border-b border-gray-200"},[_c('div',{staticClass:"w-full"},[_vm._t("header",function(){return [_c('datagrid-header',{attrs:{"page-size":_vm.pageSize},on:{"page-size-change":function($event){return _vm.$emit('page-size-change', $event)}}})]})],2),_c('div',{staticClass:"datagrid-table min-h-[50vh]"},[_c('table',{staticClass:"table-auto divide-y divide-gray-300"},[_c('thead',_vm._l((_vm.columnsPerRow),function(columnRow,columnRowIndex){return _c('tr',{key:columnRowIndex},[(
              columnRowIndex === 0 && _vm.containsAdvancedColumns && _vm.rows.length
            )?_c('th',{attrs:{"rowspan":_vm.columnsPerRow.length}}):_vm._e(),(
              columnRowIndex === 0 &&
              _vm.actions.length !== 0 &&
              _vm.rows.length !== 0
            )?_c('th',{attrs:{"rowspan":_vm.columnsPerRow.length}}):_vm._e(),_vm._l((columnRow),function(column){return _c('datagrid-column',{key:column.Name,attrs:{"column":column,"sort-by":_vm.columnSortedBy,"sort-direction":_vm.columnSortDirection,"columns":columnRow,"column-header-value":_vm.columnValue},on:{"column-resize":_vm.handleColumnResize,"column-header-change":_vm.handleColumnHeaderChange}})})],2)}),0),_c('datagrid-row-wrapper',{attrs:{"columns-per-row":_vm.columnsPerRow,"actions":_vm.actions,"rows":_vm.rows,"show-action-column":_vm.showActionColumn,"visible-rows":_vm.visibleRows,"page":_vm.page,"page-size":_vm.pageSize,"columns":_vm.columns,"skeleton-rows":_vm.skeletonRows,"process-context":_vm.processContext,"warehouse-id":_vm.warehouseId},on:{"action":_vm.onAction,"input":_vm.handleInputChange,"click-row":function($event){return _vm.$emit('click-row', $event)},"loading":function($event){return _vm.$emit('loading', $event)},"cell-click":function($event){return _vm.$emit('cell-click', $event)},"cell-icon-click":function($event){return _vm.$emit('cell-icon-click', $event)},"icon-event":function($event){return _vm.$emit('icon-event', $event)},"value-click":function($event){return _vm.handleValueClick($event)}}})],1)]),_c('datagrid-footer',{staticClass:"mt-2 w-full",attrs:{"rows":_vm.rows,"columns":_vm.columns,"page-size":_vm.pageSize,"count":_vm.rows.length,"page":_vm.page},on:{"page-change":function($event){return _vm.$emit('page-change', $event)}}},[_vm._t("footer")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }