import {setReturnItemFormFieldsFromOrder} from "./setReturnItemFormFieldsFromOrder.js";
import {handleValueFieldChange} from "./form-handlers/handleValueFieldChange.js";
import {openReturnItemScanbox} from "./openReturnItemScanbox.js";
import {getComboboxItems} from "../../../services/mainRadComboBox";

export async function handleReturnWindowOpenedFromOrder({
  vueComponent,
  criteria,
}) {
  let valueFieldLabel = criteria.Order;
  try {
    valueFieldLabel = await getComboboxItems({
      columnName: "ReturnValue",
      tableName: "Rental.OrderItem",
      searchValue: criteria.Order,
      subjectColumnName: "Value",
      subject: "Rental.virtual_Return",
      primaryKeys: {
        ReturnType: "Order",
      },
    });
  } catch (err) {
    console.error(err);
  }

  const mockValueFieldEvent = {
    Value: criteria.Order,
    predefinedType: "Order",
    Text: valueFieldLabel?.[0]?.Text,
  };

  await handleValueFieldChange({
    event: mockValueFieldEvent,
    vueComponent,
  });

  setReturnItemFormFieldsFromOrder({vueComponent, mockValueFieldEvent});

  if (vueComponent.OnlyShowScan) {
    vueComponent.formFields.Scan.IsReadOnly = false;
    delete vueComponent.formFields.Type;
    delete vueComponent.formFields.Value;
  }

  setTimeout(() => openReturnItemScanbox(), 320);
}
