import {compareVersions} from "./compareVersions";
import {fetchServerVersion} from "./fetchServerVersion";
import {showUpdatePrompt} from "./showUpdatePrompt";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

const getLastPromptTime = () => {
  const stored = localStorage.getItem("versionCheckLastPrompt");
  return stored ? dayjs(stored) : null;
};

const setLastPromptTime = () => {
  localStorage.setItem("versionCheckLastPrompt", dayjs().toISOString());
};

export const handleFocusCheck = async ({currentVersion}) => {
  const lastPrompt = getLastPromptTime();
  const fiveMinutesAgo = dayjs().subtract(5, "minute");
  if (lastPrompt && lastPrompt.isSameOrAfter(fiveMinutesAgo)) return;

  const newVersion = await fetchServerVersion();

  if (newVersion) {
    const needsUpdate = compareVersions({
      currentVersion,
      newVersion,
    });

    if (needsUpdate) {
      const updated = await showUpdatePrompt();
      if (!updated) {
        setLastPromptTime();
      }
    }
  }
};
