import {getTranslations} from "../functions/session/getTranslations";
import {alertPopup} from "../interface/alertPopup/alertPopup";
import {getPayments} from "../services/v2/payments/getPayments";
import {createAlertConfig} from "./delete-order/createAlertConfig";
import {getOrderIdFromCriteria} from "./delete-order/getOrderIdFromCriteria";

export const deleteOrder = async ({ window }) => {
    const orderID = getOrderIdFromCriteria({ criteria: window.output.Request.Criteria });
    const { data } = await getPayments({ params: { OrderID: orderID }});
    const hasPayments = (data?.Collection ?? []).length > 0;

    return alertPopup(createAlertConfig({
        translations: getTranslations(),
        orderID,
        hasPayments
    }));
};