var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center items-center gap-0.5"},[(!_vm.hideButtons && _vm.clearable)?_c('div',{staticClass:"p-1 !cursor-pointer",on:{"click":function($event){return _vm.$emit('clear-selection')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"11","viewBox":"0 0 24 24","fill":"gray"}},[_c('path',{attrs:{"d":"M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"}})])]):_vm._e(),(_vm.showOpenIndicator)?_c('div',{staticClass:"w-[15px] flex justify-center",class:{
      'mr-[1px]':
        (_vm.showRefButton && _vm.showCreateButton) ||
        (!_vm.showRefButton && !_vm.showCreateButton),
      'mt-[1px]': !_vm.isDropdownOpen,
      '-mt-[1px]': _vm.isDropdownOpen,
    },on:{"click":function($event){return _vm.$emit('open-indicator-click')}}},[_c('svg',{staticClass:"vs__open-indicator",class:{open: _vm.isDropdownOpen},attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11","height":"8","viewBox":"0 0 15 11","role":"presentation"}},[_c('path',{attrs:{"d":"M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"}})])]):_vm._e(),(_vm.showCreateButton)?_c('div',{staticClass:"flex justify-center items-center cursor-pointer",class:{'w-[19.5px]': !_vm.showRefButton},on:{"click":function($event){return _vm.$emit('open-create-entity', $event)}}},[_c('i',{staticClass:"fas fa-sharp fa-solid fa-plus !font-light !text-[16px] mb-[1px]"})]):_vm._e(),(_vm.showRefButton)?_c('div',{staticClass:"material-icons cursor-pointer !text-[19.5px] !font-bold",on:{"click":function($event){$event.preventDefault();return _vm.$emit('open-ref', $event)}}},[_vm._v("  ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"!w-[19.5px]"},[_c('i',{staticClass:"fas fa-spinner fa-spin text-1xl ml-0.5 mt-1"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }