import {processFormPostbackResult} from "./processFormPostbackResult.js";
import {getWindowFromWindowId} from "../../../../window/getWindowFromWindowId.js";
import {toggleFieldsLoading} from "../../fields/toggleFieldsLoading.js";
import {getPostbackValues} from "../../../../../services/form/getPostbackValues.js";
import {parseDatesInRow} from "../../parseDatesInRow";

export const processFormPostback = async ({
  windowId,
  vueComponent,
  windowData,
}) => {
  const window = getWindowFromWindowId({windowId, session: global.session});
  const values = parseDatesInRow({windowData});

  if (vueComponent)
    toggleFieldsLoading({
      vueComponent,
      windowData,
      windowId,
      value: true,
    });

  try {
    const result = await getPostbackValues({
      window,
      values: values,
    });

    return processFormPostbackResult({result, windowData});
  } catch (error) {
    console.error(error);
    return windowData;
  } finally {
    if (vueComponent)
      toggleFieldsLoading({
        vueComponent,
        windowData: windowData,
        windowId,
        value: false,
      });
  }
};
