export const setRowValues = ({row, values}) => {
  if (!row || !values) {
    return row;
  }

  const updatedRow = {};

  Object.keys(row).forEach((key) => {
    updatedRow[key] = {
      ...row[key],
      Value:
        key in values ? (values[key]?.Value ?? values[key]) : row[key].Value,
    };
  });

  return updatedRow;
};
