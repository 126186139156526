export const createFormFieldsFromWindowData = ({
  row,
  columns,
  metadata,
  windowId,
}) => {
  return Object.values(columns)
    .map((column) => {
      const columnMetadata = {...metadata[column.Name]} || {};

      // The description property on a column is used for a small information text box under the field.
      // The metadata object from the window is giving a Description property to the column to be used as
      // a label for a selected dropdown option. We have to manipulate this data here.
      if ("Description" in columnMetadata) {
        columnMetadata.Label = columnMetadata.Description;
        delete columnMetadata.Description;
      }

      return {
        ...column,
        ...columnMetadata,
        Value: row[column.Name],
        WindowId: windowId,
      };
    })
    .sort((a, b) => a.Ranking - b.Ranking);
};
