import {getRowConflicts} from "../row/getRowConflicts";
import {filterRowsForStockCheck} from "../../rows/filterRowsForStockCheck";
import {processRowConflicts} from "../../row/processRowConflicts";
import {getTranslations} from "../../../session/localstorage/getTranslations";

export const processScannedRowsConflicts = async ({rows, scannedRows}) => {
  const newRows = rows.slice();
  const scannedSerialIds = scannedRows.map((row) => row.SerialID.Value);

  const rowConflicts = getRowConflicts({
    rows: filterRowsForStockCheck(newRows),
  });

  if (
    rowConflicts &&
    rowConflicts.some((x) => scannedSerialIds.includes(x.row.SerialID.Value))
  ) {
    await processRowConflicts({rowConflicts});
  }

  scannedSerialIds.forEach((scannedSerialId) => {
    if (rowConflicts.some((x) => x.row.SerialID.Value === scannedSerialId)) {
      const iconData = setWarningIconMetaDataStock({
        subMessage:
          getTranslations()[
            "Rental.OrderItem-MultiEdit-SerialSelectedMoreThenOnceOnOrder"
          ],
      });

      const row = newRows.find(
        (row) =>
          row.SerialID.Value === scannedSerialId &&
          row.ClientSideUUID.Value ===
            scannedRows.find((row) => row.SerialID.Value === scannedSerialId)
              .ClientSideUUID.Value,
      );

      row.SerialID = {...row.SerialID, ...iconData};
    }
  });

  return newRows;
};

function setWarningIconMetaDataStock({subMessage}) {
  return {
    Icon: "fa-exclamation-triangle",
    IconColor: "danger",
    IconMessage: getTranslations()["Rental.OrderItem-MultiEdit-SerialNoStock"],
    IconSubMessage: subMessage,
  };
}
