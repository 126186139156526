import {focusDirtyWindow} from "../../../functions/window/focusDirtyWindow";
import {closeAllWindows} from "./closeAllWindows";
import {getDirtyWindows} from "../../../functions/window/getDirtyWindows";
import {alertCloseAllWindows} from "../tabs/alertCloseAllWindows";
import {alertPopup} from "../../../interface/alertPopup/alertPopup";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations";

export const handleCloseAllWindows = async ({
  vueInstance,
  alwaysWarnAboutClosure,
}) => {
  const translations = getTranslations();
  const dirtyWindows = getDirtyWindows({windows: vueInstance.rWindows});

  let closureApproved = true;

  if (dirtyWindows.length > 0) {
    closureApproved = await alertCloseAllWindows({dirtyWindows});
    if (!closureApproved) return focusDirtyWindow({window: dirtyWindows[0]});
  }

  if (
    Object.keys(vueInstance.rWindows).length > 0 &&
    dirtyWindows.length < 1 &&
    alwaysWarnAboutClosure
  ) {
    closureApproved = await alertPopup({
      text: translations.WarningAllWindowsWillClose,
      icon: "warning",
      buttons: {
        cancel: {text: translations.Cancel, value: false, variant: "secondary"},
        confirm: {text: "OK", value: true, variant: "primary"},
      },
    });
  }

  if (closureApproved) await closeAllWindows({vueInstance});

  return closureApproved;
};
