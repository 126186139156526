<template>
  <h4>
    <i v-if="loading" class="fas fa-spinner fa-spin mr-2"></i>

    <span
      v-if="!loading"
      class="relative inline-block rounded-md canvas-title"
      :style="{
        '--title-color': canvasTitleColor
          ? `${canvasTitleColor}`
          : 'transparent',
      }"
      v-html="title ?? ''"
    />
  </h4>
</template>

<script>
export default {
  name: "CanvasTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    window: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    canvasTitleColor() {
      return this.window?.output?.Color;
    },
  },
};
</script>

<style>
.canvas-title::after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: -4px;
  width: 106%;
  border-radius: 2px;
  height: 5px;
  background-color: var(--title-color);
}
</style>
