import { render, staticRenderFns } from "./DecimalField.vue?vue&type=template&id=426e935e&scoped=true&"
import script from "./DecimalField.vue?vue&type=script&lang=js&"
export * from "./DecimalField.vue?vue&type=script&lang=js&"
import style0 from "./DecimalField.vue?vue&type=style&index=0&id=426e935e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "426e935e",
  null
  
)

export default component.exports