import {setClosedRowPropsReadOnly} from "@/functions/datagrid/order-item/row/setClosedRowPropsReadOnly";
import {setAndForceMinDates} from "@/functions/datagrid/order-item/row/setAndForceMinDates";
import {setPeriodDropdownItems} from "@/functions/datagrid/order-item/row/setPeriodDropdownItems";
import {processActiveRowMetaData} from "@/functions/datagrid/order-item/row/processActiveRowMetaData";
import {processFreeRow} from "@/functions/datagrid/order-item/row/processFreeRow";
import {handleSubStatusOk} from "@/functions/datagrid/order-item/row/handleSubStatusOk";
import {handleSubStatusShipped} from "@/functions/datagrid/order-item/row/handleSubStatusShipped";
import {handleStatusClosed} from "@/functions/datagrid/order-item/row/handleStatusClosed";
import {processSerialIdChange} from "@/functions/datagrid/order-item/row/processSerialIdChange";
import {handleInvoicingStarted} from "./handleInvoicingStarted";
import {handleItemReturned} from "./handleItemReturned";
import {processRowDeletable} from "@/functions/datagrid/order-item/row/processRowDeletable";
import {processPopupPriceCalculations} from "@/functions/datagrid/order-item/row/processPopupPriceCalculations";
import {processItemType} from "@/functions/datagrid/order-item/row/processItemType";
import {processInitialSalesRow} from "@/functions/datagrid/order-item/row/processInitialSalesRow";
import {setRowItemTypeMetaData} from "../../row/setRowItemTypeMetaData.js";
import {getTranslations} from "../../../session/localstorage/getTranslations";
import {processRowFullyInvoiced} from "./processRowFullyInvoiced.js";
import {processRowDropdownCells} from "../../row/processRowDropdownCells.js";
import {processSerialRow} from "../../row/processSerialRow.js";
import {processRowWeights} from "../../row/processRowWeights.js";
import {processRowCellEditors} from "../../row/processRowCellEditors.js";

export async function processOrderItemRowMetaData({row, order}) {
  const translations = getTranslations();
  let newRow = {...row};
  newRow = setClosedRowPropsReadOnly({row: newRow, translations});
  newRow = setAndForceMinDates({row: newRow});
  newRow.PriceDifference = {Name: "PriceDifference", Value: 0};
  newRow.CalculatedPrice = {Name: "CalculatedPrice", Value: 0};

  const ensureColumnsExist = [
    "DateTimeInvoicedUnto",
    "Period",
    "PeriodPrice",
    "PeriodAmount",
    "PriceCalculation",
  ];

  for (const column of ensureColumnsExist) {
    if (!newRow[column]) {
      newRow[column] = {Value: null};
    }
  }

  if (
    (newRow.Status.Value === "Open" ||
      newRow.Status.Value === translations.StatusOpen) &&
    !newRow.DateTimeInvoicedUnto.Value
  ) {
    newRow = await setRowItemTypeMetaData({row: newRow});
  }

  newRow = processSerialRow({row: newRow});
  newRow = setPeriodDropdownItems({row: newRow});
  newRow = processActiveRowMetaData({row: newRow});

  newRow = handleSubStatusOk({row: newRow});
  newRow = handleInvoicingStarted({row: newRow});

  newRow = processSerialIdChange({row: newRow});
  newRow = handleSubStatusShipped({row: newRow});
  newRow = handleStatusClosed({row: newRow});
  newRow = processRowWeights({row: newRow});

  newRow = processRowDeletable({row: newRow});
  newRow = processPopupPriceCalculations({row: newRow});

  newRow = handleItemReturned({row: newRow});

  newRow = await processItemType({row: newRow, order});
  newRow = processRowCellEditors({row: newRow});
  newRow = processFreeRow({row: newRow});
  newRow = processRowDropdownCells({row: newRow});
  newRow = processInitialSalesRow({row: newRow});
  newRow = processRowFullyInvoiced({row: newRow, order});

  return newRow;
}
