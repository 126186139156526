import {getOptions} from "../../../../fields/select-field/getOptions";

export const processShipmentCustomerId = async ({
  windowData,
  windowId,
  vueComponent,
}) => {
  let newRow = {...windowData.Rows?.[0]};
  let newFields = {...windowData.Columns};
  let newMetadata = {...windowData.Metadata[0]};

  const updatedAddresses = await getOptions({
    maxReturnableOptions: null,
    field: windowData.Columns["AddressID"],
    row: newRow,
  });

  let newAddress = updatedAddresses.find((address) => address.Value === -1);
  if (!newAddress) newAddress = updatedAddresses[0];

  newMetadata["AddressID"].Description = newAddress?.Text;
  newRow["AddressID"] = null;
  newFields["AddressID"] = {
    ...newFields["AddressID"],
    Label: newAddress?.Text,
    Dropdown: {
      ...newFields["AddressID"].Dropdown,
      Items: [...updatedAddresses],
    },
  };

  let newData = {
    ...windowData,
    Rows: [newRow],
    Columns: {...newFields},
    Metadata: [{...newMetadata}],
  };

  vueComponent.$emit("data-change", {newData, windowId});
  await vueComponent.$nextTick();

  newRow["AddressID"] = newAddress?.Value;
  newData = {...windowData, Rows: [newRow]};

  return newData;
};
