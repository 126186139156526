import {dirtyCheckWithPrompt} from "@/interface/window/dirty";
import {getRWindowById} from "../../functions/window/getRWindowById";
import store from "../../../state/store";

/**
 * Update window with new output
 * @param {Object} output - New output object
 * @returns {Promise} Promise
 */
export default async function updateWindow(output) {
  const multiEdit = output.Request.Prefix === "MultiEdit";

  const rWindow = getRWindowById({windowId: this.sub?.window?.id ?? this.id});
  if (rWindow?.data.dirty && !(await dirtyCheckWithPrompt(this))) return;

  if (this.parent) {
    if (
      output.Data &&
      (output.Data.Type === "rentform" ||
        output.Data.Type === "partialdelivery" ||
        multiEdit)
    ) {
      await this.toggleLoading(true);

      // process and render
      await this.process(output, true);
      await this.toggleLoading(false);

      await this.render();
      store.commit("updateWindow");
      global.eventBus.emit("afterUpdateWindow", this);
      return;
    }

    this.parent.input.Criteria = output.Request.Criteria;
    this.parent.fetch();
    global.eventBus.emit("afterUpdateWindow", this);
    return;
  } else {
    await this.toggleLoading(true);

    // process and render
    await this.process(output, false);
    await this.toggleLoading(false);
    await this.render();

    store.commit("updateWindow");
    global.eventBus.emit("afterUpdateWindow", this);
  }
}
