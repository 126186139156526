import {clearSelectedEntityFieldFilterValues} from "./clearSelectedEntityFieldFilterValues.js";
import {fetchAndProcessCards} from "./fetchAndProcessCards.js";
import {generateEntityFieldFilter} from "./generateEntityFieldFilter.js";
import {generateLaneFilters} from "./generateLaneFilters.js";
import {generateLanes} from "./generateLanes.js";
import {processLanesVisibility} from "./processLanesVisibility.js";
import {setFiltersBasedOnViewFilters} from "./setFiltersBasedOnViewFilters.js";
import {setLanesCallbackFunction} from "./setLanesCallbackFunction.js";
import {updateFilters} from "./updateFilters.js";

export const handleViewFilterChange = async ({filter, value, vueInstance}) => {
  let viewFilters = vueInstance.viewFilters;
  let filters = vueInstance.filters;

  viewFilters = updateFilters({
    filters: viewFilters,
    target: filter.Target,
    value,
  });

  let lanes = await generateLanes({
    viewFilters: viewFilters,
    filters: filters,
  });

  const laneCallbackFunction = setLanesCallbackFunction({
    viewFilters: viewFilters,
  });

  filters = setFiltersBasedOnViewFilters({
    viewFilters,
    filters,
  });

  if (filter.Target === "Entity") {
    viewFilters = generateLaneFilters({
      viewFilters,
      lanes,
    });
    viewFilters = clearSelectedEntityFieldFilterValues({viewFilters});
  }

  lanes = processLanesVisibility({
    lanes,
    viewFilters,
  });

  const generatedEntityFieldFilterResult = await generateEntityFieldFilter({
    viewFilters,
    columns: vueInstance.entityColumns ?? [],
    columnsUnchanged: filter.Target === "EntityField",
  });

  viewFilters = generatedEntityFieldFilterResult.viewFilters;
  const entityColumns = generatedEntityFieldFilterResult.columns;

  let cards = vueInstance.cards;
  if (filter.Target !== "EntityField") {
    cards = await fetchAndProcessCards({
      filters,
      searchValues: vueInstance.searchValues,
      maxRows: vueInstance.maxRows,
      windowId: vueInstance.windowId,
      loading: vueInstance.loading,
      tableName: vueInstance.tableName,
    });
  }

  vueInstance.viewFilters = viewFilters;
  vueInstance.filters = filters;
  vueInstance.lanes = lanes;
  vueInstance.entityColumns = entityColumns;
  vueInstance.laneCallbackFunction = laneCallbackFunction;

  vueInstance.cards = [];
  //wait 1 tick
  await vueInstance.$nextTick();
  vueInstance.cards = cards;

  vueInstance.context = {
    columns: vueInstance.entityColumns,
    tableName: vueInstance.tableName,
    displayedColumns:
      vueInstance.viewFilters?.find((filter) => filter.Target === "EntityField")
        ?.Selected ?? [],
  };
  vueInstance.saveFilters({filters, viewFilters});
};
