<template>
  <div>
    <div class="table-form stick-div ml-1 mr-1 mt-1 mb-1">
      <div class="flex">
        <form-filter-bar
          :filters="viewFilters"
          :title="translations['DisplayConfiguration']"
          :disable-search="true"
          icon="fa-cog"
          :default-collapsed="true"
          :collapse-filter="true"
          @filter-change="handleViewFilterChange"
        />
        <form-filter-bar
          :filters="filters"
          :title="translations['Filters']"
          icon="fa-filter"
          :search-values="searchValues"
          :disable-search="true"
          :default-collapsed="true"
          :collapse-filter="true"
          @remove-search="handleRemoveSearch"
          @search="handleSearch"
          @filter-change="handleFilterChange"
        />
        <div>
          <div class="flex gap-2 mr-1 mb-1">
            <div
              v-for="searchValue in searchValues"
              :key="searchValue"
              class="flex items-center px-2 py-1 bg-gray-200 rounded-[3px]"
            >
              <div
                class="mr-2 text-gray-500 hover:text-gray-700 cursor-pointer"
                @click="removeSearchValue(searchValue)"
              >
                <span class="close material-icons" style="font-size: inherit"
                  ></span
                >
              </div>
              <span class="text-gray-700">{{ searchValue }}</span>
            </div>
          </div>
        </div>
        <text-field
          :field="{Editor: 'String', Placeholder: translations['Search']}"
          @input="handleSearch"
          @keydown="handleKeyDown"
        />
      </div>
    </div>
    <Kanban
      :cards="filteredCards"
      :loading="Object.values(loading).length > 0"
      :get-lane-cards-callback="laneCallbackFunction"
      :lanes="lanes"
      :context="context"
    />
  </div>
</template>

<script>
import {flatMap, isObject, some, toLower, values} from "lodash";
import {fetchAndProcessCards} from "../../functions/kanban/task/fetchAndProcessCards.js";
import {findNewestFilter} from "../../functions/kanban/task/findNewestFilter";
import {generateEntityFieldFilter} from "../../functions/kanban/task/generateEntityFieldFilter.js";
import {generateLaneFilters} from "../../functions/kanban/task/generateLaneFilters.js";
import {generateLanes} from "../../functions/kanban/task/generateLanes.js";
import {generateTaskFilters} from "../../functions/kanban/task/generateTaskFilters.js";
import {getDisplayedColumns} from "../../functions/kanban/task/getDisplayedColumns.js";
import {getInitialFilters} from "../../functions/kanban/task/getInitialFilters.js";
import {getInitialViewFilters} from "../../functions/kanban/task/getInitialViewFilters.js";
import {handleLoadAnimationOnWindow} from "../../functions/kanban/task/handleLoadAnimationOnWindow.js";
import {handleViewFilterChange} from "../../functions/kanban/task/handleViewFilterChange.js";
import {processEntityFilters} from "../../functions/kanban/task/processEntityFilters.js";
import {processLanesVisibility} from "../../functions/kanban/task/processLanesVisibility.js";
import {removeProcessedFilters} from "../../functions/kanban/task/removeProcessedFilters";
import {restoreFiltersValuesFromFilters} from "../../functions/kanban/task/restoreFiltersValuesFromFilters.js";
import {setFiltersBasedOnViewFilters} from "../../functions/kanban/task/setFiltersBasedOnViewFilters.js";
import {setLanesCallbackFunction} from "../../functions/kanban/task/setLanesCallbackFunction.js";
import {updateFilters} from "../../functions/kanban/task/updateFilters.js";
import {getUserId} from "../../functions/session/getUserId.js";
import {getLocalStorageDataByKey} from "../../functions/session/localstorage/getLocalStorageDataByKey.js";
import {setLocalStorageKey} from "../../functions/session/localstorage/setLocalsStorageKey.js";
import {handleJobExecution} from "../../functions/datagrid/actions/handleJobExecution";
import {toggleLoading} from "../../functions/window/toggleLoading.js";
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";

import FormFilterBar from "../form/filters/FormFilterBar.vue";
import TextField from "../form/fields/TextField.vue";
import Kanban from "./Kanban.vue";

const translations = getTranslations();

export default {
  name: "KanbanTaskWrapper",
  components: {FormFilterBar, Kanban, TextField},
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lanes: [],
      cards: [],
      loading: {},
      filters: [],
      viewFilters: [],
      searchValues: [],
      resources: [],
      maxRows: 1000,
      laneCallbackFunction: null,
      entityColumns: {},
      context: {columns: {}, tableName: null, displayedColumns: []},
      translations,
      handlingViewFilterChange: [],
      currentJob: null,
    };
  },
  computed: {
    tableName() {
      if (!this.viewFilters) return;
      return this.viewFilters[0]?.Selected;
    },
    filteredCards() {
      if (!this.searchValues?.length) return this.cards;

      const searchTerms = this.searchValues.map(toLower);

      return this.cards.filter((card) => {
        const topLevelMatch = some(
          card,
          (value) =>
            value &&
            searchTerms.every((term) => toLower(String(value)).includes(term)),
        );

        const entityMatch =
          card.Entity &&
          some(
            flatMap(card.Entity, (value) =>
              isObject(value) ? values(value) : value,
            ),
            (value) =>
              value &&
              searchTerms.every((term) =>
                toLower(String(value)).includes(term),
              ),
          );

        return topLevelMatch || entityMatch;
      });
    },
  },
  watch: {
    "rWindow.jobs": {
      handler: function (newJobs) {
        if (this.currentJob) return;

        if (newJobs && Object.keys(newJobs).length > 0) {
          const firstJob = Object.values(newJobs)[0];
          this.currentJob = firstJob;
          this.handleJobExecution(firstJob);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.loading.initial = true;
    toggleLoading({loading: true, windowId: this.rWindow.id});

    let filters = this.initializeKanbanFilters();
    let viewFilters = await getInitialViewFilters({translations});

    let storedFilters = this.getStoredFilters();

    filters = generateTaskFilters({
      filters,
      initialValues: storedFilters === null,
    });

    filters = restoreFiltersValuesFromFilters({
      filters,
      filtersToRestore: storedFilters?.filters,
    });

    viewFilters = restoreFiltersValuesFromFilters({
      filters: viewFilters,
      filtersToRestore: storedFilters?.viewFilters,
    });

    delete this.loading.initial;
    handleLoadAnimationOnWindow({
      loading: this.loading,
      windowId: this.rWindow.id,
    });

    let lanes = await generateLanes({
      viewFilters,
      filters,
    });

    this.laneCallbackFunction = setLanesCallbackFunction({
      lanes,
      viewFilters,
    });

    viewFilters = generateLaneFilters({
      viewFilters,
      lanes,
    });

    viewFilters = processEntityFilters(viewFilters, lanes);

    const {viewFilters: updatedViewFilters, columns: entityColumns} =
      await generateEntityFieldFilter({
        viewFilters,
      });

    storedFilters = this.getStoredFilters();

    viewFilters = restoreFiltersValuesFromFilters({
      filters: updatedViewFilters,
      filtersToRestore: storedFilters?.viewFilters,
    });

    lanes = processLanesVisibility({
      lanes,
      viewFilters,
    });

    this.viewFilters = viewFilters;
    this.entityColumns = entityColumns;
    this.lanes = lanes;

    this.filters = setFiltersBasedOnViewFilters({viewFilters, filters});

    const displayedColumns = getDisplayedColumns(viewFilters);

    this.context = {
      ...this.context,
      columns: entityColumns,
      tableName: this.tableName,
      displayedColumns,
    };

    this.cards = await fetchAndProcessCards({
      filters: this.filters,
      searchValues: this.searchValues,
      maxRows: this.maxRows,
      windowId: this.rWindow.id,
      loading: this.loading,
      tableName: this.tableName,
    });
    toggleLoading({loading: false, windowId: this.rWindow.id});
  },
  methods: {
    saveFilters({filters, viewFilters}) {
      const userId = getUserId();
      const key = `kanban-task-filters-14-10-${userId}`;

      setLocalStorageKey({key, data: {filters, viewFilters}});
    },

    getStoredFilters() {
      const userId = getUserId();
      const key = `kanban-task-filters-14-10-${userId}`;
      return getLocalStorageDataByKey({key});
    },
    handleSearch(search) {
      if (!this.searchValues?.includes(search)) {
        this.searchValues.push(search);
      }
    },
    handleRemoveSearch(search) {
      this.searchValues = this.searchValues.filter((s) => s !== search);
    },
    removeSearchValue(searchValue) {
      this.searchValues = this.searchValues.filter((s) => s !== searchValue);
    },
    handleKeyDown(event) {
      if (
        event.key === "Backspace" &&
        event.target.value === "" &&
        this.searchValues.length > 0
      ) {
        this.searchValues.pop();
      }
    },
    async handleViewFilterChange({filter, value}) {
      const timestamp = Date.now();
      const newFilter = {timestamp, filter, value};

      if (this.handlingViewFilterChange.length > 0) {
        this.handlingViewFilterChange.push(newFilter);
        return;
      }

      this.handlingViewFilterChange.push(newFilter);

      while (this.handlingViewFilterChange.length > 0) {
        const newest = findNewestFilter(this.handlingViewFilterChange);

        await handleViewFilterChange({
          filter: newest.filter,
          value: newest.value,
          vueInstance: this,
        });

        this.handlingViewFilterChange = removeProcessedFilters(
          this.handlingViewFilterChange,
          newest.timestamp,
        );
      }
    },
    async handleFilterChange({filter, value}) {
      this.filters = updateFilters({
        filters: this.filters,
        target: filter.Target,
        value,
      });

      this.cards = await fetchAndProcessCards({
        filters: this.filters,
        searchValues: this.searchValues,
        maxRows: this.maxRows,
        windowId: this.rWindow.id,
        loading: this.loading,
        tableName: this.tableName,
      });
      this.saveFilters({filters: this.filters, viewFilters: this.viewFilters});
    },

    async reset() {
      toggleLoading({loading: true, windowId: this.rWindow.id});
      this.cards = await fetchAndProcessCards({
        filters: this.filters,
        searchValues: this.searchValues,
        maxRows: this.maxRows,
        windowId: this.rWindow.id,
        loading: this.loading,
        tableName: this.tableName,
      });
      toggleLoading({loading: false, windowId: this.rWindow.id});
    },
    initializeKanbanFilters: () => {
      return getInitialFilters({translations});
    },
    async handleJobExecution(job) {
      try {
        this.$emit("job-completed", job);
        await handleJobExecution({job, vueInstance: this});
      } finally {
        this.currentJob = null;
      }
    },
  },
};
</script>
