import {isRowDeletable} from "../../row/isRowDeletable";

export function processRowDeletable({row}) {
  const newRow = {...row};

  if (!isRowDeletable({row: newRow})) {
    newRow.rowMeta = {
      ...newRow.rowMeta,
      deletable: false,
    };
  }
  return newRow;
}
