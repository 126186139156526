export function generateFilterForColumn({column}) {
  const filter = {
    Type: "List",
    Target: column.Name,
    placeholder: column.Title,
    Selected: [],
    Options: [],
  };

  if (column.DefaultFilter) {
    filter.Selected = [column.DefaultFilter];
  }

  return filter;
}
