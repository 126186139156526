<template>
  <div class="datagrid-standalone w-full border-b border-gray-200">
    <div class="w-full">
      <slot name="header"
        ><datagrid-header
          :page-size="pageSize"
          @page-size-change="$emit('page-size-change', $event)"
      /></slot>
    </div>
    <div class="datagrid-table min-h-[50vh]">
      <table class="table-auto divide-y divide-gray-300">
        <thead>
          <tr
            v-for="(columnRow, columnRowIndex) in columnsPerRow"
            :key="columnRowIndex"
          >
            <th
              v-if="
                columnRowIndex === 0 && containsAdvancedColumns && rows.length
              "
              :rowspan="columnsPerRow.length"
            ></th>
            <th
              v-if="
                columnRowIndex === 0 &&
                actions.length !== 0 &&
                rows.length !== 0
              "
              :rowspan="columnsPerRow.length"
            ></th>
            <datagrid-column
              v-for="column in columnRow"
              :key="column.Name"
              :column="column"
              :sort-by="columnSortedBy"
              :sort-direction="columnSortDirection"
              :columns="columnRow"
              :column-header-value="columnValue"
              @column-resize="handleColumnResize"
              @column-header-change="handleColumnHeaderChange"
            />
          </tr>
        </thead>
        <datagrid-row-wrapper
          :columns-per-row="columnsPerRow"
          :actions="actions"
          :rows="rows"
          :show-action-column="showActionColumn"
          :visible-rows="visibleRows"
          :page="page"
          :page-size="pageSize"
          :columns="columns"
          :skeleton-rows="skeletonRows"
          :process-context="processContext"
          :warehouse-id="warehouseId"
          @action="onAction"
          @input="handleInputChange"
          @click-row="$emit('click-row', $event)"
          @loading="$emit('loading', $event)"
          @cell-click="$emit('cell-click', $event)"
          @cell-icon-click="$emit('cell-icon-click', $event)"
          @icon-event="$emit('icon-event', $event)"
          @value-click="handleValueClick($event)"
        />
      </table>
    </div>
    <datagrid-footer
      :rows="rows"
      :columns="columns"
      :page-size="pageSize"
      :count="rows.length"
      class="mt-2 w-full"
      :page="page"
      @page-change="$emit('page-change', $event)"
    >
      <slot name="footer"></slot>
    </datagrid-footer>
  </div>
</template>

<script>
import {getVisibleRowsCompositionGrouped} from "../../functions/datagrid/rows/getVisibleRowsCompositionGrouped.js";
import {getLocalStorageColumnStyles} from "@/functions/datagrid/getLocalStorageColumnStyles";
import {persistColumnResize} from "@/functions/datagrid/persistColumnResize";
import {setResizeListeners} from "@/functions/datagrid/setResizeListeners";
import {valueClickOpenRef} from "@/functions/datagrid/events/valueClickOpenRef";
import {cloneDeep} from "lodash";

import DatagridRowWrapper from "@/components/datagrid/DatagridRowWrapper";
import DatagridFooter from "./datagridFooters/DatagridFooter.vue";
import DatagridColumn from "./DatagridColumn.vue";
import DatagridHeader from "./datagridHeaders/DatagridHeader";
import $ from "jquery";

export default {
  components: {
    DatagridRowWrapper,
    DatagridHeader,
    DatagridFooter,
    DatagridColumn,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columnValue: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    columns: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    sortBy: {
      type: String,
      default: "",
    },
    sortDirection: {
      type: String,
      default: "asc",
    },
    processContext: {
      type: Object,
      default: () => ({}),
    },
    warehouseId: {
      type: String,
      required: false,
      default: null,
    },
    actionFunctions: {
      type: Object,
      default: () => ({}),
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    skeletonRows: {
      type: Number,
      default: 0,
    },
    userMetadataKey: {
      type: String,
      default: "",
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    visibleRowFunction: {
      type: Function,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      sortByColumn: null,
      sortColumnByDirection: "asc",
      persistedColumnStyles: {},
    };
  },
  computed: {
    filteredRows() {
      const effectiveFilters = Object.fromEntries(
        Object.entries(this.filters).filter(([key, value]) => value.length > 0),
      );

      if (!Object.keys(effectiveFilters).length) return this.rows;
      return this.rows.filter((row) => {
        for (const [key, values] of Object.entries(effectiveFilters)) {
          if (values.includes(row[key].Value)) return true;
        }
        return false;
      });
    },
    visibleRows() {
      let start = (this.page - 1) * this.pageSize;
      let end = start + this.pageSize;

      if (this.visibleRowFunction) {
        return this.visibleRowFunction({
          rows: this.rows,
          pageSize: this.pageSize,
          page: this.page,
        });
      }

      return this.filteredRows.slice(start, end);
    },
    columnsPerRow() {
      let firstRow = this.columns.filter(
        (x) => !x.ShowUnder && x.IsVisible && !x.IsAdvancedColumn,
      );

      firstRow = this.restoreStylesToColumns({columnList: firstRow});

      const rows = [firstRow];

      while (true) {
        const lastRow = rows[rows.length - 1];
        const newRow = lastRow.map(
          (x) => x && this.columns.find((y) => y.ShowUnder == x.Name),
        );

        if (!newRow.find(Boolean)) break;

        rows.push(newRow);
      }

      return rows.map((x) => this.processGaps(x));
    },
    columnSortedBy() {
      return this.sortByColumn || this.sortBy;
    },
    columnSortDirection() {
      return this.sortColumnByDirection || this.sortDirection;
    },
    containsAdvancedColumns() {
      return this.columns.some((column) => column.IsAdvancedColumn);
    },
    showActionColumn() {
      return this.actions.length > 0;
    },
  },
  mounted() {
    setResizeListeners(this.$el);

    this.keypressHandler = (e) => {
      if (!e.target || e.target.nodeName != "INPUT") return;
      if (e.target.selectionStart != e.target.selectionEnd) return;

      const td = $(e.target).parents("td").get(0);

      if (!td) return;

      const KEY_LEFT = 37;
      const KEY_UP = 38;
      const KEY_RIGHT = 39;
      const KEY_DOWN = 40;

      function focusStart(input) {
        input.setSelectionRange(0, 0);
        input.focus();
      }

      function focusEnd(input) {
        input.setSelectionRange(
          String(input.value).length + 1,
          String(input.value).length + 1,
        );
        input.focus();
      }

      if (e.which == KEY_LEFT && e.target.selectionStart == 0) {
        const input = td.previousElementSibling.querySelector("input");

        if (input) {
          input.select();
          e.preventDefault();
        }
      }

      if (
        e.which == KEY_RIGHT &&
        e.target.selectionStart == String(e.target.value).length
      ) {
        const input = td.nextElementSibling.querySelector("input");

        if (input) {
          input.select();
          e.preventDefault();
        }
      }

      if (e.which == KEY_UP && e.target.selectionStart == 0) {
        const offset = getTdOffset(td);
        const rowAbove = td.parentElement.previousElementSibling;

        if (rowAbove) {
          const newTd = getTdAtOffset(rowAbove, offset);

          if (newTd) {
            const input = newTd.querySelector("input");

            if (input) {
              input.select();
              e.preventDefault();
            }
          }
        }
      }

      function getTdOffset(td) {
        let offset = 0;

        for (let x = td; x; x = x.previousElementSibling) {
          if (x != td) offset += x.colSpan;
        }

        return offset;
      }

      function getTdAtOffset(row, offset) {
        let newOffset = 0;

        for (const newTd of row.children) {
          if (newOffset >= offset) return newTd;

          newOffset += newTd.colSpan;
        }

        return row.children[row.children.length - 1];
      }

      if (
        e.which == KEY_DOWN &&
        e.target.selectionStart == String(e.target.value).length
      ) {
        const offset = getTdOffset(td);
        let rowBelow = td.parentElement.nextElementSibling;

        if (rowBelow) {
          const newTd = getTdAtOffset(rowBelow, offset);

          if (newTd) {
            const input = newTd.querySelector("input");

            if (input) {
              input.select();
              e.preventDefault();
            }
          }
        }
      }
    };
    this.$el.addEventListener("keydown", this.keypressHandler, false);
  },
  unmounted() {
    this.$el.removeEventListener("keydown", this.keypressHandler, false);
  },
  methods: {
    async onAction(action) {
      const oldRows = cloneDeep(this.rows);
      const actionName = action.name;

      const importedFunction =
        this.actionFunctions[actionName] ||
        (await import("../../functions/datagrid/actions/" + actionName))
          .default;
      const result = await importedFunction({
        row: action.row,
        rows: this.rows,
        columns: this.columns,
      });
      this.$emit("rows-updated", {
        rows: result?.rows ?? this.rows,
        action,
        oldRows,
      });

      if (actionName === "deleteRow") this.checkVisibleRowsAndAdjustPage();
    },
    handleValueClick({cell, column}) {
      if (cell.openRef || (column.openRef && cell.openRef !== false)) {
        valueClickOpenRef({cell, column});
      }
    },
    handleInputChange({rows, oldRows}) {
      this.$emit("rows-updated", {rows, oldRows});
    },
    handleColumnHeaderChange(event) {
      this.$emit("column-header-change", event);
    },
    handleColumnResize({column, style}) {
      persistColumnResize({
        column,
        style,
        userMetadataKey: this.userMetadataKey,
      });
    },
    checkVisibleRowsAndAdjustPage() {
      let currentRows = getVisibleRowsCompositionGrouped({
        rows: this.rows,
        page: this.page,
        pageSize: this.pageSize,
      });

      if (currentRows < 1 && this.page > 1) {
        this.$emit("page-change", this.page - 1);
      }
    },
    restoreStylesToColumns({columnList}) {
      const persistedColumnStyles = this.getPersistedColumnStyles();
      let modifiedColumnList = columnList.slice();

      for (const column of modifiedColumnList) {
        const persistedColumnStyle = persistedColumnStyles[column.Name];

        if (persistedColumnStyle) {
          column.Styles = persistedColumnStyle;
        }
      }
      return modifiedColumnList;
    },
    calculateGapSize(array, index) {
      const remainingItems = array.slice(index);
      const gapSize = remainingItems.findIndex(Boolean);

      const isLastColumn = gapSize == -1;
      if (isLastColumn) return remainingItems.length;

      return gapSize;
    },
    processGaps(columns) {
      for (let i = 0; i < columns.length; i++) {
        if (columns[i]) continue;

        const gapSize = this.calculateGapSize(columns, i);

        const prevColumn = columns[i - 1];

        if (prevColumn && prevColumn.Type == "String") {
          columns.splice(i - 1, gapSize + 1, {
            ...prevColumn,
            Colspan: (prevColumn.Colspan || 1) + gapSize,
          });
        } else {
          columns.splice(i, gapSize, {
            Name: `GapFiller${i}`,
            Colspan: gapSize,
          });
        }
      }

      return columns;
    },
    processSorting(column) {
      if (this.sortByColumn === column.Name) {
        if (this.sortColumnByDirection === "desc") {
          this.sortByColumn = "";
          this.sortColumnByDirection = "asc";
          return;
        }
        this.sortColumnByDirection = "desc";
        return;
      }
      this.sortByColumn = column.Name;
    },
    getPersistedColumnStyles() {
      return getLocalStorageColumnStyles({
        key: `${this.userMetadataKey}-columns-styles`,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.datagrid-standalone {
  .datagrid-table {
    max-height: 640px;
    overflow: auto !important;
    width: 100%;

    .bg-gray-100 .form-field {
      input:read-write,
      .r-select-wrapper {
        background-color: white !important;
      }
    }

    .normal-row {
      .vs__selected-options {
        flex-wrap: inherit;
      }

      .vs__selected-options {
        span {
          color: black;
        }
      }

      .vs__dropdown-toggle {
        height: 27px !important;
      }

      .vs__selected {
        padding: 0;
        margin: 4px 0 0;
      }

      .vs--single.vs--open .vs__selected,
      .vs--single.vs--loading .vs__selected {
        position: relative;
      }
      input.align-right {
        direction: ltr;
        overflow: hidden;
      }

      input.align-right:read-only {
        direction: rtl;
        text-align: left;
        unicode-bidi: plaintext;
        text-overflow: ellipsis;
      }

      input.align-right:not(:focus) {
        direction: rtl;
        text-align: left;
        unicode-bidi: plaintext;
        text-overflow: ellipsis;
      }

      .vs__selected {
        white-space: nowrap;
      }

      .vs__dropdown-menu {
        display: flex;
        flex-direction: column;
      }

      .v-select > div {
        height: 27px;
        background: transparent;
        border-radius: 3px;
        border: none !important;
        box-shadow: none !important;
      }

      .vs__selected {
        line-height: 1.6;
      }

      .vs__dropdown-menu {
        min-width: 220px !important;
      }
      td {
        input {
          max-width: 100%;
          height: 29px;
          border: none;
          background-color: transparent !important;
        }
      }
    }

    th {
      white-space: nowrap;

      text-overflow: ellipsis;
      overflow: hidden;
    }

    thead {
      position: sticky;
      box-shadow:
        0 0.5px 0 0.5px #d2d3d5,
        0 -0.5px 0 -0.5px #d2d3d5 !important;
      top: 0;
      z-index: 300;
      background-color: #eeeeee;

      th {
        box-shadow:
          inset 0 0.5px 0 0.5px #d2d3d5,
          inset 0 -0.5px 0 -0.5px #d2d3d5 !important;
      }
    }
  }

  tbody {
    margin-top: 1px;
  }
}

.datagrid-standalone input.vs__search {
  background-color: transparent;
}

.datagrid-standalone {
  overflow-x: auto;
  overflow-y: auto;
  //display: contents;
  width: 100%;

  .bg-gray-100 .select-field {
    background: white;
  }

  .table-sm td,
  .table-sm th {
    padding: 0rem;
  }

  table {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

pre {
  display: block;
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier,
    monospace;
  white-space: pre;
  margin: 1em 0;
  background-color: rgb(235, 235, 235);
  border-radius: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
