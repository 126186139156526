<template>
  <div>
    <date-range-picker
      ref="picker"
      :locale-data="dataFormat"
      :date-range="dateRange"
      :date-format="dateFormat"
      :show-dropdowns="true"
      :auto-apply="true"
      :readonly="field.IsReadOnly"
      :ranges="false"
      :calculate-position="withPopper"
      opens="center"
      :single-date-picker="true"
      :append-to-body="true"
      @update="handleChange"
    >
    </date-range-picker>
    <div
      class="date-field pointer truncate"
      :class="{readonly: field.IsReadOnly}"
    >
      <div v-if="formattedValue" class="pt-1.5 pl-1" @click="togglePicker()">
        {{ formattedValue }}
      </div>
      <div
        v-if="placeholder && !formattedValue && !field.IsReadOnly"
        class="pt-2 pl-2 text-[#bdbdbd] pointer truncate"
        @click="togglePicker()"
      >
        {{ placeholder }}
      </div>

      <div class="flex">
        <div
          v-if="formattedValue && !required && !field.IsReadOnly"
          class="btn btn-link pointer m-0 px-2"
          @click="clearValue"
        >
          <i class="fas fa-times text-gray"></i>
        </div>

        <div
          v-if="!field.IsReadOnly"
          class="btn btn-link pointer m-0 px-2"
          @click="togglePicker()"
        >
          <i class="fas fa-calendar-alt text-dark"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

import DateRangePicker from "../../calendar/DateRangePicker.vue";
import translation from "@/util/translation";
import {parseDate} from "../../../util/parsing/parseDate.js";
import {createPopper} from "@popperjs/core";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
export default {
  name: "FormFilterBarFilterOptionsDateField",
  components: {
    DateRangePicker,
  },
  inject: ["translations"],
  props: {
    field: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Kies een datum",
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      date: "",
    };
  },
  computed: {
    dateRange() {
      if (this.field.Value === null) return {startDate: null, endDate: null};
      // Remove characters from C# version of EPOCH unix timestamp

      const startDate = this.field.Value;

      return {
        startDate: parseDate({dateString: startDate}),
        endDate: "",
      };
    },
    formattedValue() {
      if (this.field.Value) {
        return dayjs(this.field.Value).format("DD-MM-YYYY");
      }
      return "";
    },
    dataFormat() {
      return {
        direction: "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        changed: false,
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: [
          translation("calendar-sun"),
          translation("calendar-mon"),
          translation("calendar-tue"),
          translation("calendar-wed"),
          translation("calendar-thu"),
          translation("calendar-fri"),
          translation("calendar-sat"),
        ],
        monthNames: [
          translation("calendar-jan"),
          translation("calendar-feb"),
          translation("calendar-mar"),
          translation("calendar-apr"),
          translation("calendar-may"),
          translation("calendar-jun"),
          translation("calendar-jul"),
          translation("calendar-aug"),
          translation("calendar-sep"),
          translation("calendar-oct"),
          translation("calendar-nov"),
          translation("calendar-dec"),
        ],
        firstDay: 0,
      };
    },
  },
  methods: {
    dateFormat(classes) {
      return classes;
    },
    parseDateRangeValue(dateRange) {
      if (!dateRange) return null;
      if (dateRange.startDate === null) return null;

      return parseDate({dateString: dateRange.startDate});
    },
    handleChange(value) {
      if (value === null) {
        this.date = "";
        this.$emit("input", null);
        return;
      }
      this.date = value.startDate;
      this.dateRange.startDate = this.date;

      this.$emit("input", this.parseDateRangeValue(value));
    },
    clearValue() {
      this.handleChange(null);
    },
    togglePicker() {
      if (
        !Object.prototype.hasOwnProperty.call(this.field, "IsReadOnly") ||
        !this.field.IsReadOnly
      ) {
        this.$refs.picker.openPicker();
      }
    },
    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom", // Prefer bottom but allow flipping
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 32],
            },
          },
          {
            name: "flip",
            options: {
              // Allow flipping to top if there's not enough space below
              allowedAutoPlacements: ["top", "bottom"],
              fallbackPlacements: ["top"],
            },
          },
          {
            name: "preventOverflow",
            options: {
              // Keep some padding from window edges
              padding: 8,
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
};
</script>

<style scoped>
.readonly {
  background-color: #eeeeee;
  color: #888;
}

.date-field {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%);
  border: 1px solid #ced4da;
  height: 31.5px !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
</style>
