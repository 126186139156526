import {handleSettingsSave} from "@/actions/legacy/handleSettingsSave.js";

const actions = [
  {
    targetTableName: "Core.Settings",
    webMethodName: "_save",
    actionFunction: handleSettingsSave,
    afterAction: true,
  },
  {
    targetTableName: "Warehouse.UserWarehouse",
    webMethodName: "SaveData",
    actionFunction: handleSettingsSave,
    afterAction: true,
  },
  {
    targetTableName: "Core.vw_RoleSettings",
    webMethodName: "SaveData",
    actionFunction: handleSettingsSave,
    afterAction: true,
  },
];

export async function runMatchedClientSideActions({
  subject,
  targetTableName,
  webMethodName,
  webserviceUrl,
  window,
  afterAction,
  success,
}) {
  const matchedActions = actions.filter(
    (action) =>
      (action.targetTableName === targetTableName ||
        action.targetTableName === subject) &&
      action.webMethodName === webMethodName &&
      action.afterAction === afterAction,
  );

  if (matchedActions.length > 0) {
    for (const action of matchedActions) {
      await action.actionFunction(window, {success});
    }
  }
}
