export const processFormPostbackMetadata = ({newData}) => {
  const newColumns = {...newData.Columns};
  const newRow = {...newData.Rows?.[0]};
  const newMetadata = newData.Metadata?.[0];

  if (!newMetadata || !newColumns) return newData;

  // When the postback call removes a fields/column from the form,
  // remove its value from the row as well
  for (let key in newRow) {
    if (!newColumns[key]) newRow[key] = null;
  }

  Object.entries(newMetadata).forEach(([key, value]) => {
    if (value.Description && newColumns[key])
      newColumns[key].Label = value.Description;

    if (value.IsReadOnly && newColumns[key])
      newColumns[key].IsReadOnly = value.IsReadOnly;
  });

  return {...newData, Columns: newColumns, Rows: [{...newRow}]};
};
