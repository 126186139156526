import $ from "jquery";

import Combobox from "./combobox.js";

import Components from "../components";
import Drag from "./drag";

import Formatter from "../model/formatter";
import {EventEmitter} from "events";
import Hook from "../hook";

import scroll from "./scrollContainer";

import store from "../../state/store";
import LoginComponent from "../components/login/loginRouter.vue";

import Vue from "./vue";
import Modal from "./modal";

import Session from "../model/session";
import templater from "./templater.js";
import Window from "../model/window";

import TwoFactorSetup from "../components/login/twoFactorSetup/loginTwoFactorSetup.vue";
import {setActiveClassToWindow} from "@/functions/window/setActiveClassToWindow.js";
import {getWindowElement} from "@/functions/window/getWindowElement.js";
import {searchbarLogic} from "@/functions/legacy-interface/searchbarLogic";
import {insertWindow} from "../functions/window/insertWindow";
import {getActiveWindow} from "../functions/window/getActiveWindow";

/**
 * Canvas class, contains most render functions and handles GUI
 * @property {element} element - base element reference
 * @property {Array} draggers - Drag classes
 * @property {element} anchorElement - Anchor element(drag related)
 * @property {boolean} fullscreen - If fullscreen or kiosk
 * @property {Session} session - Session reference
 * @property {number} x - anchor x position
 * @property {number} y - anchor y position
 */
export default class Canvas extends EventEmitter {
  /**
   * @param {Session} session - session reference
   */
  constructor(session) {
    super();

    this.element = null;
    this.session = null;
    this.fullscreen = null;
    this.draggers = [];
    this.anchorElement = null;
    this.x = null;
    this.y = null;

    // When a JavaScript runtime error (including syntax errors and exceptions thrown within handlers) occurs,
    // an error event using interface ErrorEvent is fired.
    // If the thrown event was 'catched' this function is not called
    // this function is added to make sure all disabled/loading windows are enabled again.
    // A global warning is show in the corner of the window
    window.addEventListener("error", function () {
      session.tabs.forEach(async function (w) {
        while (w != null) {
          if (w.loading) {
            w.toggleLoading(false);

            window.session.activeWindow.showNotification(
              "danger",
              window.session.translations.AdminDesktop_AlertOperationCancelled,
            );

            w = null;
          } else if (w.sub && w.sub.window) {
            w = w.sub.window;
          } else {
            w = null;
          }
        }
      });
    });

    this.modal = new Modal(window, this);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _this_ = this;

    this.session = session || new Session("");

    let components = new Components(this.session);
    components.init(document);
    session.components = components;

    $(document).on("click", "[data-row-index]", function () {
      let id = $(this).closest(".window").attr("data-window-id");
      let active = session.windows[id] ?? getActiveWindow();

      if (active.bulkedit || (active.sub && active.sub.window.bulkedit)) {
        return;
      }

      let $checkbox = $(this).find(".selection-box input[type=checkbox]");
      $checkbox.prop("checked", !$checkbox.prop("checked"));
      $checkbox.trigger("change");
    });

    $(document).on("click", "[data-action='openCriteria']", function () {
      const $element = $(this);
      const fullTableName = $element.data("tablename");
      const criteria = $element.data("criteria");
      const prefix = $element.data("prefix");

      global.session.activeWindow.openCriteria(fullTableName, prefix, criteria);
    });

    $(document).on("click", ".form-permissions input", function () {
      const tableName = $(session.activeWindow.element)
        .find("[name='tablename']")
        .val();
      const roleID = $(session.activeWindow.element)
        .find("[name='roleid']")
        .val();
      global.session.activeWindow.savePermissions(tableName, roleID);
    });

    $(document).on("click", "[data-row-index]", function (e) {
      // check if $(e.target) has a class .ref
      if ($(e.target).hasClass("col-ref")) return;

      let id = $(this).closest(".window").attr("data-window-id");
      let subject = $(this).attr("data-subject");
      let key = $(this).attr("data-key");
      let keyReference = $(this).attr("data-keyreference");
      let active = session.windows[id] ?? getActiveWindow();

      if (!window.rowdblclicktimer || window.rowdblclicktarget !== this) {
        window.rowdblclicktarget = this;
        window.rowdblclicktimer = setTimeout(() => {
          window.rowdblclicktimer = null;
        }, 500);
        return;
      }

      window.rowdblclicktarget = null;
      window.rowdblclicktimer = null;

      if (
        ((active.output.Options.OpenRow == 1 && active.bulkedit) ||
          active.output.Options.OpenRow === 0) &&
        !$(event.target).hasClass("open-row")
      ) {
        return;
      }

      let index = $(this).attr("data-row-index");
      let fieldIndex = null;

      // if event.target has attribute 'open-row', provide clicked cellIndex to window.openRow function
      if (
        $(event.target).hasClass("open-row") &&
        !$(event.target).is(":empty")
      ) {
        fieldIndex = $(event.target).attr("data-field-index");
      }
      // open the row when OpenRow is enabled or an open-row column was clicked
      if (active.output.Options.OpenRow !== 0 || fieldIndex !== null) {
        active.openRow(index, fieldIndex, subject, key, keyReference);
      }
    });

    $(document).on("dblclick", ".col-ref", function () {
      let id = $(this).closest(".window").attr("data-window-id");
      let subject = $(this).parent().attr("data-subject");
      let key = $(this).parent().attr("data-key");
      let keyReference = $(this).parent().attr("data-keyreference");
      let active = session.windows[id];

      let index = $(this).parent().parent().attr("data-row-index");
      let fieldIndex = $(event.target).attr("data-field-index");

      if (!index) return;

      active.openRow(index, fieldIndex, subject, key, keyReference);
    });

    $(this.element).on("mousedown", ".window.parent", function () {
      global.session.activeWindow.focus();
    });

    $(document).on(
      "keydown",
      ".window input[name], .window [contenteditable][name]",
      async function onKeyUpAction(e) {
        let window = global.session.getClosestWindow(this);

        if (e.which !== 13 && e.which !== 27) {
          return;
        }

        let $this = $(this);
        let isInput = $this.is("input");

        if (
          window.options.customKeyEvents ||
          window.output.Request.Prefix == "New" ||
          $this.is(".no-field") ||
          (isInput && $this.is(".combobox-input"))
        ) {
          return;
        }

        let isCalender = $this.is("input.date") || $this.is(".field.date");

        switch (e.which) {
          // enter
          case 13: {
            if ($this.hasClass("ignore-next")) {
              return;
            }

            if (isInput) {
              let nextindex = Number($this.attr("data-field-index")) + 1;
              let $form = $this.closest(".form-view");
              let $next = $form.find(`[data-field-index='${nextindex}']`);

              if ($next.length) {
                $this.blur();
                $next.focus();
              }
            } else {
              let index = $this.index();
              let $thisRow = $this.closest(".table-row");
              let $thisGroup = $this.closest(".table-row-group");
              let isInGroup = $thisGroup.length > 0;
              let isLast = isInGroup
                ? $thisGroup.is(":last-child")
                : $thisRow.is(":last-child");

              if (isLast && $this.closest(".bulkedit").length > 0) {
                shouldAddNewLine(window, $this, $thisRow, true);
                if (!$this.val() && !$this.text()) {
                  $thisRow
                    .find(".selection-box input")
                    .prop("checked", false)
                    .change();
                }
              }

              let $nextRow = isInGroup
                ? $thisGroup.next().find(".table-row").first()
                : $thisRow.next();

              $nextRow.children().eq(index).focus();
            }

            if (isCalender) {
              $this.parent().find(".calendar").addClass("hide");
            }

            break;
          }
          // escape
          case 27: {
            if ($this.hasClass("ignore-reset")) {
              return;
            }

            if (isInput) {
              let colId = $this.attr("data-field-index");
              let originalVal =
                window.output.Table.Rows[0][colId].Value || String();
              $this.val(originalVal);
            } else {
              let $row = $this.closest(".table-row");
              if ($row.hasClass("new-row")) {
                $this.text("");
                return;
              }
              let row = $row.attr("data-row-index");
              let index = $this.attr("data-field-index");
              if (
                !window.bulkedit ||
                !window.output.Table ||
                !window.output.Table.Rows
              ) {
                return;
              }
              let cell = window.output.Table.Rows[row][index];
              let originalValue = cell.SerializedValue;
              originalValue =
                Formatter.parseValue(cell.Column, originalValue) || String();

              $this.text(originalValue);
              placeCaretAtEnd($this.get(0));
            }
            if (isCalender) {
              $this.parent().find(".calendar").addClass("hide");
            }
            $this.blur();
            $this.change();
            break;
          }
        }
      },
    );

    this.session.on("updated", () => this.render());
    // this.render()

    Hook.register("afterToggleLoading", this, (window) => {
      $(window.element)
        .find(".loading")
        .last()
        .toggleClass("hide", !window.loading);
    });

    Hook.register("afterFocus", this, (window) => {
      if (!this.session.kiosk) {
        location.hash = "#" + window.id;
      }

      $(".active-window").removeClass("active-window");
      $(window.parent ? window.parent.element : window.element).addClass(
        "active-window",
      );

      if (window.jsx) {
        $(document)
          .find(`[data-window-id='${window.id}']`)
          .addClass("active-window");
      }
      setActiveClassToWindow({window});
    });

    window.onhashchange = () => {
      let id = location.hash.slice(1);
      let window = this.session.windows[id];

      if (window) {
        window.focus();
      }
    };

    searchbarLogic();

    function placeCaretAtEnd(el) {
      el.focus();
      if (
        typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined"
      ) {
        let range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
        let textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    }

    $(document).on("click", "[data-row-index] [href]", (e) => {
      // stop selection of row when clicking link (image link)
      e.stopImmediatePropagation();
    });

    $(this.element).on(
      "change",
      ".window select.number-of",
      function changeNumberOfSelectedFields() {
        if (!session.activeWindow) {
          return;
        }

        let $window = $(this).closest(".window");
        let newVal = $(this).val();
        if ($window.is(".child")) {
          _this_.session.activeWindow.sub.window.pagesize(newVal);
        } else {
          _this_.session.activeWindow.pagesize(newVal);
        }
      },
    );

    $(document).on(
      "change",
      ".window .table-index .selection-box input",
      function selectAll() {
        let checkedAll = $(this).prop("checked");
        $(this)
          .closest(".table-view")
          .find(".table-body .table-row .selection-box input")
          .each(function () {
            $(this).prop("checked", checkedAll);
            $(this).change();
          });
      },
    );
    $(document).on(
      "click",
      ".window .table-body .table-row .selection-box",
      function cancelClick(e) {
        e.stopImmediatePropagation();
      },
    );

    $(document).on(
      "change",
      ".window .table-body .table-row .selection-box input",
      function selectOne() {
        let $w = $(this).closest(".window");
        let window = _this_.session.getClosestWindow($w);
        let rowIndex = $(this)
          .closest("[data-row-index]")
          .attr("data-row-index");
        let checked = $(this).prop("checked");

        window.selectRow(rowIndex, checked);

        $(this).closest(".table-row").toggleClass("dirty", checked);
        $w.find("[data-select-count]").text(window.selection.length);
      },
    );

    $(document).on("mousedown", ".canvas > .tabs .tab", function (e) {
      if (e.which !== 2) {
        return false;
      }

      let id = $(this).attr("data-window-target");
      _this_.session.windows[id].dispose();
    });

    let dblclicktimer = null;
    let clicktarget = null;

    $(document).on("click", "[data-toggle-lower-field]", function selectOne() {
      let $this = $(this);
      let window = _this_.session.getClosestWindow(this);
      let $lower = $this.closest(".table-row-group").find(".lower-field");

      if (!$lower.length) {
        $lower = $this
          .closest(".table-row.grouped-row")
          .nextUntil(".grouped-row");
      }

      $lower.toggleClass("hide");
      window.resize();
    });

    $(document).on(
      "click",
      "[data-show-all-lower-fields]",
      function selectOne() {
        let $this = $(this);
        let window = _this_.session.getClosestWindow(this);
        let numberOfShown = 0;

        $this
          .closest(".table-view")
          .find(".lower-field")
          .each(function () {
            if ($(this).hasClass("hide")) {
              numberOfShown++;
            }
          });

        $this
          .closest(".table-view")
          .find(".lower-field")
          .toggleClass("hide", numberOfShown == 0);
        window.resize();
      },
    );

    $(document).on(
      "change",
      ".window .table-body.bulkedit .table-row .combobox input[type='hidden']",
      function selectOne() {
        let $this = $(this);
        let $tr = $this.closest(".table-row");
        let window = _this_.session.getClosestWindow(this);

        shouldAddNewLine(window, $this, $tr, $this.val());

        if (!$tr.is(".new-row")) {
          compareBulkCellData();
        }
      },
    );

    function shouldAddNewLine(window, $this, $tr, val) {
      if (window.options.noNewLines) {
        return;
      }

      if (
        $tr
          .parent()
          .is(
            ".table-row-group",
          ) /* $tr.parent().is(".table-row-group:last-child") && val*/
      ) {
        return;
      }

      if ($this.closest(".table-row").is(":last-child") && val) {
        let $trClone = $tr.clone();
        $trClone.find("[contenteditable]").html("");
        $trClone.find(".combobox").each(function () {
          $(this).find(".menu").addClass("hide");
          $(this).find("input").val("");
          $(this).find(".option.selected").removeClass("selected");
          Combobox.update($(this).get(0));
        });
        $trClone.removeClass("dirty");
        $tr.after($trClone);
        $tr.find(".table-cell.selection-box input").prop("checked", true);
        $tr.addClass("dirty");
      }
    }

    $(document).on(
      "keypress",
      ".table-cell.number.field[contenteditable]",
      async function (e) {
        let asciiValue = e.keyCode || e.charCode;
        if (asciiValue == 45) {
          // 45 is -, we want to allow this once at the start of the number
          if (
            this.textContent.charAt(0) != "-" &&
            this.textContent.charAt(0) != ""
          ) {
            this.textContent = "-" + this.textContent;
            e.preventDefault();
            e.stopImmediatePropagation();
          }
        } else if (asciiValue == 46) {
          // 46 is . we want to allow this once
          if (this.textContent.indexOf(".") != -1) {
            e.preventDefault();
            e.stopImmediatePropagation();
          }
        } else if (asciiValue < 48 || asciiValue > 57) {
          // anything else we do not want the user to enter
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      },
    );

    $(document).on("keyup input", ".field[contenteditable]", async function () {
      let oldContent = $(this).html();
      let newContent = $(this)
        .html()
        .replace(/<br([^>]*)>/g, "");

      if (oldContent != newContent) {
        $(this).html(newContent);
      }
    });

    $(document).on("submit", "form", (e) => e.preventDefault());

    $(document).on("click", ".table-cell", function () {
      $(this).find("[contenteditable=true]").focus();
    });

    $(document).on(
      "blur paste",
      ".bulkedit .field[contenteditable]",
      function () {
        let $this = $(this);
        // cleanse
        $this.text($this.text());
        let window = _this_.session.getClosestWindow(this);
        window.resize();

        // compare data to original

        let $tr = $this.closest(".table-row");

        shouldAddNewLine(window, $this, $tr, $this.text());

        if ($tr.is(".new-row")) {
          // if(!$tr.is(":last-child")) {
          // 	$tr.addClass("dirty")
          // }
        } else {
          compareBulkCellData();
        }

        $this.change();
        return $this;
      },
    );

    function compareBulkCellData() {
      return;
    }

    $("body").on("click", "[data-tabs-scroll-left]", function () {
      $(".tabs").scrollLeft("0");
    });

    $("body").on("click", "[data-tabs-scroll-right]", function () {
      $(".tabs").scrollLeft("400");
    });

    $("body").on("click", ".password-lost-button", async function (e) {
      e.preventDefault();
      _this_.session.openScreen("ForgotPassword");
    });

    $("body").on("click", "[name='setup2fa']", async function () {
      const bindingElement = document.getElementById(
        "two-factor-authentication",
      );
      new window.vue({
        el: bindingElement,
        store,
        render: (h) => h(TwoFactorSetup),
      });
    });

    Hook.register("afterResize", this, this.correctTableHeaders);

    Hook.register("afterPaginate", this, async (window) => {
      $(window.element).find(".scroll-container").scrollTop(0);
    });

    $(document).on("click", "[data-menu-id]", async function treeClick() {
      // return when the menu node is disabled
      if ($(this).hasClass("disabled")) {
        return;
      }

      let menuNode = {};

      menuNode.MenuId = $(this).attr("data-menu-id");
      menuNode.Value = $(this).attr("data-value");
      menuNode.Text = $(this).attr("data-text");

      let arMenuNodes = _this_.session.moduleMenu;

      // Notify the tutorial about the opened menunode, and try to handle url: and tutorial:
      _this_.session.tutorial.openMenuNodeNotifioncation(
        $(this).attr("data-menu-id"),
      );

      if (menuNode != null && menuNode.Value != null) {
        if (menuNode.Value.includes("iframe:")) {
          let url = menuNode.Value.replace("iframe:", "");

          const title = menuNode.Text;
          insertWindow({
            Data: {
              Type: "iframe",
              Url: url,
            },
            Title: title,
            Request: {
              Subject: "Core.virtual_Iframe",
              Criteria: [{Url: url}],
              MenuIframe: true,
              Url: url,
              Prefix: "Single",
            },
          });
          return;
        }

        if (menuNode.Value.includes("tutorial:")) {
          _this_.session.tutorial.resetTutorial();

          return;
        } else if (menuNode.Value.includes("url:")) {
          global.open(menuNode.Value.replace("url:", ""), "_blank");

          return;
        }
      }

      $("body").addClass("avoid-clicks");
      // prevent doubleclick
      let el = $(this).get(0);

      if (dblclicktimer && clicktarget === el) {
        return;
      }

      dblclicktimer = setTimeout(() => {
        dblclicktimer = null;
      }, 300);

      clicktarget = el;

      if ($(global.window).width() < 500) {
        $(".interface").toggleClass("hide-aside", true);
      }

      let window = new Window(_this_.session);
      window.loading = true;

      try {
        await window.render();
        window.focus();

        let output = await _this_.session.request(
          "/Admin/WebServices/CoreWebServices.asmx/OpenMenuNode",
          {
            ID: $(this).attr("data-menu-id"),
          },
        );

        await window.process(output);
        window.loading = false;

        await window.render();
      } catch (err) {
        $("body").removeClass("avoid-clicks");
        console.error(err);
        window.dispose();
      }

      store.dispatch("addTab", window);
      $("body").removeClass("avoid-clicks");
    });
  }

  /**
   * Render base layout
   * @returns {void}
   */
  render() {
    let wasLocked = $("body").hasClass("locked");
    $("body")
      .toggleClass("locked", this.session.locked && this.session.active)
      .toggleClass("unlocked", !this.session.locked && this.session.active);

    if (this.session.setup !== null) {
      return;
    }

    if (!this.session.active || this.session.locked) {
      if (this.loginComponentInstance) {
        this.loginComponentInstance.$forceUpdate();
      } else {
        this.loginComponentInstance = new Vue({
          el: ".login",
          store,
          render: (h) =>
            h(LoginComponent, {
              props: {
                session: this.session,
              },
            }),
        });
      }

      if (!this.session.active) {
        // $(".interface").replaceWith($("<div>").addClass("interface"))
      }

      return;
    }

    if (wasLocked && !this.session.locked) {
      if (
        this.session.activeWindow != null &&
        !this.session.activeWindow.isDirty()
      ) {
        this.session.activeWindow.reload();
      }

      return;
    }

    if (
      this.session.kiosk ||
      (this.session.comboboxes.values == null &&
        this.session.moduleMenu == null)
    ) {
      $(".interface").toggleClass("hide-aside", true);
    }

    let oldElement = this.element;
    this.element = $(".canvas").get(0);

    if (this.element !== oldElement) {
      this.bindEvents();
    }

    this.findAnchor();

    this.makeFullscreen(this.session.fullscreen);

    // Check if the user follows a tutorial
    this.session.tutorial.checkTutorialMode();
  }

  /**
   * find and set anchor element
   * @returns {void}
   */
  findAnchor() {
    this.anchorElement = $(this.element).find("[data-window-anchor]").get(0);
  }

  /**
   * Log user out
   * @see Session.logout
   * @returns empty
   */
  logoff() {
    this.session.logout();
    return;
  }

  /**
   * show user settings
   * @returns {void}
   */
  usersettings() {
    let window = this.session.openWindow({
      Subject: "dbo.aspnet_Users",
      Prefix: "Single",
      Criteria: [{UserId: this.session.currentUser.UserId}],
    });
  }

  /**
   * Update window
   * @param {Window} window - Window class
   * @param {Window} original - Original window
   * @returns {Promise} Promise
   */
  async update(window, original, rerender) {
    if (!window.element || !$.contains(document.body, window.element)) {
      window.element = window.parent
        ? $(window.parent.element).find(".window.child").get(0)
        : $('[data-window-id="' + window.id + '"]').get(0);
      await new Promise((resolve) => setTimeout(resolve));
    }

    if (!window.element || !$.contains(document.body, window.element)) {
      console.log("Cannot render window due to missing HTML element.");
      window.element = getWindowElement({window});
    }

    let inlineStyle = $(window.element).attr("style");

    // save current scroll position
    const scrollContainer = document.querySelector("#scrollContainer");
    const scrollPosition = scrollContainer.scrollTop;

    if (!window.vueTemplate && !window.vueComponent) {
      // render window
      const newWindowElement = templater.renderWindow(window);
      // Create a temporary container
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = newWindowElement;

      // Update checkbox state
      for (const checkbox of $(tempContainer).find("[type=checkbox]")) {
        checkbox.checked = checkbox.attributes.checked != null;
      }

      if (!window.element) {
        window.element = getWindowElement({window});
      }

      window.element.innerHTML = newWindowElement;
    }

    $(window.element).attr("style", inlineStyle);

    let activeWindow = null;
    if (window != null && window.output.Table != null) {
      activeWindow = window;
    }

    // Set activeWindow to the sub if one exists as subs are our new "active windows"
    if (activeWindow === null && window.sub) {
      activeWindow = window.sub.window;
    }
    if (this.session.activeWindow && window === this.session.activeWindow) {
      $(window.element).addClass("active-window");
    }

    //window.resize();

    if (window.sub && window.sub.window) {
      window.sub.window.element = $(
        '[data-window-id="' + window.sub.window.id + '"]',
      ).get(0);
    }

    if (window.output.Options) {
      if (
        window.output.Options.UploadUrl != null &&
        window.output.Data &&
        window.output.Data.Type === "table"
      ) {
        this.initializeDragAndDrop(window, window.output.Data.Type);
      } else if (window.output.Options.UploadUrl != null) {
        this.initializeDragAndDrop(window);
      }
    }

    if (!(original || window).loading) {
      $(".selection-box input[type='checkbox']", window.element).each(
        function () {
          let rowIndex = $(this)
            .closest("[data-row-index]")
            .attr("data-row-index");
          let _window = window.session.getClosestWindow(this);
          $(this).prop(
            "checked",
            typeof rowIndex !== "undefined" && !_window?.bulkedit
              ? _window?.isSelectedRow(rowIndex)
              : false,
          );
        },
      );

      if (
        (window.output.Request.Prefix == "New" ||
          window.output.Request.Prefix == "Single") &&
        window.options.autoFocus &&
        window.renderOptions.inactive == false
      ) {
        let $firstInput = $(window.element).find("[name]").first();
        let $closestCombo = $firstInput.closest(".combobox");

        if ($closestCombo.length > 0) {
          $firstInput = $closestCombo.find("input.combobox-input");
        }
      }
    }

    // Usage

    store.commit("updateWindow");

    await new Promise((resolve) => setTimeout(resolve));

    this.restoreScrollPosition({scrollPosition, scrollContainer});
  }

  async restoreScrollPosition({scrollPosition, scrollContainer}) {
    const timeout = 50; // 10 seconds
    const interval = 50; // Check every 50 milliseconds

    let timeElapsed = 0;

    while (
      (scrollContainer.scrollHeight <= scrollPosition ||
        scrollPosition < 1000) &&
      timeElapsed < timeout
    ) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      timeElapsed += interval;
    }

    scrollContainer.scrollTop = scrollPosition;
  }

  /**
   * Set drag/drop functionality for window
   * @param {Window} window
   * @returns {void}
   */
  initializeDragAndDrop(window, type) {
    let $dragarea;

    if (!type) {
      $dragarea = $(window.element);
    } else {
      $dragarea = $(".table-row");
    }
    // Remove old drag and drop events
    //$(window.element).unbind()
    $dragarea.off("drag dragstart dragend dragover dragenter dragleave drop");

    if (!type) {
      $dragarea
        .on("drag dragstart dragend dragover dragenter dragleave drop", (e) => {
          e.preventDefault();
          e.stopPropagation();
        })
        .on("dragenter", function () {
          $(this).find(".upload-info-box").toggleClass("hide", false);
        })
        .on("dragleave dragend drop", ".upload-info-box", function () {
          $(this).toggleClass("hide", true);
        })
        .on("drop", async function (e) {
          let droppedFiles = e.originalEvent.dataTransfer.files;

          if (window.output.Options.UploadUrl == null) {
            alert("Je kunt hier geen bestand uploaden");
          } else {
            window.actionUploadFile(droppedFiles);
          }
        });
    } else {
      $dragarea
        .on("drag dragstart dragend dragover dragenter dragleave drop", (e) => {
          e.preventDefault();
          e.stopPropagation();
        })
        .on("dragenter", function () {
          $(this).css("background-color", "rgba(0,0,0,0.45)");
          $(this).css("opacity", "0.8");

          $(this).css("height", "50px");
          $(this).css("font-size", "16px");
        })
        .on("dragleave dragend drop", function () {
          let $row = $(this).closest(".table-row");

          $row.css("background-color", "");
          $row.css("height", "");
          $row.css("font-size", "");
          $row.css("opacity", "");
        })
        .on("drop", async function (e) {
          let rowID;
          if ($(this)[0]) {
            rowID = $(this)[0]["data-row-index"];
          } else {
            rowID = $(this)["data-row-index"];
          }

          let requestUrl = window.output.Options.UploadUrl;

          let primaryKey = global.session.activeWindow.buildCriteriaNew(
            global.session.activeWindow.output.FullTable.Rows[rowID],
          );
          let droppedFiles = e.originalEvent.dataTransfer.files;

          if (requestUrl == null) {
            alert("Je kunt hier geen bestand uploaden");
          } else {
            window.actionUploadFile(droppedFiles, primaryKey);
          }
        });
    }
  }

  /**
   * Emit resize event for all windows
   * @returns {void}
   * @deprecated
   */
  emitResizeAll() {
    for (let window of this.session.tabs) {
      window.resize();
    }
  }

  /**
   * Make canvas fullscreen
   * @param {boolean} isFull - If is fullscreen
   * @returns {void}
   * @deprecated
   */
  makeFullscreen(isFullscreen) {
    if (typeof isFullscreen === "undefined") {
      throw new Error("isFull (first argument) should be specified (bool)");
    }

    this.fullscreen = isFullscreen;
    this.draggers.forEach((d) => (d.enabled = !this.fullscreen));
    this.move();
    $(this.element).toggleClass("fullscreen", this.fullscreen);
    if (isFullscreen) {
      $(this.element).find("[data-window-anchor]").css("position", "");
    }
    this.emitResizeAll();
  }

  /**
   * Move all windows(set base anchor)
   * @param {number} x - X position
   * @param {number} y - Y position
   * @returns {void}
   */
  move(x, y) {
    if (this.fullscreen) {
      return;
    }

    this.x = x || 0;
    this.y = y || 0;

    $(this.element).toggleClass("fullscreen", this.fullscreen);

    $(this.element).css({
      "background-position": `${x}px ${y}px`,
    });

    $(this.anchorElement).css({
      position: !this.fullscreen ? "absolute" : "",
      left: this.x + "px",
      top: this.y + "px",
    });
  }

  /**
   * Set correct width for headers
   * @param {Window} window - Window element
   * @returns {void}
   */
  async correctTableHeaders(window) {
    if (!window) return;
    $(".table-view", window.element).each(function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const tableElement = this;
      const headerRow = $(
        ".table-index .table-row > .table-cell",
        tableElement,
      );
      let firstContentRow = $(
        ".table-body .table-row > .table-cell",
        tableElement,
      );

      if (firstContentRow.length < 1) {
        firstContentRow = $(
          ".table-index .table-row > .table-row-group > .table-cell",
          tableElement,
        );
      }

      headerRow.each(function () {
        let index = $(this).index();
        let cell = firstContentRow.eq(index);

        if (!cell || !cell.length) {
          return;
        }

        let width = cell.outerWidth();
        $(this).css({
          width: width,
          "min-width": width,
          "max-width": width,
        });
      });
    });
  }

  /**
   * Fix comboboxes
   * @returns {void}
   * @todo Remove if not used or not needed
   */
  static fixCombobox() {
    // fix combo width
    $(".combobox", document).each(function () {
      let $this = $(this);
      let w = $this.outerWidth();
      $this.find(".menu").css({width: w});
    });
  }

  /**
   * Bind events once
   * @returns {void}
   */
  bindEvents() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _this_ = this;

    $(window).resize(() => {
      if (this.fullscreen) {
        this.emitResizeAll();
      }

      Canvas.fixCombobox();
    });

    Canvas.fixCombobox();

    Hook.register("afterDispose", this, (window, e) => {
      if (window.element && !e.cancel && !window.parent) {
        $(window.element).remove();
      }
    });

    Hook.register("afterCreate", this, (window) => {
      if (!window.element) {
        window.element = document.createElement("div");
      }

      if (!window.parent) {
        this.anchorElement.appendChild(window.element);
      }
    });

    const breakPoints = {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
    };

    Hook.register("afterResize", this, (window) => {
      if (!window.element) {
        return;
      }

      let $els = $(window.element);

      if (window.sub && window.sub.window && window.sub.window.element) {
        $els = $els.add(window.sub.window.element);
      }

      let realWidth = window.element.offsetWidth;

      for (let breakPointName in breakPoints) {
        let className = "grid-" + breakPointName;
        let minWidth = breakPoints[breakPointName];
        let enable = realWidth >= minWidth;

        $els.toggleClass(className, enable);
      }

      $els.find("#scrollContainer").trigger("scroll");
      Canvas.fixCombobox();
    });

    Hook.register("afterRender", this, (window) => {
      $("#canvas")
        .off("scroll")
        .on("scroll", function () {
          Canvas.fixCombobox();
        });

      if (window.element != null && window.options.autoFocus) {
        setTimeout(() => {
          // $(window.element).find("input:enabled:visible, [contenteditable=true]").first().focus()
        }, 0);
      }

      $("#scrollContainer")
        .off("scroll scrollstart touchstart touchmove touchend")
        .on("scroll scrollstart touchstart touchmove touchend", function (e) {
          $(".combobox", document).each(function () {
            scroll.positionMenu(this);
          });
        });

      if (
        window.output &&
        ((window.output.Data && window.output.Data.Type == "iframe") ||
          (window.output.Sub &&
            window.output.Sub.Data &&
            window.output.Sub.Data.Type == "iframe"))
      ) {
        $(window.element)
          .find("iframe")
          .on("load", function () {
            // Set title
            let iframeTitle = $(this).contents().find("title").text();
            if (iframeTitle != null && iframeTitle.length > 0) {
              window.setTitle(iframeTitle);
            }

            window.focus();
            store.commit("refreshTabs");
          });
      }
      setActiveClassToWindow({window});

      global.eventBus.emit("afterRender", window);
    });

    $(this.element).on(
      "mousedown",
      "[data-window-event] input",
      async function windowEventFix(e) {
        if (e.which !== 1) {
          return;
        }

        $(this).closest("[data-window-event]").trigger({
          type: "click",
          which: 1,
        });
      },
    );

    $(this.element).on(
      "click",
      "[data-window-event]",
      async function windowEvent(e) {
        if (e.which !== 1) {
          return;
        }

        let preventdefault = $(this).attr("data-window-event-preventdefault");
        if (preventdefault != "false") {
          e.preventDefault();
          e.stopImmediatePropagation();
        }

        let eventArgs = $("<div>")
          .html($(this).attr("data-window-event"))
          .text();
        let [eventName, ...args] = eventArgs.split(/:/g);
        let elementId = $(this).closest(".window").attr("data-window-id");

        let target = $(this).attr("data-window-target") || elementId;

        let $window = $(this).closest(".window");

        // New action bar is not able to find relative window, give it instead using activeWindow
        if (global.session.activeWindow.sub && $window[0] === undefined) {
          $window = global.session.activeWindow.sub.window.element;
        }
        let window = global.session.getClosestWindow($window);

        if (window == null) {
          window = global.session.activeWindow;
        }

        let targetWindows;

        if (target === "parent" && window.parent) {
          targetWindows = [window.parent];
        } else {
          targetWindows =
            target === "*"
              ? global.session.tabs.slice()
              : [].concat(
                  target === "active"
                    ? global.session.activeWindow
                    : global.session.windows[target],
                );
        }

        // TargetWindows are not defined, use activeWindow when headless, this occurs on NEW prefixed windows
        if (
          store.getters.headless &&
          (targetWindows.length === 0 || targetWindows[0] === undefined)
        ) {
          targetWindows = [global.session.activeWindow];
        }

        if (targetWindows.length === 0 || targetWindows[0] === undefined) {
          targetWindows = [global.session.activeWindow];
        }

        if ($(this).val()) {
          targetWindows[0].input.Data[$(this).val()] = true;
          targetWindows[0].output.Request.Data[$(this).val()] = true;
        }

        await Promise.all(
          targetWindows.map((w) => w.event(eventName, ...args)),
        );
      },
    );

    $("body").on("click", "[data-canvas-event]", function canvasEvent(e) {
      if (e.which !== 1) {
        return;
      }

      e.preventDefault();

      let [eventName, ...args] = $(this).attr("data-canvas-event").split(/:/g);
      let localFn = _this_[eventName];

      if (typeof localFn !== "function") {
        console.error(new Error("Canvas event not bound to a function"));
        return;
      }

      localFn.call(_this_, ...args);
    });

    $(this.element).on(
      "change",
      ".window select.number-of",
      function changeNumberOfSelectedFields() {
        if (!_this_.session.activeWindow) {
          return;
        }

        let $window = $(this).closest(".window");
        let newVal = $(this).val();
        if ($window.is(".child")) {
          _this_.session.activeWindow.sub.window.pagesize(newVal);
        } else {
          _this_.session.activeWindow.pagesize(newVal);
        }
      },
    );

    this.draggers.push(
      new Drag(
        () => [this.x, this.y],
        (target, x, y) => this.move(x, y),
        this.element,
        this.element,
        true,
      ),

      Drag.resize(".window.parent > div > [data-resize-mode]", (el) =>
        $(el).closest(".window").get(0),
      ).callback((target) => {
        let id = $(target).attr("data-window-id");
        let window = _this_.session.windows[id];
        window.resize();
      }),

      Drag.absolute(".window.parent > .window-head .handle", (el) =>
        $(el).closest(".window").get(0),
      ),
    );
  }
}
