var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',{staticClass:"bg-white divide-y divide-gray-300"},[_vm._l((_vm.visibleRows),function(row,index){return [(_vm.shouldRenderRow(row))?_c('datagrid-row',{key:`1-${row.ClientSideUUID.Value}-${index}`,attrs:{"row-columns":_vm.columnsPerRow[0],"columns":_vm.columns,"actions":_vm.actions,"show-action-column":_vm.showActionColumn,"row":row,"process-context":_vm.processContext,"warehouse-id":_vm.warehouseId,"index":index,"contains-advanced-columns":_vm.containsAdvancedColumns,"value":_vm.visibleRows[index],"extra-rows":_vm.columnsPerRow.length,"rows":_vm.rows},on:{"action":function($event){return _vm.$emit('action', $event)},"click-row":function($event){return _vm.$emit('click-row', $event)},"cell-click":function($event){return _vm.$emit('cell-click', $event)},"cell-icon-click":function($event){return _vm.$emit('cell-icon-click', $event)},"value-click":function($event){return _vm.$emit('value-click', $event)},"icon-event":function($event){return _vm.$emit('icon-event', $event)},"change":function($event){return _vm.processFieldUpdate({
          row,
          index,
          fieldName: $event.Name,
          cell: $event,
          value: $event.Value,
        })}}}):_vm._e(),(_vm.columnsPerRow[1] && _vm.shouldRenderRow(row))?_c('datagrid-row',{key:`2-${row.ClientSideUUID.Value}-${index}`,attrs:{"row-columns":_vm.columnsPerRow[1],"columns":_vm.columns,"row":row,"process-context":_vm.processContext,"warehouse-id":_vm.warehouseId,"index":index,"value":_vm.visibleRows[index],"is-extra-row":true,"rows":_vm.rows},on:{"change":function($event){return _vm.processFieldUpdate({
          row,
          index,
          fieldName: $event.Name,
          cell: $event,
          value: $event.Value,
        })},"click-row":function($event){return _vm.$emit('click-row', $event)},"cell-click":function($event){return _vm.$emit('cell-click', $event)},"icon-event":function($event){return _vm.$emit('icon-event', $event)}}}):_vm._e(),(_vm.columnsPerRow[2] && _vm.shouldRenderRow(row))?_c('datagrid-row',{key:`3-${row.ClientSideUUID.Value}-${index}`,attrs:{"row-columns":_vm.columnsPerRow[2],"columns":_vm.columns,"row":row,"process-context":_vm.processContext,"warehouse-id":_vm.warehouseId,"index":index,"value":_vm.visibleRows[index],"is-extra-row":true,"rows":_vm.rows},on:{"change":function($event){return _vm.processFieldUpdate({
          row,
          index,
          fieldName: $event.Name,
          cell: $event,
          value: $event.Value,
        })},"click-row":function($event){return _vm.$emit('click-row', $event)},"cell-click":function($event){return _vm.$emit('cell-click', $event)},"icon-event":function($event){return _vm.$emit('icon-event', $event)}}}):_vm._e(),(_vm.columnsPerRow[3] && _vm.shouldRenderRow(row))?_c('datagrid-row',{key:`4-${row.ClientSideUUID.Value}-${index}`,attrs:{"row-columns":_vm.columnsPerRow[3],"columns":_vm.columns,"row":row,"process-context":_vm.processContext,"warehouse-id":_vm.warehouseId,"index":index,"value":_vm.visibleRows[index],"is-extra-row":true,"rows":_vm.rows},on:{"change":function($event){return _vm.processFieldUpdate({
          row,
          index,
          fieldName: $event.Name,
          value: $event.Value,
          cell: $event,
        })},"click-row":function($event){return _vm.$emit('click-row', $event)},"cell-click":function($event){return _vm.$emit('cell-click', $event)},"icon-event":function($event){return _vm.$emit('icon-event', $event)}}}):_vm._e(),(_vm.shouldRenderRow(row))?_c('datagrid-row-advanced-fields',{key:`advanced-${row.ClientSideUUID.Value}-${index}`,attrs:{"row":row,"index":index,"columns":_vm.columns,"value":_vm.visibleRows[index],"process-context":_vm.processContext,"warehouse-id":_vm.warehouseId},on:{"change":function($event){return _vm.processFieldUpdate({
          row,
          index,
          cell: $event,
          fieldName: $event.Name,
          value: $event.Value,
        })}}}):_vm._e()]}),_vm._l((_vm.skeletonRows * _vm.columnsPerRow.length),function(index){return _c('datagrid-row-skeleton',{key:index,attrs:{"column-count":_vm.columnsPerRow[0].length + 2}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }