<template>
  <div class="flex flex-col w-full min-h-[80vh]">
    <div v-if="isLoading" class="flex justify-center items-center flex-grow">
      <i class="fas fa-spinner fa-spin text-5xl"></i>
    </div>

    <iframe
      v-if="showIFrame && url"
      :src="url"
      class="flex-grow border-none"
      @load="onIframeLoad"
    />
  </div>
</template>

<script>
import {handleJobExecution} from "../../functions/datagrid/actions/handleJobExecution.js";

export default {
  name: "IFrame",
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      windowData: null,
      isLoading: true,
      showIFrame: true,
    };
  },
  computed: {
    url() {
      return this.rWindow.data.Url;
    },
  },
  created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async reset(job) {
      try {
        this.showIFrame = false;

        await this.$nextTick();

        this.showIFrame = true;
      } finally {
        this.$emit("job-completed", job);
      }
    },
    onIframeLoad() {
      this.isLoading = false;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
