<template>
  <div class="h-[32.5px]">
    <input
      :id="fieldName"
      :value="value"
      :type="isPasswordField ? 'password' : 'text'"
      :autocomplete="isPasswordField ? 'new-password' : 'off'"
      class="form-control editable-text placeholder:text-primary"
      :maxlength="field.Length"
      :disabled="readonly"
      :readonly="readonly"
      :name="fieldName"
      :title="value"
      :placeholder="field.Placeholder"
      @change="handleChange"
      @keydown="$emit('keydown', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      originalValue: this.field.Value,
    };
  },
  computed: {
    value() {
      return this.field.Value;
    },
    isPasswordField() {
      return this.field.Editor === "password";
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  methods: {
    handleChange(newValue) {
      const text = newValue.target?.value;

      this.$emit("input", text);
    },
  },
};
</script>

<style scoped>
input[readonly] {
  background-color: #eeeeee;
  cursor: not-allowed;
  color: #888;
}
</style>
