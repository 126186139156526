<template>
  <td
    :colspan="column.Colspan || 1"
    :data-column-name="column.Name"
    :data-column-ranking="column.Ranking"
    class="border h-[32.5px]"
  >
    <div @click="handleCellIconClick">
      <datagrid-cell-icon
        v-if="cellHasIcon"
        :component="cellValue.IconComponent"
        :cell="cellValue"
        :icon="cellValue.Icon"
        :icon-color="cellValue.IconColor"
        :message="cellValue.IconMessage"
        :row="row"
        :rows="rows"
        :process-context="processContext"
        :component-data="cellValue.IconComponentData"
        :sub-message="cellValue.IconSubMessage"
        class="icon"
        @icon-event="$emit('icon-event', $event)"
      />
    </div>
    <datagrid-cell
      :class="{'ml-2': cellHasIcon}"
      :value="row[column.Name]"
      :columns="columns"
      :name="column.Name"
      :row="row"
      :rows="rows"
      :warehouse-id="warehouseId"
      @change="handleChange"
      @row-change="handleRowChange"
      @cell-click="$emit('cell-click', $event)"
      @value-click="handleValueClick"
    />
  </td>
</template>

<script>
import DatagridCell from "./DatagridCell.vue";
import DatagridCellIcon from "@/components/datagrid/DatagridCellIcon";

export default {
  name: "DatagridCellWrapper",
  components: {DatagridCell, DatagridCellIcon},
  props: {
    value: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    processContext: {
      type: Object,
      required: true,
    },
    warehouseId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    row() {
      return this.value;
    },
    cellValue() {
      return this.row[this.column.Name];
    },
    cellHasIcon() {
      return this.cellValue && this.cellValue.Icon;
    },
  },
  methods: {
    handleCellIconClick($event) {
      // skip if event target has a data attribute data-tooltip or one of its parents does
      if ($event.target.closest("[data-tooltip]")) {
        return;
      }
      this.$emit("cell-icon-click", {
        row: this.row,
        cell: this.row[this.column.Name],
        column: this.column,
        event: $event,
      });
    },
    handleChange(newValue) {
      this.$emit("change", newValue);
    },
    handleRowChange(newRow) {
      this.row = Object.assign({}, newRow);
    },
    handleValueClick({event, row, column}) {
      this.$emit("value-click", {
        row,
        cell: this.row[this.column.Name],
        column,
        event,
      });
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  font-size: 15.4px;
}

.icon {
  position: relative;
  top: 1px;
  height: 0;
  font-size: 11px;
  max-width: 15px;
}
</style>
