import dayjs from "dayjs";
import {axiosInstance} from "../axiosInstance.js";
import store from "../../../state/store";

export const deliverOrderItems = async ({rows, order, fields}) => {
  const orderItemIds = [];
  rows.map((row) => orderItemIds.push({OrderItemID: row.Values.OrderItemID}));

  return await axiosInstance.post(
    `/Admin/WebServices/RentWebServices.asmx/DeliverOrderItems`,
    {
      ExtraData: null,
      selectionInfo: {
        ActionName: "quickrent-submit",
        Arguments: {
          isNewObject: false,
          EnableSelectAll: true,
          initialDate: dayjs(order.DateTimeBusinessStart).format("DD-MM-YYYY"),
          CanAddNewItems: true,
          CustomerID: order.CustomerID.Key,
          CustomerIDName: order.CustomerID.Description,
          ...fields,
        },
        Request: {
          IsSubWindow: false,
          HideEmptyPlaceHolder: false,
          HideDefaultFilters: false,
          Subject: "Rental.virtual_PartialDelivery",
          TableToSelect: null,
          ParentSubject: "",
          TitleResourceID: "Delivery",
          SubTitleResourceID: null,
          ExcelAction: null,
          LinkedSubject: null,
          Prefix: "Multi",
          Headless: false,
          RunDesktopHooks: true,
          Criteria: [
            {
              Target: "Rental.Order",
              OrderID: order.OrderID,
            },
          ],
          EntityTranslationLanguage: null,
          IsTableValuedFunction: false,
          TableValuedFunctionParameters: null,
          Data: {
            Search: [],
            Filters: {},
            SortOn: null,
            SortReverse: false,
            SelectedTabActionName: null,
            PageNumber: 1,
            PageSize: null,
            ShowCurrencyToggle: false,
            ToggleCurrency: false,
            ClientCurrency: null,
            ClientCurrencySymbol: null,
            OldClientCurrency: null,
            ForeignCurrency: null,
          },
          PreviousPostBackValues: null,
          PostBackValues: null,
        },
        Data: {
          Changes: rows,
          Selection: orderItemIds,
        },
      },
    },
    {
      params: {
        warehouse: store.state.activeWarehouse,
      },
    },
  );
};
