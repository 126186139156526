import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {getRowConflict} from "./getRowConflict";
import translation from "@/util/translation";
import {getStock} from "@/services/rental/itemserial/getStock";
import dayjs from "dayjs";

export async function processRowsItemSerialStock({rows, row, order}) {
  const newRow = {...row};
  const itemType = getRawValueFromDropdownValue(row.ItemType.Value);
  const serialId = getRawValueFromDropdownValue(row.SerialID.Value);

  if (
    itemType === "Free" ||
    !serialId ||
    newRow.rowMeta?.stock - newRow.Amount.Value < 0
  )
    return row;

  if (getRowConflict({row, rows})) {
    const iconData = setWarningIconMetaDataStock({
      stock: 0,
      totalAmount: 0,
      subMessage: translation(
        "Rental.OrderItem-MultiEdit-SerialSelectedMoreThenOnceOnOrder",
      ),
    });
    newRow.SerialID = {...newRow.SerialID, ...iconData};
    return newRow;
  }

  const startDate = dayjs(row.DateTimeExpectedStart.Value).format("YYYY-MM-DD");
  const endDate = row.DateTimeExpectedEnd.Value
    ? dayjs(row.DateTimeExpectedEnd.Value).format("YYYY-MM-DD")
    : null;

  const {Stock} = await getStock({
    serialId,
    startDate,
    endDate,
    excludeOrderId: order.OrderID,
    warehouseID: getRawValueFromDropdownValue(row.WarehouseID.Value),
  });

  if (parseInt(Stock) < 1) {
    const iconData = setWarningIconMetaDataStock({
      subMessage: translation("Rental.OrderItem-MultiEdit-SerialNotAvailable"),
    });
    newRow.SerialID = {...newRow.SerialID, ...iconData};
    return newRow;
  }

  const iconData = setSuccessIconMetaDataStock();
  newRow.SerialID = {...newRow.SerialID, ...iconData};

  return newRow;
}

function setSuccessIconMetaDataStock() {
  return {
    Icon: "fa-check",
    IconColor: "success",
    IconMessage: translation(
      "Rental.OrderItem-MultiEdit-SerialNoStockConflicts",
    ),
    IconSubMessage: null,
  };
}

function setWarningIconMetaDataStock({subMessage}) {
  return {
    Icon: "fa-exclamation-triangle",
    IconColor: "danger",
    IconMessage: translation("Rental.OrderItem-MultiEdit-SerialNoStock"),
    IconSubMessage: subMessage,
  };
}
