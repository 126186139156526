import {alertPopup} from "../../../interface/alertPopup/alertPopup";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations";

export const alertCloseAllWindows = async ({dirtyWindows, overrideMessage}) => {
  const translations = getTranslations();

  const dirtyWindowTitles = [
    ...new Set(dirtyWindows.map((window) => window.title)),
  ];
  const dirtyWindowTitlesList = dirtyWindowTitles
    .map((title) => `<li>${title}</li>`)
    .join("");

  let message = overrideMessage ?? translations.ConfirmCloseAllWindowsWarning;
  message = message.replace(
    "[windowTitles]",
    `<ul>${dirtyWindowTitlesList}</ul>`,
  );

  const alertObject = {
    text: message,
    icon: "warning",
    dangerMode: true,
    buttons: [
      {
        text: translations.Cancel,
        value: null,
        variant: "secondary",
      },
      {
        text: translations.Continue,
        value: true,
        variant: "primary",
      },
    ],
  };

  return await alertPopup(alertObject);
};
