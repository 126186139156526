import {isRowDeletable} from "../isRowDeletable";

export function canDeleteSingleRow({row}) {
  if (!isRowDeletable({row})) return false;
  if (row.Composition?.rowMeta?.virtual === true) return false;
  if (!row.Composition?.Value) return true;

  return ["Adjustable", "AdjustableDownwards"].indexOf(
      row.CompositionDefinition?.Value?.QuantityPolicy,
  ) > -1;
}
