
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/table/tableBody.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["data", "window", "settings", "setColumnCell", "tableContainsColoredRow"], 
[], 
function (l_data, l_window, l_settings, l_setColumnCell, l_tableContainsColoredRow, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", l_data);
frame.set("window", l_window);
frame.set("settings", l_settings);
frame.set("setColumnCell", l_setColumnCell);
frame.set("tableContainsColoredRow", l_tableContainsColoredRow);
var t_2 = "";t_2 += "\n<div class=\"table-body ";
t_2 += runtime.suppressValue((runtime.memberLookup((l_window),"bulkedit")?"bulkedit":""), env.opts.autoescape);
t_2 += "\">\n  <div class=\"table-rows\">\n    ";
frame = frame.push();
var t_5 = runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Rows");
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("row", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n      ";
var t_7;
t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("index", t_7, true);
if(frame.topLevel) {
context.setVariable("index", t_7);
}
if(frame.topLevel) {
context.addExport("index", t_7);
}
t_2 += "\n      ";
if(runtime.inOperator("*",runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "index"))) && runtime.inOperator("Color",runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "index"))),"*"))) {
t_2 += "\n        ";
var t_8;
t_8 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "index"))),"*")),"Color");
frame.set("rowColor", t_8, true);
if(frame.topLevel) {
context.setVariable("rowColor", t_8);
}
if(frame.topLevel) {
context.addExport("rowColor", t_8);
}
t_2 += "\n      ";
;
}
else {
t_2 += "\n        ";
var t_9;
t_9 = "transparent";
frame.set("rowColor", t_9, true);
if(frame.topLevel) {
context.setVariable("rowColor", t_9);
}
if(frame.topLevel) {
context.addExport("rowColor", t_9);
}
t_2 += "\n      ";
;
}
t_2 += "\n\n      <div class=\"table-row\" data-row-index=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
t_2 += "\">\n\n      ";
if(l_tableContainsColoredRow) {
t_2 += "\n        <div class=\"table-cell !min-w-[10px]\" style=\"box-shadow: inset 10px 0 0 0 ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rowColor"), env.opts.autoescape);
t_2 += ";\"></div>\n      ";
;
}
t_2 += "\n\n      ";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"output")),"Options")),"DisableSelection")) {
t_2 += "\n        <label class=\"table-cell checkbox selection-box pt-1\"><input type=\"checkbox\" data-commit-checkbox=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
t_2 += "\" title=\"Select\"></label>\n      ";
;
}
var t_10;
t_10 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("rowIndex", t_10, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_10);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_10);
}
frame = frame.push();
var t_13 = t_6;
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("col", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
t_2 += "\n        ";
t_2 += runtime.suppressValue((lineno = 23, colno = 24, runtime.callWrap(l_setColumnCell, "setColumnCell", context, [runtime.memberLookup((t_14),"Column"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((t_14),"Value"),l_window,t_14,runtime.memberLookup((runtime.memberLookup((t_14),"Column")),"AllowEditOnTable"),runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "rowIndex"))])), env.opts.autoescape);
t_2 += "\n      ";
;
}
}
frame = frame.pop();
t_2 += "\n    </div>\n    ";
;
}
}
frame = frame.pop();
t_2 += "\n\n    ";
if(runtime.memberLookup((l_window),"bulkedit")) {
t_2 += "\n    <div class=\"table-row new-row\">\n      <label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
t_2 += "\" title=\"Select\"></label>\n      ";
frame = frame.push();
var t_17 = runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Columns");
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("col", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
t_2 += "\n      ";
t_2 += runtime.suppressValue((lineno = 32, colno = 22, runtime.callWrap(l_setColumnCell, "setColumnCell", context, [t_18,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",l_window,null,null,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Metadata")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"))])), env.opts.autoescape);
t_2 += "\n      ";
;
}
}
frame = frame.pop();
t_2 += "\n    </div>\n    ";
;
}
t_2 += "\n  </div>\n</div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("renderTableBody");
context.setVariable("renderTableBody", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/table/tableBody.html", ctx);
          }
        
          return nunjucks.render("views/content/table/tableBody.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/table/tableBody.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        