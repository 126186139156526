import {v4 as uuidv4} from "uuid";
import {getTranslations} from "../src/functions/session/localstorage/getTranslations";
import {alertPopup} from "../src/interface/alertPopup/alertPopup";
import initializeTableElements from "./actions/initializeTableElements";
import {createProcessInfo} from "./resetWindow/createProcessInfo";
import {getRunningResetWindowCount} from "./resetWindow/getRunningResetWindowCount";
import {handleConfirmation} from "./resetWindow/handleConfirmation";
import {removeQueuedProcesses} from "./resetWindow/removeQueuedProcesses";
import {resetWindowContent} from "./resetWindow/resetWindowContent";
import {updateWindowState} from "./resetWindow/updateWindowState";
import {waitForRunningProcesses} from "./resetWindow/waitForRunningProcesses";

const actions = {
  addTab({commit}, window) {
    //global.session.tabs.unshift(window)
    commit("refreshTabs");
  },

  async resetWindow({commit}, {windowid, shouldConfirm}) {
    const window = global.session.windows[windowid];
    const resetId = uuidv4();
    const translations = getTranslations();

    const getRowData = (window) =>
      window.output?.Data?.Rows ?? window.sub.window.output?.Data?.Rows[0];

    const oldRow = getRowData(window);
    const processInfo = createProcessInfo({resetId, windowId: windowid});

    removeQueuedProcesses({windowId: windowid});
    global.runningProcesses.push(processInfo);

    await waitForRunningProcesses({resetId, windowId: windowid});

    const isProcessActive = () =>
      global.runningProcesses.some((process) => process.resetId === resetId);

    if (!isProcessActive()) return;

    processInfo.state = "executing";

    try {
      window.toggleLoading(true);

      if (window.output.Request.Subject === "Rental.Category") {
        window.reload();
      }

      if (!(await handleConfirmation({window, shouldConfirm}))) return;

      await resetWindowContent({window, shouldConfirm: false});

      const newRow = getRowData(window);
      const shouldCloseWindow =
        oldRow && !newRow && window.input.Prefix === "Single";

      if (shouldCloseWindow) {
        await alertPopup({
          text: translations.DataUnavailableScreenClosed,
          icon: "warning",
          size: "sm",
        });
        window.dispose();
      }

      updateWindowState({window, commit});
    } catch (err) {
      console.error(err);
    } finally {
      const isLastRunningReset =
        getRunningResetWindowCount({windowId: windowid}) === 1;

      if (isLastRunningReset) {
        window.toggleLoading(false);
      }

      global.runningProcesses = global.runningProcesses.filter(
        (process) => process.resetId !== resetId,
      );
    }
  },

  removeTabByIndex({commit}, index) {
    global.session.tabs.splice(index, 1);
    commit("updateWindow");
  },

  removeTabByID({commit}, windowid) {
    const tabs = global.session.tabs.filter(function (tab) {
      if (tab.id === windowid) {
        tab.dispose(true, false, true);
        return;
      }
      if (tab.id !== windowid) {
        return tab;
      }
    });

    global.session.tabs = tabs;
    commit("updateWindow");
  },

  replaceTabByIndex({commit}, {index, newWindow}) {
    global.session.tabs.splice(index, 0, newWindow);
    commit("refreshTabs");
    commit("setActiveWindowID", newWindow.id);
  },

  initializeTableElements({commit}, {window}) {
    initializeTableElements(window);
  },

  reopenLastWindow({state}) {
    const oldWindows = state.oldWindows;
    if (oldWindows) {
      for (const windowInput of oldWindows) {
        global.session.openWindow(windowInput);
      }
    }
  },
};

export default actions;
