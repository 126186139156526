<template>
  <div class="content mt-[2rem]">
    <form class="form-view no-gutters row" autocomplete="off">
      <form-content
        :sections="sections"
        :settings="settings"
        :form-fields="formFields"
        @field-change="handleFieldChange"
      />
      <form-sidebar
        :window-id="windowId"
        :metadata="metadata"
        :window-data="windowData"
        :r-window="rWindow"
        @field-change="handleFieldChange"
      />
    </form>
  </div>
</template>

<script>
import {processLegacyWindowForm} from "../../../functions/form/processLegacyWindowForm.js";
import {processInitialFormData} from "../../../functions/form/r-form/processInitialFormData";
import {processFormDirtiness} from "../../../functions/form/r-form/change-handlers/processFormDirtiness.js";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import FormSidebar from "../../form/formSidebar/FormSidebar.vue";
import FormContent from "./FormContent.vue";

export default {
  name: "RForm",
  components: {
    FormSidebar,
    FormContent,
  },
  provide() {
    return {
      translations: this.translations,
    };
  },
  props: {
    windowData: {
      type: Object,
      required: true,
    },
    windowId: {
      type: String,
      required: true,
    },
    sections: {
      type: Object,
      required: true,
    },
    rWindow: {
      type: Object,
      required: true,
    },
    initialRow: {
      type: Object,
      required: true,
    },
    formFields: {
      type: Array,
      required: true,
    },
    metadata: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    processInitialDataFunction: {
      type: Function,
      required: false,
      default: null,
    },
    processChangeFunction: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      translations: getTranslations(),
    };
  },
  async created() {
    let processedWindowData = processInitialFormData({
      windowData: this.windowData,
    });

    if (this.processInitialDataFunction)
      processedWindowData = await this.processInitialDataFunction({
        windowData: processedWindowData,
        windowId: this.windowId,
        vueComponent: this,
      });

    this.$emit("data-change", {
      newData: processedWindowData,
      windowId: this.windowId,
    });
  },
  methods: {
    async handleFieldChange({field}) {
      let newData = await this.processChangeFunction({
        windowData: this.windowData,
        windowId: this.windowId,
        changedField: field,
        vueComponent: this,
      });

      newData = await processFormDirtiness({
        windowData: newData,
        initialRow: this.initialRow,
      });

      this.$emit("data-change", {newData, windowId: this.windowId});

      processLegacyWindowForm({
        rWindowData: newData,
        windowId: this.windowId,
      });
    },
  },
};
</script>
