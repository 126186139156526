<template>
  <div class="flex justify-center items-center gap-0.5">
    <div
      v-if="!hideButtons && clearable"
      class="p-1 !cursor-pointer"
      @click="$emit('clear-selection')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="11"
        viewBox="0 0 24 24"
        fill="gray"
      >
        <path
          d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
        />
      </svg>
    </div>
    <div
      v-if="showOpenIndicator"
      class="w-[15px] flex justify-center"
      :class="{
        'mr-[1px]':
          (showRefButton && showCreateButton) ||
          (!showRefButton && !showCreateButton),
        'mt-[1px]': !isDropdownOpen,
        '-mt-[1px]': isDropdownOpen,
      }"
      @click="$emit('open-indicator-click')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="8"
        viewBox="0 0 15 11"
        role="presentation"
        class="vs__open-indicator"
        :class="{open: isDropdownOpen}"
      >
        <path
          d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
        ></path>
      </svg>
    </div>
    <div
      v-if="showCreateButton"
      class="flex justify-center items-center cursor-pointer"
      :class="{'w-[19.5px]': !showRefButton}"
      @click="$emit('open-create-entity', $event)"
    >
      <i
        class="fas fa-sharp fa-solid fa-plus !font-light !text-[16px] mb-[1px]"
      ></i>
    </div>
    <div
      v-if="showRefButton"
      class="material-icons cursor-pointer !text-[19.5px] !font-bold"
      @click.prevent="$emit('open-ref', $event)"
    >
      &#xe0b2;
    </div>
    <div v-if="loading" class="!w-[19.5px]">
      <i class="fas fa-spinner fa-spin text-1xl ml-0.5 mt-1"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "RSelectButtons",
  props: {
    showRefButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDropdownOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    showOpenIndicator() {
      return (
        !this.readOnly &&
        (!this.showRefButton && !this.showCreateButton ? !this.loading : true)
      );
    },
  },
};
</script>

<style>
.vs__open-indicator {
  color: black !important;
  fill: black !important;
  transform: scale(1.25);
}

.vs__open-indicator.open {
  transform: rotate(180deg);
}
</style>
