import $ from "jquery";
import Hook from "@/hook";
import formatter from "@/model/formatter";
import clone from "clone";

/**
 * Process server output to be used with window
 * @param {Object} output - Server output
 * @returns {Promise} Promise
 */
export default async function process(output, confirm) {
  $("body").addClass("avoid-clicks");

  await Hook.run("process", this, async () => {
    // go to table view, e. g. no bulk edit
    if (!output) {
      $("body").removeClass("avoid-clicks");
      return;
    }

    this.bulkedit = false;
    this.output = output;

    // filter buttons if needed
    if (this.input.buttonFilterCallback) {
      this.output.Buttons = this.input.buttonFilterCallback(
        this.output.Buttons,
      );
    }

    // process data
    formatter.format(this);

    // set input to what was send to server(because some hook might have edited)
    if (this.output.Request !== null) {
      // Always use criteria as selection for Form's (single's or custom prefixes)
      if (
        this.output.Data &&
        this.output.Data.Type == "form" &&
        this.output.Request.Prefix != "New"
      ) {
        this.selection = this.output.Request.Criteria;
      } else {
        this.selection = [];
      }

      this.input = clone(this.output.Request);
    }

    // remove __type property
    if (this.output.Request?.__type) {
      delete this.output.Request.__type;
    }

    // set tabs
    if (!this.parent) {
      this.tabs = this.output.Tabs || [];
    }

    // set sub window
    if (this.output.Sub !== null && this.output.Sub !== undefined) {
      await this.injectSub(this.output.Sub, true, null, confirm ?? true);
    }

    // if there is no default form to open but there are tabs we use a dummy window.
    if (this.tabs.length && !this.sub) {
      this.sub = {
        dummy: true,
      };
    }

    // keep inactive
    if (this.parent && this.parent.isDirty()) {
      this.renderOptions.inactive = true;
    }

    if (this.output.Data && this.output.Data.Type == "iframe") {
      this.renderOptions.actionbar = false;
    }
    //
    await this.loadSub();

    this.setCounter();
  });

  global.eventBus.emit("afterProcess", {windowId: this.id});

  $("body").removeClass("avoid-clicks");
}
