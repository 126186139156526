import {getGuides} from "../../../../services/guide.js";

export const fetchGuides = async ({subject, criteria, prefix}) => {
  if (prefix === "New") return [];
  if (!criteria || !subject) return [];
  const firstCriteria = criteria[0];

  const result = await getGuides({subject, criteria: firstCriteria});
  return Array.isArray(result) ? result : [];
};
