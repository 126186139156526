import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {isRowDeletable} from "../../row/isRowDeletable";

export function processFreeRow({row}) {
  const newRow = {...row};

  const itemType = getRawValueFromDropdownValue(newRow.ItemType.Value);
  if (itemType !== "Free") return row;

  newRow.DateTimeExpectedStart.IsReadOnly = true;
  newRow.DateTimeExpectedEnd.IsReadOnly = true;
  newRow.DateTimeBusinessStart.IsReadOnly = true;
  newRow.DateTimeBusinessEnd.IsReadOnly = true;

  newRow.ConditionID.IsReadOnly = true;
  newRow.ThemeGroupID.IsReadOnly = true;
  newRow.SerialID.IsReadOnly = true;
  newRow.SerialID.Value = null;

  newRow.Price.IsReadOnly = false;
  newRow.Discount.IsReadOnly = false;
  newRow.PeriodPrice.IsReadOnly = true;
  newRow.Period.IsReadOnly = true;
  newRow.PeriodAmount.IsReadOnly = true;

  newRow.ItemID.Editor = "String";
  newRow.ItemID.openRef = false;
  newRow.ItemID.IsReadOnly = false;

  if (!row?.DateTimeReturned?.Value) {
    newRow.ItemDescription.IsReadOnly = false;
  }

  newRow.rowMeta = {
    ...newRow.rowMeta,
    deletable: isRowDeletable({row: newRow}),
  };

  return newRow;
}
