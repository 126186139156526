import {getExtraCriteriaFromDropdown} from "./getExtraCriteriaFromDropdown.js";
import {getComboboxItems} from "../../../services/mainRadComboBox.js";
import {getActiveWindow} from "../../window/getActiveWindow.js";

export const fetchOptions = async ({
  field,
  row,
  searchValue,
  maxReturnableOptions,
}) => {
  const window = getActiveWindow();
  const subject =
    window.output.Request.Prefix === "Single" ||
    window.output.Request.Prefix === "New"
      ? window.output.Request.Subject
      : field.Dropdown.TableName;

  let primaryKeys = {};
  if (field.ReturnType) {
    primaryKeys = {
      ReturnType: field.ReturnType,
    };
  }

  if (field.Dropdown?.ExtraCriteria) {
    primaryKeys = getExtraCriteriaFromDropdown({field, row});
  }

  if (
    (Object.keys(primaryKeys).length > 0 &&
      primaryKeys.constructor === Object) ||
    field.Dropdown.TableName
  ) {
    let comboboxItems = [];
    try {
      comboboxItems = await getComboboxItems({
        tableName: field.Dropdown.TableName,
        columnName: field.Dropdown.ColumnName,
        subject,
        subjectColumnName: field.Name,
        searchValue,
        primaryKeys,
        maxReturnableOptions,
      });
    } catch (err) {
      console.error(err);
    }

    if (!comboboxItems?.Error) return comboboxItems;
  }
};
