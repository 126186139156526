import {getSettings} from "@/util/getSettings";

export function processSettingItemSinglePeriodEnforced({columns}) {
  const {FetchAllPeriods} = getSettings();
  const newColumns = columns.slice();
  columns.forEach((column) => {
    if (column.Name === "PeriodAmount") {
      column.IsVisible = true;
    }
    if (column.Name === "Period") {
      column.IsVisible = true;
      column.Editor = "Select";

      column.IsReadOnly = false;
      column.IsRequired = true;

      if (FetchAllPeriods) {
        column.Dropdown = {
          Items: null,
          TableName: "Settings.Period",
          ColumnName: "PeriodID",
          ExtraKeys: null,
          ExtraCriteria: [],
          PassthroughValues: null,
          OpenRef: true,
        };
        column.Callback = true;
      }
    }

    if (column.Name === "PeriodPrice") {
      column.IsVisible = true;
      column.ShowUnder === null ? (column.ShowUnder = "Period") : "";
    }

    if (column.Name === "DateTimeBusinessEnd") {
      column.IsReadOnly = true;
    }

    if (column.Name === "LockPrice") {
      column.IsReadOnly = true;
    }
    if (column.Name === "TotalExcVAT") {
      column.ShowUnder = null;
    }
    if (column.Name === "CalculatedPrice") {
      column.IsVisible = false;
    }
    if (column.Name === "PriceDifference") {
      column.IsVisible = false;
    }
  });
  return newColumns;
}
