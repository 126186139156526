import {cloneDeep} from "lodash";
import {generateTaskFilters} from "../../kanban/task/generateTaskFilters.js";
import {generateFilterForColumn} from "./generateFilterForColumn";

export function generateTaskListFilters({columns, filters, initialValues}) {
  const newFilters = cloneDeep(filters);
  for (const column of columns) {
    if (
      column.AllowFiltering &&
      !filters.find((filter) => filter.Target === column.Name)
    ) {
      newFilters.push(generateFilterForColumn({column}));
    }
  }

  return generateTaskFilters({filters: newFilters, initialValues});
}
