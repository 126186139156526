import Decimal from "decimal.js";

export const calculateRowTotalIncVat = ({price, vatPercentage, amount}) => {

    if(!price) return 0
    if(!vatPercentage) return price * amount;

    const pricePerUnitIncVat = new Decimal(price).times(1 + vatPercentage / 100).toDecimalPlaces(2).toNumber();

    return pricePerUnitIncVat * amount ?? 1;
}