import {isFieldDirty} from "../fields/isFieldDirty.js";

export const processFormDirtiness = async ({windowData, initialRow}) => {
  const newData = {...windowData};
  const newRow = newData.Rows[0];

  const dirtyFields = Object.keys(newRow).filter((field) =>
    isFieldDirty({
      field: newData.Columns[field],
      initialValue: initialRow[field],
      newValue: newRow[field],
    }),
  );

  newData.dirty = dirtyFields.length !== 0;

  Object.entries(newData.Columns).forEach(([field, column]) => {
    column.IsDirty = dirtyFields.includes(field);
  });

  return newData;
};
