import {generateNewRowFromScanItem} from "@/functions/datagrid/order-item/row/generateNewRowFromScanItem";
import {fetchDimensionObjects} from "@/functions/datagrid/order-item/dimensions/fetchDimensionObjects";
import {getUniqueDimensionsFromDimensionObjects} from "@/functions/datagrid/order-item/dimensions/getUniqueDimensionsFromDimensionObjects";
import {addDimensionValueToRow} from "@/functions/datagrid/order-item/dimensions/addDimensionValueToRow";
import {setAndForceMinDates} from "@/functions/datagrid/order-item/row/setAndForceMinDates";
import {processRowPrices} from "@/functions/datagrid/order-item/row/processRowPrices";
import {processTotalExcVat} from "@/functions/datagrid/order-item/row/processTotalExcVat";
import {calculateCalculatedPrice} from "@/functions/datagrid/order-item/row/calculateCalculatedPrice";
import {calculatePriceDifference} from "@/functions/datagrid/order-item/row/calculatePriceDifference";
import {processRowsItemSerialStock} from "@/functions/datagrid/order-item/row/processRowsItemSerialStock";
import {rowsStockFetch} from "@/functions/datagrid/order-item/rows/rowsStockFetch";
import {rowsProcessPopupStockPredictions} from "@/functions/datagrid/order-item/rows/rowsProcessPopupStockPredictions";
import {processInitialPeriodPrice} from "@/functions/datagrid/order-item/row/processInitialPeriodPrice";
import {processFreeRow} from "@/functions/datagrid/order-item/row/processFreeRow";
import {notify} from "@/util/notify";
import {processPopupPriceCalculations} from "./row/processPopupPriceCalculations";
import {addMissingKeysToRow} from "@/functions/datagrid/row/addMissingKeysToRows";
import {processPeriodColumnChange} from "@/functions/datagrid/order-item/row/processPeriodColumnChange";
import {physicalItemRowsStockFetch} from "./rows/physicalItemRowsStockFetch.js";
import {getNotificationRowAddedMessage} from "../getNotificationRowAddedMessage.js";
import {processScannedRowsConflicts} from "./rows/processScannedRowsConflicts";

export async function addNewRows({
  rows,
  item,
  order,
  settings,
  scanAmount,
  columns,
  vueInstance,
}) {
  let scannedRows = await generateNewRowFromScanItem({item, rows, order});
  let newRows = rows.slice();

  for (let row of scannedRows) {
    if (vueInstance.showDimensionObjects) {
      const dimensionObjects = await fetchDimensionObjects({
        customerId: order.CustomerID?.Key,
      });

      if (dimensionObjects && !dimensionObjects.Error) {
        const dimensions = await getUniqueDimensionsFromDimensionObjects({
          dimensionObjects,
        });

        for (const dimension of dimensions) {
          row = addDimensionValueToRow({row, dimension});
        }
      }
    }

    if (!vueInstance.rentalOrderItemPricesUpdatedDraftWebhookEvent) {
      row = await processRowPrices({row, order});
    }

    row = await processTotalExcVat({row, rows});
    row = setAndForceMinDates({row, rows});
    row = await calculateCalculatedPrice({order, row});
    row = await calculatePriceDifference({row, rows});
    row = await processRowsItemSerialStock({row, rows, order});

    if (settings.PeriodSelection === "ItemSinglePeriodEnforced") {
      row = processInitialPeriodPrice({row, rows, order});
    }
    row.Amount.Value = (scanAmount ?? 1) * row.Amount.Value;
    row = await processFreeRow({row, rows});
    row = addMissingKeysToRow({row, columns: columns});
    row = processPeriodColumnChange({row});
    row = processPopupPriceCalculations({row, rows});

    newRows.push(row);
  }

  newRows = await rowsStockFetch({rows: newRows, order});
  newRows = await physicalItemRowsStockFetch({rows: newRows, order});
  newRows = await rowsProcessPopupStockPredictions({rows: newRows});
  newRows = await processScannedRowsConflicts({rows: newRows, scannedRows});

  if (scannedRows.length > 0)
    notify({
      message: getNotificationRowAddedMessage({scannedRows}),
      type: "success",
    });

  return newRows;
}
