<template>
  <div class="form-field">
    <div class="flex items-center h-full">
      <div class="max-h-full shrink-0" @click="handleFieldClick">
        <upload-icon
          :class="{
            'fill-gray-600 hover:fill-black cursor-pointer my-2 h-1': true,
          }"
        />
      </div>
      <input
        ref="fileInput"
        type="file"
        style="display: none"
        @change="onFilePicked"
        @click="handleFieldClick"
      />

      <div class="h-[80%] w-[1px] mx-1 bg-gray-300 shrink-0"></div>
      <div
        v-if="!fileName"
        class="text-gray-500 cursor-pointer shrink-0"
        @click="handleFieldClick"
      >
        {{ translations.FileUploadButtonText }}...
      </div>
      <div
        v-if="fileName"
        class="text-gray-600 pr-2 flex w-full min-w-0"
        :title="fileName"
      >
        <div
          class="cursor-pointer truncate flex-1 min-w-0"
          :title="fileName"
          @click.prevent="
            downloadBase64({
              base64: base64File,
              filename: fileName,
              setDefaultPrefix: false,
            })
          "
        >
          <download-icon
            class="fill-gray-600 hover:fill-black h-1 shrink-0 inline"
          />
          <span
            class="text-black truncate"
            :href="base64File"
            :download="fileName"
          >
            {{ fileName }}
          </span>
        </div>
        <div
          class="ml-2 flex items-center shrink-0"
          @click.prevent="handleInput(null)"
        >
          <i class="fas fa-times cursor-pointer"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getTranslations} from "../../../functions/session/localstorage/getTranslations";
import {getAccessToken} from "../../../functions/getAccessToken.js";
import {downloadBase64} from "../../../util/downloadBase64.js";
import DownloadIcon from "../../icons/DownloadIcon.vue";
import UploadIcon from "../../icons/UploadIcon.vue";

const readFile = ({file}) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.readAsDataURL(file);
  });

const handleFileSelection = async ({files, onFileSelected}) => {
  if (files.length === 0) return;
  const file = files[0];
  const base64File = await readFile({file});
  onFileSelected({fileName: file.name, base64File});
};

export default {
  name: "UploadField",
  components: {UploadIcon, DownloadIcon},
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    downloadLink: null,
    accessToken: getAccessToken(),
    file: null,
  }),
  computed: {
    parsedValue() {
      return this.field.Value
        ? typeof this.field.Value === "string"
          ? JSON.parse(this.field.Value)
          : this.field.Value
        : null;
    },
    base64File() {
      return this.parsedValue?.Base64File;
    },
    fileName() {
      return this.parsedValue?.FileName;
    },
    translations: () => getTranslations(),
  },
  methods: {
    downloadBase64,
    handleFieldClick() {
      this.pickFile();
    },
    pickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      handleFileSelection({
        files: event.target.files,
        onFileSelected: this.updateFileData,
      });
    },
    updateFileData({fileName, base64File}) {
      this.$emit("input", {
        Value: JSON.stringify({FileName: fileName, Base64File: base64File}),
        json: true,
      });
    },
    handleInput($event) {
      this.$emit("input", {Value: null, json: true});
    },
  },
};
</script>

<style scoped>
.form-field {
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.09);
  border: 1px solid #ced4da;
  height: 29px !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
</style>
