import getRailroadGuides from "../../../../services/railroad/getRailroadGuides.js";

export const fetchRailroadGuides = async ({prefix, subject, criteria}) => {
  const supportedSubjects = [
    "Inbound.Order",
    "Inbound.Production",
    "Inbound.Invoice",
    "Rental.Order",
    "Rental.Quotation",
    "Rental.Invoice",
    "Rental.Service",
    "Rental.Reservation",
  ];

  if (prefix === "New" || !supportedSubjects.includes(subject)) return [];
  if (!criteria || !subject) return [];
  const firstCriteria = criteria[0];

  const result = await getRailroadGuides({subject, criteria: firstCriteria});
  return Array.isArray(result) ? result : [];
};
