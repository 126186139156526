import { render, staticRenderFns } from "./DatagridCellBoolean.vue?vue&type=template&id=136cc2ac&scoped=true&"
import script from "./DatagridCellBoolean.vue?vue&type=script&lang=js&"
export * from "./DatagridCellBoolean.vue?vue&type=script&lang=js&"
import style0 from "./DatagridCellBoolean.vue?vue&type=style&index=0&id=136cc2ac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136cc2ac",
  null
  
)

export default component.exports