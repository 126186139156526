export const areAllRowsInCompositionDeletable = ({compositionRow, rows}) => {
  const rowsThatBelongToComposition = rows.filter((row) => {
    return (
      (row.CompositionID?.Value?.Key ?? row.CompositionID?.Value) ===
      (compositionRow.CompositionID.Value?.Key ??
        compositionRow.CompositionID.Value)
    );
  });

  return rowsThatBelongToComposition.every((row) => row.rowMeta?.deletable);
};
