import dayjs from "dayjs";

export const formatTaskCardPropertyValue = ({column, value}) => {
  // if value === object, return value.Description
  if (typeof value === "object" && value !== null) {
    try {
      return value.Description;
    } catch (error) {
      console.error(`Error in formatTaskCardPropertyValue: ${error}`);
      return value;
    }
  }

  if (
    (column.Editor === "Date" ||
      column.Type === "Date" ||
      column.Editor === "dateCalendarOpeningsDay") &&
    value !== null
  ) {
    return dayjs(value).format("DD-MM-YYYY");
  }

  return value;
};
