import {formatTaskCardPropertyValue} from "./formatTaskCardPropertyValue.js";

export const getDataForTaskCard = async ({card, columns, displayedColumns}) => {
  if (!displayedColumns.length) return {};

  const entity = card.Entity;

  return displayedColumns.map((columnName) => {
    const value =
      columnName in entity.CustomFields
        ? entity.CustomFields[columnName]
        : entity[columnName];

    return {
      title: columns.find((col) => col.Name === columnName).Title,
      value: formatTaskCardPropertyValue({
        value: value,
        column: columns.find((col) => col.Name === columnName),
      }),
    };
  });
};
