import {generateNewRowFromScanItem} from "@/functions/datagrid/quotation-item/row/generateNewRowFromScanItem";
import {setAndForceMinDates} from "@/functions/datagrid/order-item/row/setAndForceMinDates";
import {processRowPrices} from "@/functions/datagrid/quotation-item/row/processRowPrices";
import {processTotalExcVat} from "@/functions/datagrid/order-item/row/processTotalExcVat";
import {processInitialPeriodPrice} from "@/functions/datagrid/order-item/row/processInitialPeriodPrice";
import {processFreeRow} from "@/functions/datagrid/quotation-item/row/processFreeRow";
import {addMissingKeysToRow} from "@/functions/datagrid/row/addMissingKeysToRow";
import {notify} from "@/util/notify";
import {processPopupPriceCalculations} from "../order-item/row/processPopupPriceCalculations.js";
import {getNotificationRowAddedMessage} from "../getNotificationRowAddedMessage.js";

export async function addNewRows({
  rows,
  item,
  quotation,
  settings,
  scanAmount,
  columns,
  vueComponent
}) {
  let scannedRows = await generateNewRowFromScanItem({item, rows, quotation});

  for (let row of scannedRows) {
    if (item.type === "ItemSerial" && row.SerialID.Value) {
      const compatibleMergeRowIndex = rows.findIndex((x) => {
        return (
          x.ItemID.Value === row.ItemID.Value &&
          x.Amount.Value === 1 &&
          x.SerialID.Value == null
        );
      });

      if (compatibleMergeRowIndex > -1) {
        rows[compatibleMergeRowIndex].SerialID.Value = row.SerialID.Value;
        rows[compatibleMergeRowIndex].Amount.IsReadOnly = true;

        return rows;
      }
    }

    row = await setAndForceMinDates({row});

    if (vueComponent.rentalQuotationItemPricesUpdatedDraftWebhookEvent) {
      row = await processRowPrices({row, quotation});
    }

    row = await processTotalExcVat({row, rows});
    row = setAndForceMinDates({row, rows});

    if (settings.PeriodSelection === "ItemSinglePeriodEnforced") {
      row = processInitialPeriodPrice({row, rows, quotation});
    }

    row.Amount.Value = (scanAmount ?? 1) * row.Amount.Value;
    row = await processFreeRow({row, rows});
    row = addMissingKeysToRow({row, columns: columns});
    row = processPopupPriceCalculations({row});

    rows.push(row);
  }

  if (scannedRows.length > 0)
    notify({
      message: getNotificationRowAddedMessage({scannedRows}),
      type: "success",
    });

  return rows;
}
