import {fieldCriteriaIsLinkedToChangedField} from "../../../../dialog/form/fieldCriteriaIsLinkedToChangedField.js";
import {fieldContainsExtraCriteria} from "../../../fieldContainsExtraCriteria.js";
import {processLinkedFormFieldFromCriteria} from "./processLinkedFormFieldFromCriteria.js";

export const processLinkedFieldsExtraCriteria = async ({
  changedField,
  columns,
  row,
}) => {
  let updatedColumns = {...columns};
  let updatedRow = {...row};

  for (const field of Object.values(columns)) {
    const rowValue = row[field.Name];

    if (
      rowValue &&
      fieldContainsExtraCriteria({field}) &&
      fieldCriteriaIsLinkedToChangedField({field, changedField})
    ) {
      const {newColumns, newRow} = await processLinkedFormFieldFromCriteria({
        linkedField: field,
        columns: updatedColumns,
        row: updatedRow,
      });

      updatedColumns = {...newColumns};
      updatedRow = {...newRow};
    }
  }

  return {newColumns: updatedColumns, newRow: updatedRow};
};
