export const getFormattedSelectFieldValue = ({field, options}) => {
  let option = options?.find(
    (item) => item.Value?.toString() === field.Value?.toString(),
  );

  if (!option && field.Label && field.Value)
    return {
      Text: field.Label,
      Value: field.Value,
      SubDescription: field.SubDescription ?? "",
      Color: field.Color ?? null,
    };

  if (!option) return field.Value;

  if (option?.Value === -1 && field.Label)
    option = {...option, Text: field.Label};

  return option;
};
