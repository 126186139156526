<template>
  <div v-if="!headless" class="row tab-wrapper">
    <div class="col-xs-12 col-md-9">
      <tabs
        :tabs="session.tabs"
        @close-all-windows="$emit('close-all-windows')"
      />
    </div>
    <div class="col-xs-12 col-md-3">
      <div class="row">
        <div class="col-xs-12 col-lg-6">
          <div class="mb-3 mt-3 h-[32.5px]">
            <warehouse-selector
              :active-warehouse="activeWarehouse"
              @change-warehouse="$emit('change-warehouse', $event)"
            />
          </div>
        </div>
        <div class="col-xs-12 col-lg-6">
          <div class="mb-3 mt-3 h-[32.5px]">
            <quick-action-search :settings="settings" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuickActionSearch from "./QuickActionSearch.vue";
import WarehouseSelector from "./WarehouseSelector.vue";
import tabs from "./tabs/tabs.vue";

export default {
  components: {QuickActionSearch, WarehouseSelector, tabs},
  props: {
    session: {
      required: true,
      type: Object,
    },
    settings: {
      required: true,
      type: Object,
    },
    headless: {
      required: false,
      type: Boolean,
      default: false,
    },
    activeWarehouse: {
      required: false,
      type: String,
      default: "",
    },
  },
};
</script>
