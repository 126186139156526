<template>
  <input
    :id="fieldName"
    :value="formattedValue"
    type="text"
    class="form-control editable-text text-right placeholder:text-primary"
    :readonly="readonly"
    :disabled="readonly"
    :maxlength="field.Length"
    :title="formattedValue"
    @change="handleChange"
  />
</template>

<script>
import {handleDecimalFieldChange} from "../../../functions/form/input-field-logic/handleDecimalFieldChange.js";
import {parseDecimalValue} from "../../../util/parsing/parseDecimalValue.js";
import {parseCommaToPoint} from "../../../functions/parsing/parseCommaToPoint.js";

export default {
  name: "DecimalField",
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    decimalPlaces: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  computed: {
    formattedValue() {
      return parseDecimalValue({
        value: this.field.Value,
        decimalPlaces: this.computedDecimalPlaces,
        min: this.field.MinNumber ?? 0,
        max: this.field.MaxNumber ?? 0,
      });
    },
    computedDecimalPlaces() {
      return this.decimalPlaces ?? this.field.NumericScale;
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  methods: {
    handleChange(event) {
      handleDecimalFieldChange({
        value: parseCommaToPoint({value: event.target.value.toString()}),
        field: this.field,
        vueComponent: this,
      });
    },
  },
};
</script>

<style scoped>
input[readonly] {
  background-color: #eeeeee;
  cursor: not-allowed;
  color: #888;
}
</style>
