import {fetchRailRoadTasksWithCorrespondingEntity} from "../../table/core-rail-road-task/fetchRailRoadTasksWithCorrespondingEntity";
import {convertRailroadTasksToKanbanCards} from "../convertRailroadTasksToKanbanCards.js";
import {getDataForTaskCard} from "./getDataForTaskCard.js";
import {handleLoadAnimationOnWindow} from "./handleLoadAnimationOnWindow.js";
import {parseAllFilters} from "./parseAllFilters.js";

export const fetchAndProcessCards = async ({
  filters,
  searchValues,
  maxRows,
  windowId,
  loading,
  tableName,
  entityColumns,
}) => {
  const parsedFilters = parseAllFilters({filters, searchValues, maxRows});
  const {tasks} = await fetchRailRoadTasksWithCorrespondingEntity({
    ...parsedFilters,
    tableName,
    filterOnFirstOpenTaskForEntity: true,
  });
  const cards = await convertRailroadTasksToKanbanCards({tasks, entityColumns});

  const cardsWithGetPropertiesFunction = cards.map((card) => {
    return {
      ...card,
      getCardProperties: getDataForTaskCard,
    };
  });

  handleLoadAnimationOnWindow({loading, windowId});

  return cardsWithGetPropertiesFunction;
};
