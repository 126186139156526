<template>
  <r-form
    :window-data="windowData"
    :initial-row="initialFormRow"
    :window-id="windowId"
    :sections="sections"
    :r-window="rWindow"
    :metadata="metadata"
    :form-fields="formFields"
    :settings="settings"
    :process-change-function="processQuotationFormFieldChange"
    :process-initial-data-function="processQuotationFormFieldChange"
    @data-change="$emit('data-change', $event)"
  />
</template>

<script>
import {processQuotationFormFieldChange} from "../../../functions/form/r-form/quotation/processQuotationFormFieldChange";
import {createFormFieldsFromWindowData} from "../../../functions/form/r-form/createFormFieldsFromWindowData.js";
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {validateRequiredFields} from "../../../functions/form/r-form/fields/validateRequiredFields.js";
import {processDaysCalculated} from "../../../functions/form/r-form/change-handlers/processDaysCalculated";
import {getWindowFromWindowId} from "../../../functions/window/getWindowFromWindowId.js";
import {handleJobExecution} from "../../../functions/datagrid/actions/handleJobExecution.js";
import RForm from "../r-form/RForm.vue";

export default {
  name: "RFormRentalQuotation",
  components: {
    RForm,
  },
  inject: ["translations"],
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentJob: null,
    };
  },
  computed: {
    windowData() {
      return processDaysCalculated({newData: this.rWindow.data});
    },
    initialFormRow() {
      return this.rWindow.initialData.Rows[0];
    },
    formRow() {
      return this.windowData?.Rows?.[0];
    },
    windowId() {
      return this.rWindow.id;
    },
    sections() {
      return this.windowData?.Sections;
    },
    metadata() {
      if (!this.windowData?.Metadata?.length > 0) return {};
      return this.windowData.Metadata[0];
    },
    formFields() {
      return createFormFieldsFromWindowData({
        row: this.formRow,
        columns: this.windowData?.Columns ?? {},
        metadata: this.metadata,
        windowId: this.windowId,
      });
    },
  },
  watch: {
    "rWindow.jobs": {
      handler: function (newJobs) {
        // If there's already a job running, don't start another
        if (this.currentJob) return;

        if (newJobs && Object.keys(newJobs).length > 0) {
          const firstJob = Object.values(newJobs)[0];
          this.currentJob = firstJob;
          this.handleJobExecution(firstJob);
        }
      },
      deep: true,
    },
  },
  methods: {
    async save() {
      return await validateRequiredFields({
        formFields: this.formFields,
        row: this.formRow,
        translations: this.translations.translations,
      });
    },
    async reset() {
      try {
        this.$store.state.loading = true;
        if (this.windowData.dirty && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        const window = getWindowFromWindowId({
          windowId: this.windowId,
          session: global.session,
        });

        await window.reload();
      } finally {
        this.$store.state.loading = false;
      }
    },
    processQuotationFormFieldChange,
    async handleJobExecution(job) {
      try {
        this.$emit("job-completed", job);
        await handleJobExecution({job, vueInstance: this});
      } finally {
        this.currentJob = null;
      }
    },
  },
};
</script>
