<template>
  <r-modal
    v-model="show"
    :show="show"
    :title="translations.ModalDayPlannerTitle"
    :show-footer="false"
    size="xl"
    @hide="closeDialog"
  >
    <div
      id="modalItemAvailabilityInitialBody"
      class="relative item-picker-stock-dialog"
    >
      <div v-if="clickedSegment">
        <DialogItemStock
          :segment="clickedSegment"
          :warehouse="warehouse"
          @close-item-stock-dialog="closeItemStockDialog"
        />
      </div>
      <div
        id="notifyDialog"
        class="alert alert-warning alert-dismissible fade show hidden"
        role="alert"
      >
        <span id="notifyDialogContent"></span>
        <button
          aria-label="Close"
          class="close"
          data-dismiss="alertPopup"
          type="button"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div v-show="!clickedSegment">
        <div v-if="itemId === null" class="ml-[-8px]">
          <form name="availabilitySearch">
            <div class="flex items-center flex-wrap">
              <div class="w-1/5">
                <label class="form-field">
                  <div class="form-label ml-0.5">
                    <i class="fas fa-clipboard-list"></i>
                    {{ translations.ModalDayPlannerCategoriesLabel }}
                  </div>
                  <div id="categorySelector" class="form-input combobox-scan">
                    <multi-tree-select-field
                      v-model="categoryId"
                      :field="categorySelectField"
                      :multiple="false"
                      @input="handleCategoryInput"
                    />
                  </div>
                </label>
              </div>

              <div class="w-1/5 h-full">
                <label class="form-field">
                  <div class="form-label">
                    <i class="fas fa-filter"></i>
                    {{ translations.ModalDayPlannerFilterByTextLabel }}
                  </div>
                  <div class="form-input">
                    <input
                      v-model="searchCriteria"
                      class="form-control min-h-[31.5px]"
                      name="SearchCriteria"
                      type="text"
                      @input="search"
                    />
                  </div>
                </label>
              </div>

              <div class="mt-2.5 ml-2">
                <button
                  class="btn btn-primary py-1 px-4"
                  type="submit"
                  @click="search"
                >
                  <span v-if="!searching">{{
                    translations.ModalDayPlannerSearchTitle
                  }}</span>
                  <span v-else><i class="fas fa-spinner fa-spin"></i></span>
                </button>
              </div>

              <div class="ml-auto">
                <div class="form-field">
                  <div class="form-label">
                    <i class="fas fa-filter"></i>
                    {{ translations.FilteredUnto }}
                  </div>
                  <div class="form-input h-[31.5px]">
                    <DataFilterDateRange
                      :filter="dateFilter"
                      @input="handleDatePick"
                    />
                  </div>
                </div>
              </div>

              <div class="ml-2 z-1">
                <label class="form-field min-w-[200px]">
                  <div class="form-label ml-0 pl-0">
                    <i class="fas fa-clipboard-list"></i>
                    {{ translations["Warehouse"] }}:
                  </div>
                  <div class="h-[31.5px]">
                    <r-select
                      :value="warehouse"
                      input-id="warehouseSelector"
                      label="Text"
                      :options="$store.state.warehouses"
                      :required="true"
                      :read-only="!loaded"
                      :loading="!loaded"
                      @input="handleWarehouseChange"
                    />
                  </div>
                </label>
              </div>
            </div>
          </form>
        </div>

        <div
          v-if="itemId !== null"
          class="flex items-center justify-between flex-col md:flex-row mb-3"
        >
          <div v-if="showOpenItemOverviewButton" class="w-full md:w-1/3">
            <button class="btn btn-primary pl-4" @click="openItemOverview">
              <i class="fas fa-arrow-left"></i>
              {{ translations.ModalDayPlannerBackToItemOverview }}
            </button>
          </div>
          <div v-if="showOpenWarehouseViewButton" class="w-full md:w-1/3">
            <button class="btn btn-primary pl-4" @click="openWarehouseView">
              <i class="fas fa-arrow-left"></i>
              {{ translations.ModalDayPlannerBackToWarehouseOverview }}
            </button>
          </div>
          <div class="w-full md:w-1/3 text-center">
            <b>{{ currentItemName }}</b>
          </div>
          <div class="w-full md:w-1/3"></div>
        </div>
        <div class="col d-sm-block d-md-block d-lg-none d-xl-none">
          <hr />
        </div>
      </div>

      <div
        v-show="!clickedSegment"
        class="border border-dashed border-gray-900/25 rounded-md p-1"
      >
        <div class="min-h-[600px]" @click="handlePlannerClick">
          <item-availability-planner
            v-if="items.length && loaded"
            :groups="groupsForCurrentPage"
            :items="items"
            :options="options"
            @segment-click="handleSegmentClick"
          />
          <div v-else class="flex items-center justify-center min-h-[600px]">
            <span v-if="loaded">{{ translations.NoResults }}</span>
            <span v-else><i class="fas fa-spinner fa-spin text-2xl"></i></span>
          </div>
        </div>
      </div>
      <div
        v-if="
          subject === 'Rental.virtual_ItemAvailability' ||
          subject === 'Rental.virtual_SerialAvailability'
        "
        v-show="!clickedSegment"
        class="mt-1 flex justify-between items-end"
      >
        <div>
          <div id="page-selection" class="mr-0 pr-0 float-right"></div>
          <a
            id="pageResults"
            class="btn btn-secondary disabled mt-2"
            disabled
            >{{ count }}</a
          >
          <a id="pageIndicator" class="btn btn-secondary disabled mt-2" disabled
            >{{ translations.Page }} {{ pageNumber }} / {{ pageCount }}</a
          >
        </div>

        <pagination
          :changing-page="changingPage"
          :current-page="pageNumber"
          :page-count="pageCount"
          @changePage="changePage"
        />
      </div>
    </div>
  </r-modal>
</template>

<script>
import {itemPickerStockDialogVisRow} from "./itemPickerStockDialogVisRow";
import {getCategoryDropdownItems} from "@/functions/categories/getCategoryDropdownItems";
import {unixMilisecondsToDayjs} from "../../../util/formatting/unixToDayjs.js";
import {getActiveWarehouse} from "../../../util/getActiveWarehouse.js";
import {availabilityList} from "@/components/item/availabilityList";
import {getVisStockData} from "../../../functions/stock-timeline/getVisStockData.js";
import {getSettings} from "../../../util/getSettings.js";
import {openWindow} from "@/services/admin/webservices/corewebservices/openWindow";
import {scanItem} from "@/functions/item-scanner/scanItem";

import ItemAvailabilityPlanner from "../../elements/ItemAvailabilityPlanner.vue";
import MultiTreeSelectField from "@/components/form/fields/MultiTreeSelectField";
import DataFilterDateRange from "../../../../src/components/filters/filter-types/DataFilterDaterange.vue";
import DialogItemStock from "../../dialog/DialogItemStock.vue";
import Pagination from "@/components/pagination/Pagination";
import Formatter from "@/model/formatter";
import RSelect from "../../elements/RSelect.vue";
import RModal from "@/components/elements/RModal";
import dayjs from "dayjs";

export default {
  name: "ItemPickerStockDialog",
  components: {
    ItemAvailabilityPlanner,
    MultiTreeSelectField,
    DataFilterDateRange,
    DialogItemStock,
    Pagination,
    RSelect,
    RModal,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: null,
    },
    orderDates: {
      type: Object,
      required: false,
      default: () => {},
    },
    orderId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    selectedItemId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    translations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      windowData: {},
      startDate: null,
      endDate: null,
      dateFilter: {},
      categoryId: null,
      searchCriteria: null,
      groups: [],
      items: [],
      clickedSegment: null,
      options: {},
      pageNumber: 1,
      previousPageNumber: 1,
      pageCount: 1,
      showOpenItemOverviewButton: true,
      showOpenWarehouseViewButton: false,
      count: 0,
      subject: "Rental.virtual_ItemAvailability",
      pageSize: 16,
      loaded: false,
      changingPage: false,
      warehouse: null,
      selectedWarehouse: null,
      currentItemName: null,
      itemId: null,
      searching: false,
      settings: getSettings(),
      showWarehouseSelector: false,
      categorySelectField: {
        Value: null,
        options: [],
        fetchOptions: getCategoryDropdownItems,
      },
    };
  },
  computed: {
    warehouses() {
      return this.$store.state.warehouses ?? [];
    },
    groupsForCurrentPage() {
      if (
        this.subject === "Rental.virtual_ItemAvailability" ||
        this.subject === ""
      ) {
        return this.filteredGroups.slice(
          (this.pageNumber - 1) * this.pageSize,
          this.pageNumber * this.pageSize,
        );
      }
      return this.filteredGroups;
    },
    filteredGroups() {
      if (
        this.subject !== "Rental.virtual_ItemAvailability" &&
        this.subject !== ""
      ) {
        return this.groups;
      }
      if (!this.searchCriteria) {
        return this.groups;
      }
      return this.groups.filter((group) => {
        return group.content
          .toLowerCase()
          .includes(this.searchCriteria.toLowerCase());
      });
    },
  },
  watch: {
    value: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  async mounted() {
    this.warehouse = getActiveWarehouse();
    this.selectedWarehouse = this.warehouse;

    if (this.selectedItemId) {
      this.searchCriteria = this.selectedItemId;
    }
    await this.setupVisData();
  },
  methods: {
    async setupVisData({type} = {type: null}) {
      try {
        this.loaded = false;

        this.setOptions();
        if (
          this.subject === "Rental.virtual_ItemAvailability" ||
          this.subject === ""
        ) {
          const periodQuantity =
            Math.round(
              (new Date(this.endDate) - new Date(this.startDate)) /
                (1000 * 60 * 60 * 24),
            ) ?? 90;

          const visStockData = await getVisStockData({
            warehouseId: this.warehouse,
            orderId: this.orderId,
            categoryId: this.categoryId,
            startDate: dayjs(this.startDate).format("YYYY-MM-DD"),
            periodQuantity,
          });
          await this.generateVisDataFromStockData({type, visStockData});
          return;
        }

        await this.fetchWindowData();
        this.generateVisDataFromWindowData({type});
      } finally {
        this.loaded = true;
      }
    },
    setOptions() {
      this.startDate = new Date(this.pickedStartDate ?? this.orderDates.start);
      this.startDate.setHours(0, 0, 0, 0);
      this.endDate = new Date(this.pickedEndDate ?? this.orderDates.end);

      this.options = {
        start: this.startDate,
        end: this.endDate, // The initial visible enddate of the timeline
        min: this.startDate,
        max: dayjs().add(1, "years").toDate(), // The maximum visible date
        zoomMin: 1000 * 60 * 60,
        locale: global.session.language,
        editable: false,
        stack: false, // do not stack the items, group them together on 1 row,
        stackSubgroups: false,
        orientation: "both",
        autoResize: true,
        format: {
          minorLabels: {day: "DD-MM"},
        },
        showCurrentTime: true,
        showTooltips: false,
      };

      this.dateFilter = {
        type: "daterange",
        name: "Daterange",
        value: {
          startDate: this.options.start,
          endDate: this.options.end,
        },
      };
    },
    generateVisDataFromWindowData({type}) {
      this.loaded = false;
      this.resetVisData();
      let distinctedSegments = this.removeDuplicates(
        this.windowData.Data.segments,
        "segmentGroup",
      );

      distinctedSegments.forEach((dataItem) => {
        // Contains an ItemID than show serials.
        let isItemID = false;
        if (this.itemID) {
          isItemID = true;
        }
        if (this.windowData.Title === "Rental.virtual_ItemDetailAvailability") {
          dataItem.segmentGroupDescription =
            this.translations[
              "AvailabilityList_" + dataItem.segmentGroupDescription
            ];
        }

        if (!dataItem.item) dataItem.item = {};

        dataItem.originalSegmentGroupDescription =
          dataItem.segmentGroupDescription;

        dataItem.segmentGroupDescription = this.truncateString(
          dataItem.segmentGroupDescription,
          25,
        );

        dataItem.segmentGroupDescription =
          dataItem.segmentGroupDescription.replace(
            `${dataItem.item.itemID}`,
            `${dataItem.item.itemID} | `,
          );

        const htmlString = itemPickerStockDialogVisRow({
          dataItem,
          isItemID,
          settings: this.settings,
          warehouse: this.warehouse,
          currentWarehouse: getActiveWarehouse(),
          subject: this.windowData.Title,
        });

        this.groups.push({
          id: dataItem.segmentGroup,
          content: htmlString,
        });
      });

      // Output the requested data segments into the right object
      this.windowData.Data.segments.forEach(function addItems(segment, index) {
        if (this.windowData.Title === "Rental.virtual_ItemDetailAvailability") {
          segment.segmentGroupDescription =
            this.translations[
              "AvailabilityList_" + segment.segmentGroupDescription
            ];
        }

        const segmentStartDate = dayjs(unixMilisecondsToDayjs(segment.start));
        if (
          this.pickedEndDate &&
          dayjs(this.pickedEndDate).isBefore(segmentStartDate)
        ) {
          return;
        }

        const item = {
          id: index,
          group: segment.segmentGroup,
          title: this.constructTitle(segment),
          className: this.setClassName(
            segment.label,
            segment.segmentGroup.toLowerCase(),
          ),
          details: segment.details,
          label: segment.segmentGroupDescription,
          criteria: {...segment.criteria, type: type ?? null},
          content: this.formatAmount(segment.label),
          start: Formatter.unixMilisecondsToDateObject(segment.start),
          end: Formatter.unixMilisecondsToDateObject(segment.end),
        };
        this.items.push(item);
      }, this);

      this.pageCount = this.windowData.Data.pageCount;
      this.count = this.windowData.Data.count;

      this.loaded = true;
    },
    generateVisDataFromStockData({type, visStockData}) {
      this.loaded = false;
      this.resetVisData();

      this.groups = visStockData.groups.map((group) => {
        const item = {
          itemID: group.id,
          useSerials: group.stockType === "Serial",
          hasSerials: visStockData.segments.some(
            (segment) => segment.group === group.id,
          ),
        };
        return {
          ...group,
          content: itemPickerStockDialogVisRow({
            dataItem: {
              ...group,
              item,
              subject: "Rental.virtual_ItemAvailability",
              segmentGroupDescription: `${group.id} | ${group.content}`,
            },
            isItemID: group.stockType !== "Serial",
            warehouse: this.warehouse,
            currentWarehouse: getActiveWarehouse(),
            settings: this.settings,
            subject: "Rental.virtual_ItemAvailability",
            segmentGroupDescription: `${group.id} | ${group.content}`,
          }),
        };
      });

      this.items = visStockData.segments.map((segment, index) => {
        if (!this.clickedSegment) {
          segment.segmentGroupDescription =
            this.translations[
              "AvailabilityList_" + segment.segmentGroupDescription
            ];
        }

        const startDate = dayjs(segment.start);
        const endDate = dayjs(segment.end);

        return {
          id: index,
          group: segment.group,
          title: this.constructTitle(segment),
          className: this.setClassName(
            segment.value,
            segment.group.toLowerCase(),
          ),
          details: segment.details,
          label: segment.group,
          criteria: {...segment.criteria, type: type ?? null},
          content: this.formatAmount(segment.value),
          start: startDate.toDate(),
          end: endDate.toDate(),
        };
      });

      this.pageCount = Math.ceil(this.filteredGroups.length / 16);
      this.count = this.filteredGroups.length;
      this.loaded = true;
    },
    async fetchWindowData() {
      this.loaded = false;

      const startDate = dayjs(this.startDate ?? dayjs());
      const endDate = dayjs(this.endDate ?? dayjs().add(1, "months"));

      const request = {
        Subject: this.subject,
        Prefix: "View",
        Criteria: [
          {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
            categoryID: this.categoryId,
            searchCriteria: this.searchCriteria,
            itemID: this.itemId,
          },
        ],
        Data: {
          PageNumber: this.pageNumber,
          PageSize: this.pageSize,
        },
      };
      this.windowData = await openWindow({request, warehouse: this.warehouse});
    },
    setClassName(amount, segmentGroup) {
      let className = "";
      if (Number(amount) < 0) {
        className = "vis-stockNegative vis-stockNegative-border";
      } else if (Number(amount) === 0) {
        className = "vis-stockNeutral vis-stockNeutral-border";
      } else {
        className = "vis-stockPositive vis-stockPositive-border";
      }
      className = className + " vis-bar-" + segmentGroup;
      return className;
    },
    constructTitle(segment) {
      let itemId = segment.criteria.ItemID;
      let startDate = dayjs(segment.start).format("DD-MM-YYYY");

      let endDate = dayjs(segment.end).subtract(1, "days").format("DD-MM-YYYY");

      return availabilityList({
        availabilityList: segment.details,
        itemId,
        startDate,
        endDate,
        warehouse: this.warehouse,
        currentWarehouse: getActiveWarehouse(),
        translations: this.translations,
      });
    },
    handleDatePick(event) {
      if (!event.startDate && !event.endDate) {
        this.pickedStartDate = null;
        this.pickedEndDate = null;
        return this.setupVisData();
      }

      if (
        isBefore(this.startDate, event.startDate) ||
        isAfter(this.endDate, event.endDate)
      ) {
        this.pickedStartDate = new Date(event.startDate);
        this.pickedEndDate = new Date(event.endDate);
        return this.setupVisData();
      } else {
        this.startDate = new Date(event.startDate);
        this.options.start = event.startDate;

        this.endDate = new Date(event.endDate);
        this.options.end = event.endDate;
      }

      function isBefore(date1, date2) {
        return new Date(date1) < new Date(date2);
      }

      function isAfter(date1, date2) {
        return new Date(date1) > new Date(date2);
      }
    },
    async handleCategoryInput(event) {
      this.categoryId = event;
      await this.setupVisData();
    },
    handleSegmentClick(event) {
      if (event.segment?.criteria?.type === "warehouse-group")
        this.warehouse = event.segment.group;

      this.clickedSegment = event.segment;
    },
    handlePlannerClick(event) {
      const data = event.target.dataset;

      if (data?.function && this.subject === "Rental.virtual_ItemAvailability")
        this.currentItemName = event.target.closest("div[title]")?.textContent;

      if (data?.function) this[data.function](data);
    },
    async handleWarehouseChange(event) {
      this.selectedWarehouse = event;
      this.warehouse = this.selectedWarehouse;
      await this.setupVisData();
    },
    resetVisData() {
      this.groups = [];
      this.items = [];
    },
    closeItemStockDialog() {
      if (this.subject !== "Rental.virtual_SerialAvailability")
        this.warehouse = this.selectedWarehouse;
      this.clickedSegment = null;
    },
    closeDialog() {
      this.show = false;
      this.clickedSegment = null;
      this.openItemOverview();

      this.$emit("close");
    },
    openWarehouseView(data) {
      const {itemId} = data;

      this.resetVisData();
      if (this.subject === "Rental.virtual_SerialAvailability") {
        this.showOpenItemOverviewButton = true;
        this.showOpenWarehouseViewButton = false;
      }

      if (itemId) this.itemId = itemId;
      this.subject = "Rental.virtual_ItemWarehouseAvailability";
      this.warehouse = this.selectedWarehouse;
      this.setupVisData({type: "warehouse-group"});
    },
    openItemOverview() {
      if (this.subject === "Rental.virtual_SerialAvailability") {
        this.pageNumber = this.previousPageNumber;
      }
      this.resetVisData();
      this.itemId = null;
      this.currentItemName = null;

      this.subject = "Rental.virtual_ItemAvailability";
      this.setupVisData();
    },
    openItemDetailOverview(data) {
      const {itemId} = data;

      this.resetVisData();

      this.itemId = itemId;
      this.subject = "Rental.virtual_ItemDetailAvailability";
      this.setupVisData();
    },
    openItemSerialOverview(data) {
      const {itemId, warehouseId} = data;
      this.previousPageNumber = this.pageNumber;
      this.pageNumber = 1;

      this.resetVisData();

      if (this.subject === "Rental.virtual_ItemWarehouseAvailability") {
        this.showOpenItemOverviewButton = false;
        this.showOpenWarehouseViewButton = true;
      }

      this.itemId = itemId;
      this.warehouse = warehouseId;
      this.subject = "Rental.virtual_SerialAvailability";
      this.setupVisData();
    },
    async scanItem(data) {
      const {itemId, warehouseId} = data;
      const item = await scanItem({
        searchValue: itemId,
        warehouseId: warehouseId ?? this.warehouse,
      });

      this.$emit("scanItem", item);
    },
    async scanSerial(data) {
      const {serialId} = data;
      const params = {
        warehouseId: this.warehouse,
      };
      const item = await scanItem({
        searchValue: serialId,
        warehouseId: this.warehouse,
        params,
      });

      this.$emit("scanItem", item);
    },
    async changePage(page) {
      this.changingPage = true;
      this.pageNumber = page;
      await this.setupVisData();
      this.changingPage = false;
    },
    async search() {
      this.searching = true;
      this.pageNumber = 1;
      await this.setupVisData();
      this.searching = false;
    },
    removeDuplicates(originalArray, objKey) {
      let trimmedArray = [];
      let values = [];
      let value;

      for (let i = 0; i < originalArray.length; i++) {
        value = originalArray[i][objKey];

        if (values.indexOf(value) === -1) {
          trimmedArray.push(originalArray[i]);
          values.push(value);
        }
      }

      return trimmedArray;
    },
    formatAmount(amount) {
      let intAmount = Formatter.parsers.Int(amount);
      return Formatter.parsers.String(intAmount);
    },
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
  },
};
</script>

<style lang="scss">
.item-picker-stock-dialog {
  .vue-daterange-picker {
    height: 29.5px !important;
  }

  .vis-group {
    max-height: 37px;
  }

  .vis-item {
    top: 0 !important;

    .vis-group {
      max-height: 37px;
    }
  }

  .vis-label {
    max-height: 37px;
  }

  .vis-item.vis-range {
    border: none;
  }
}
</style>
