import {createAlertButtons} from "./createAlertButtons";

export const createAlertConfig = ({translations, orderID, hasPayments}) => ({
  text: hasPayments
    ? translations.DeleteOrderConfirmationWithPayments.replaceAll(
        "{orderID}",
        orderID,
      )
    : translations.DeleteOrderConfirmation.replaceAll("{orderID}", orderID),
  icon: "warning",
  buttons: [
    createAlertButtons(translations).confirmButton,
    createAlertButtons(translations).cancelButton,
  ],
});
