import {getActiveWarehouse} from "../../../util/getActiveWarehouse.js";
import {parseDateFilters} from "./parseDateFilters.js";
import {parseListFilters} from "./parseListFilters.js";

export const parseAllFilters = ({filters, searchValues, maxRows}) => {
  const parsedDateFilters = parseDateFilters({filters});
  const parsedListFilters = parseListFilters({filters});
  const filterKey = JSON.stringify(filters);

  return {
    loading: {[filterKey]: true},
    sortOn: "DateTimeExecuteEnd",
    size: maxRows,
    // search: searchValues,
    filters: {
      ...parsedListFilters,
      ...parsedDateFilters,
      Warehouse: getActiveWarehouse(),
    },
    sortReverse: false,
  };
};
