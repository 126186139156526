import {formContainsFieldWithExtraCriteria} from "../../../formContainsFieldWithExtraCriteria";
import {processDaysCalculated} from "../processDaysCalculated.js";
import {processFormExtraCriteria} from "../extra-criteria/processFormExtraCriteria";
import {processFormPostback} from "../postback/processFormPostback";

export const processFormFieldChangeWithDates = async ({
  windowData,
  changedField,
  windowId,
  vueComponent,
}) => {
  let newData = {...windowData};

  if (changedField) {
    newData.Rows[0][changedField.Name] = changedField.Value;
  }

  newData = processDaysCalculated({newData, changedField});

  if (changedField?.IsPostback)
    newData = await processFormPostback({
      windowId: windowId,
      windowData: newData,
      vueComponent,
    });

  if (
    changedField &&
    formContainsFieldWithExtraCriteria({fields: newData.Columns})
  )
    newData = await processFormExtraCriteria({
      changedField,
      windowData: newData,
    });

  return newData;
};
