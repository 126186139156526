<template>
  <div class="max-w-[15rem] w-[15rem]">
    <date-field
      :field="{Value: filter.Selected, IsReadOnly: false}"
      :placeholder="placeholder"
      :required="false"
      @input="handleChange"
    />
  </div>
</template>
<script>
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import DateField from "./FormFilterBarFilterOptionsDateField.vue";

const translations = getTranslations();

export default {
  name: "TableFormSearchBarFilterDates",
  components: {
    DateField,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      translations,
    };
  },
  computed: {
    placeholder() {
      return translations[this.filter.Target];
    },
  },
  methods: {
    handleChange(value) {
      this.$emit("filter-change", {filter: this.filter, value});
    },
  },
};
</script>
