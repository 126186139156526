<template>
  <r-select
    :value="activeWarehouse"
    :options="warehouses"
    input-id="warehouseSelector"
    :no-options-found-placeholder="translations.translations.NoRowsFound"
    :clearable="false"
    @option-selected="$emit('change-warehouse', $event)"
  />
</template>

<script>
import RSelect from "../elements/RSelect.vue";

export default {
  components: {
    RSelect,
  },
  inject: ["translations"],
  props: {
    activeWarehouse: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    warehouses() {
      return this.$store.state.warehouses ?? [];
    },
  },
};
</script>