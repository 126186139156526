export function setClosedRowPropsReadOnly({row, translations}) {
  if (row.Status.Value === translations.Closed) {
    const exemptedColumns = [
      "Notes",
      "VATCodeID",
      "VisibilityPolicy",
      "PriceCalcReason",
    ];

    const newRow = {...row};
    // Loop over every property in row and set IsReadOnly to true
    for (const property in row) {
      if (row[property] && !exemptedColumns.includes(row[property].Name)) {
        row[property].IsReadOnly = true;
      }
    }
    return newRow;
  }
  return row;
}
