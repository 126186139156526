export const processGlobalActions = async ({
  globalActions,
  currentWindowId,
}) => {
  if (!globalActions) {
    return;
  }

  for (const action of globalActions) {
    const importedModule = await import(`@/actions/${action.Function}.js`);
    await importedModule.default(
      action.Data,
      currentWindowId ?? this?.id ?? null,
    );
  }
};
