import {getWindowFromWindowId} from "../window/getWindowFromWindowId.js";
import {getActiveWindow} from "../window/getActiveWindow.js";

export const processLegacyWindowForm = ({rWindowData, windowId}) => {
  const legacyWindow =
    getWindowFromWindowId({windowId, session: global.session}) ??
    getActiveWindow();

  const newRow = rWindowData.Rows[0];
  const newColumns = rWindowData.Columns;
  const legacyRow = legacyWindow.output?.Table?.Rows[0];

  if (!newRow || !legacyRow) return;

  legacyRow.filter((legacyCell) =>
    Object.keys(newRow).includes(legacyCell?.Column?.Name),
  );

  legacyRow.forEach((cell) => {
      if (!cell.Column) return
    cell.NewValue = newRow[cell.Column.Name];

    if (newColumns[cell.Column.Name]) {
      cell.IsDirty = newColumns[cell.Column.Name]?.IsDirty;
      cell.Column = {
        ...cell.Column,
        IsReadOnly: newColumns[cell.Column.Name]?.IsReadOnly,
      };
    }
  });

  // Ensure that all columns that are present in the form are used in Action.js
  // This is necessary because the legacy row does not get updated with new columns after postback calls anymore.
  Object.keys(newRow).forEach((key) => {
    if (!legacyRow.find((legacyCell) => legacyCell?.Column?.Name === key)) {
      legacyRow.push({
        Column: newColumns[key],
        NewValue: newRow[key],
        IsDirty: newColumns[key]?.IsDirty,
      });
    }
  });
};
