import {alertPopup} from "../../interface/alertPopup/alertPopup";
import {getTranslations} from "../session/localstorage/getTranslations";
const translations = getTranslations()
export const showUpdatePrompt = async () => {
    const result = await alertPopup({
        text: translations.NewVersionMessage,
        icon: 'warning',
        dangerMode: true,
        buttons: [{
            text: translations.RemindMeLater,
            value: false,
            variant: "secondary",
        }, {
            text: translations.UpdateNow,
            value: true,
            variant: "primary",
        }],
        size: 'large'
    });

    if (result === true) {
        window.location.reload(true);
    }
    return result
};
