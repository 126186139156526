<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center relative"
    >
      <i
        class="fas fa-spinner fa-spin text-3xl absolute right-0 -top-5 mt-3"
      ></i>
    </div>
    <div ref="datagrid">
      <DatagridStandalone
        v-if="loaded && columns.length"
        :rows="rows"
        :columns="columns"
        :window-id="rWindow.id"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :page-size="pageSize"
        :process-context="{context: 'ReturnItem'}"
        :column-value="headerCheckbox"
        @loading="loading = $event"
        @rows-updated="handleRowsUpdated"
        @cell-click="handleCellClick($event)"
        @column-header-change="processColumnHeaderCheckboxClick"
        @page-change="handlePageChange({page: $event})"
        @page-size-change="handlePageSizeChange"
      >
        <template #header>
          <datagrid-header-return-item
            :form-fields="formFields"
            :page-size="pageSize"
            :rows="rows"
            @form-input="handleFormInput"
            @page-size-change="handlePageSizeChange"
          />
        </template>

        <template #footer>
          <datagrid-return-item-footer
            v-if="rows.length"
            :page="page"
            :page-size="pageSize"
            :rows="rows"
            :loading-page="loadingPage"
            @page-change="handlePageChange({page: $event})"
          ></datagrid-return-item-footer>
        </template>
      </DatagridStandalone>
    </div>
  </div>
</template>

<script>
import {processRowsDateTimeRentalEndMetadata} from "../../../functions/datagrid/return-item/processRowsDateTimeRentalEndMetadata.js";
import {handleReturnWindowOpenedFromOrder} from "../../../functions/datagrid/return-item/handleReturnWindowOpenedFromOrder.js";
import {processReturnItemAdvancedColumns} from "../../../functions/datagrid/return-item/processReturnItemAdvancedColumns.js";
import {processReturnItemServiceColumn} from "../../../functions/datagrid/return-item/processReturnItemServiceColumn.js";
import {initializeReturnItemFormFields} from "../../../functions/datagrid/return-item/initializeReturnItemFormFields.js";
import {processReturnItemRowsMetaData} from "../../../functions/datagrid/return-item/processReturnItemRowsMetaData.js";
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {handleServiceCheckboxClick} from "../../../functions/datagrid/return-item/handleServiceCheckboxClick.js";
import {validateReturnItemRowClick} from "../../../functions/datagrid/return-item/validateReturnItemRowClick.js";
import {validateReturnItemRowCheck} from "../../../functions/datagrid/return-item/validateReturnItemRowCheck.js";
import {toggleHeaderCheckboxState} from "../../../functions/datagrid/return-item/toggleHeaderCheckboxState.js";
import {processColumnsMetaData} from "../../../functions/datagrid/return-item/processColumnsMetaData";
import {resetReturnItemScanbox} from "../../../functions/datagrid/return-item/resetReturnItemScanbox.js";
import {setReturnScanboxItems} from "../../../functions/datagrid/return-item/setReturnScanboxItems.js";
import {toggleAllRowsChecked} from "../../../functions/datagrid/return-item/toggleAllRowsChecked.js";
import {processRowForReturn} from "../../../functions/datagrid/return-item/processRowForReturn.js";
import {checkReturnableRow} from "../../../functions/datagrid/return-item/checkReturnableRow.js";
import {processColumnTypes} from "../../../functions/datagrid/processColumnTypes.js";
import {sortReturnItemRows} from "../../../functions/datagrid/return-item/sortReturnItemRows.js";
import {normalizeValueRows} from "../../../functions/datagrid/normalizeValueRows.js";
import {handleReturnResult} from "../../../functions/datagrid/return-item/handleReturnResult.js";
import {handleJobExecution} from "../../../functions/datagrid/actions/handleJobExecution.js";
import {handleRowsUpdated} from "../../../functions/datagrid/return-item/handleRowsUpdated.js";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";
import {createReturn} from "../../../services/return-item/createReturn.js";
import {sortColumns} from "../../../functions/datagrid/columns/sortColumns";
import {getColumns} from "../../../functions/datagrid/columns/getColumns";
import {getUserId} from "../../../functions/session/getUserId";
import {notify} from "../../../util/notify.js";

import DatagridHeaderReturnItem from "../datagridHeaders/DatagridHeaderReturnItem.vue";
import DatagridReturnItemFooter from "../datagridFooters/DatagridReturnItemFooter.vue";
import DatagridStandalone from "../DatagridStandalone.vue";

export default {
  Name: "DatagridReturnItem",
  components: {
    DatagridReturnItemFooter,
    DatagridHeaderReturnItem,
    DatagridStandalone,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addItemQueue: [],
      loadingPage: false,
      formFields: {},
      pageSize: 15,
      loading: false,
      columns: [],
      loaded: false,
      rows: [],
      page: 1,
      translations: getTranslations(),
      OnlyAutoCheckIfAmountEqualsAmountToReturn: false,
      OnlyShowScan: false,
      headerCheckbox: {
        Value: false,
        IsReadOnly: false,
      },
    };
  },
  computed: {
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Rental.virtual_Return-Multi`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    ({
      OnlyAutoCheckIfAmountEqualsAmountToReturn:
        this.OnlyAutoCheckIfAmountEqualsAmountToReturn,
      OnlyShowScan: this.OnlyShowScan,
    } = this.rWindow.data);

    this.formFields = await initializeReturnItemFormFields({
      window: this.rWindow,
      settings: this.settings,
      translations: this.translations,
    });

    let columns = await getColumns({
      table: "Rental.virtual_Return",
      prefix: "New",
    });

    columns = processColumnTypes({columns});
    columns = processColumnsMetaData({columns});
    columns = processReturnItemAdvancedColumns({columns});
    this.columns = sortColumns(columns);

    const windowCriteria = this.rWindow.criteria?.[0] ?? null;
    if (windowCriteria && Object.keys(windowCriteria)[0] === "Order") {
      await handleReturnWindowOpenedFromOrder({
        vueComponent: this,
        criteria: windowCriteria,
      });
    }

    this.loaded = true;
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async handleFormInput(event) {
      const module = await import(
        `../../../functions/datagrid/return-item/form-handlers/handle${event.Name}FieldChange.js`
      );
      const handleEventFunction = module[`handle${event.Name}FieldChange`];

      await handleEventFunction({event: event, vueComponent: this});
    },
    handleRowsUpdated(event) {
      handleRowsUpdated({...event, vueComponent: this});
    },
    loadAndApplyRowDataForPage({rows}) {
      this.rows = [];

      if (!rows) return;

      if (rows?.length < 1) {
        this.formFields.DateTimeReturned.IsReadOnly = true;
        this.formFields?.BusinessHourEnd
          ? (this.formFields.BusinessHourEnd.IsReadOnly = true)
          : null;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: false,
          },
        });
      } else {
        this.formFields.DateTimeReturned.IsReadOnly = false;
        this.formFields?.BusinessHourEnd
          ? (this.formFields.BusinessHourEnd.IsReadOnly = false)
          : null;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: true,
          },
        });
      }

      let newRows = rows.slice();

      newRows = processReturnItemRowsMetaData({
        rows: newRows,
        vueComponent: this,
      });

      newRows = processRowsDateTimeRentalEndMetadata({
        rows: newRows,
        newValue: this.formFields.ChangeRentalEndDate.Value,
        vueComponent: this,
      });

      newRows = sortReturnItemRows({rows: newRows});

      this.rows = newRows;
      this.toggleColumnHeaderCheckbox();

      this.columns = processReturnItemServiceColumn({
        columns: this.columns,
        rows: this.rows,
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    async save(job) {
      try {
        if (this.loading) return false;
        this.$store.state.loading = true;
        document.activeElement.blur();

        while (this.loading || this.loadingPage) {
          await new Promise((r) => setTimeout(r, 0));
        }

        const returnItems = this.rows
          .filter((row) => row.Checked.Value)
          .map((row) => {
            return processRowForReturn({row, formFields: this.formFields});
          });

        if (!returnItems.length || !returnItems) {
          notify({
            type: "warning",
            message: this.translations.AdminDesktop_AlertNoRowsSelected,
          });
          return false;
        }
        const currentDirtyState = this.rWindow.data.dirty;
        this.$emit("data-change", {
          windowId: this.rWindow.id,
          newData: {
            ...this.rWindow.data,
            dirty: false,
          },
        });

        const normalizedReturnRows = normalizeValueRows({rows: returnItems});

        const result = await createReturn({returnItems: normalizedReturnRows});

        await handleReturnResult({
          result,
          currentDirtyState,
          vueComponent: this,
        });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        if (this.rWindow.data.dirty && !(await confirmDiscardUnsavedChanges()))
          return false;

        this.loadingPage = true;
        this.rows = [];

        await new Promise((resolve) =>
          setTimeout(async () => {
            this.formFields = await initializeReturnItemFormFields({
              window: this.rWindow,
              settings: this.settings,
              translations: this.translations,
            });
            toggleHeaderCheckboxState({vueComponent: this, value: false});

            this.loadingPage = false;
            this.$emit("data-change", {
              windowId: this.rWindow.id,
              newData: {
                ...this.rWindow.data,
                dirty: false,
              },
            });
            resolve();
          }, 125),
        ).then(() => {
          if (this.formFields.Scan.Dropdown?.AutoFocus)
            resetReturnItemScanbox({vueComponent: this});
        });
      } finally {
        if (job) this.$emit("job-completed", job);
      }
      return false;
    },
    async handleCellClick(event) {
      if (event.name === "PerformServiceBeforeReturn") {
        this.rows = handleServiceCheckboxClick({
          rows: this.rows,
          event: event,
        });
      }

      if (validateReturnItemRowClick({event})) {
        await this.handleRowClick(event);
      }
    },
    async handleRowClick(event) {
      if (
        !event?.row.Checked.Value &&
        !(await validateReturnItemRowCheck({row: event?.row}))
      )
        return;

      this.rows = checkReturnableRow({
        rows: this.rows,
        event: event,
      });

      this.toggleColumnHeaderCheckbox();

      setTimeout(() => {
        this.rows = sortReturnItemRows({
          rows: this.rows,
        });
      }, 130);
    },
    async processColumnHeaderCheckboxClick(event) {
      if (event.column.Name === "Checked")
        this.rows = await toggleAllRowsChecked({
          rows: this.rows,
          value: event.value.Value,
          vueComponent: this,
        });
      this.toggleColumnHeaderCheckbox();
    },
    toggleColumnHeaderCheckbox() {
      this.headerCheckbox.Value = this.rows.every((row) => row.Checked.Value);
      this.formFields = setReturnScanboxItems({
        rows: this.rows,
        formFields: this.formFields,
      });
    },
    handlePageChange({page}) {
      this.page = page;
      this.loadAndApplyRowDataForPage({
        rows: this.rows,
      });
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
      this.loadAndApplyRowDataForPage({
        rows: this.rows,
      });
    },
    navigateToPage({page}) {
      this.page = page;
      this.loadAndApplyRowDataForPage({
        rows: this.rows,
      });
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
