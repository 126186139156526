<template>
  <div class="relative w-full h-full">
    <div class="interface" :style="styleOverrides">
      <alert-popup-manager />
      <content class="flex h-full">
        <side-menu v-if="session.moduleMenu" :menu-items="session.moduleMenu" />

        <div
          v-show="token !== null"
          id="scrollContainer"
          :class="{canvas: true}"
        >
          <div :class="{'container-fluid': !headless}">
            <interface-header
              :session="session"
              :settings="settings"
              :headless="headless"
              :active-warehouse="activeWarehouse"
              @close-all-windows="handleCloseAllWindows"
              @change-warehouse="handleWarehouseChange"
            />
            <div class="flex flex-wrap no-gutters">
              <!-- Actionbar -->
              <action-bar
                v-if="showActionBar === true && !headless"
                :active-window-id="activeWindowId"
                :active-sub-window-id="activeSubRWindowId"
                @new-job="handleNewJob"
              />
              <!-- End Actionbar -->

              <!-- START Canvas -->
              <div v-show="window.id" id="window" :class="windowClasses">
                <div id="cardCanvas" class="card h-100 mb-3 card-canvas">
                  <div class="card-body">
                    <canvas-template
                      v-if="token"
                      :window="window"
                      :settings="settings"
                      :r-windows="rWindows"
                      :active-r-window="activeRWindow"
                      :active-r-sub-window="activeSubRWindow"
                      @data-change="handleWindowDataChange($event)"
                      @initial-data-change="
                        handleInitialWindowDataChange($event)
                      "
                      @new-job="handleNewJob"
                      @job-completed="handleJobCompleted"
                    />
                  </div>
                </div>
              </div>
              <!-- END Canvas -->
            </div>
            <back-to-top />
          </div>
        </div>
      </content>
      <webinar-hint v-if="webinar !== false" />
      <interface-environment-title />

      <Dialog />
    </div>
    <portal-target name="modal"></portal-target>
  </div>
</template>

<script>
import {getFormattedSettings} from "../../functions/settings/getFormattedSettings.js";
import {getTranslations} from "../../functions/session/localstorage/getTranslations";

import InterfaceEnvironmentTitle from "./InterfaceEnvironmentTitle.vue";
import AlertPopupManager from "./AlertPopupManager.vue";
import {PortalTarget} from "portal-vue";
import InterfaceHeader from "./InterfaceHeader.vue";
import {disposeWindowAndSubs} from "./util/disposeWindowAndSubs";
import {getWindowClasses} from "./util/getWindowClasses";
import {handleInitialWindowDataChange} from "./util/handleInitialWindowDataChange";
import {handleJobCompleted} from "./util/handleJobCompleted";
import {handleNewJob} from "./util/handleNewJob";
import {handleTabSelected} from "./util/handleTabSelected";
import {handleWindowDataChange} from "./util/handleWindowDataChange";
import WebinarHint from "./WebinarHint.vue";
import BackToTop from "./BackToTop.vue";
import ActionBar from "./actionBar/actionBar.vue";
import Dialog from "../dialog/Dialog.vue";
import Canvas from "../canvas/CanvasTemplate.vue";
import Menu from "./menu/menu.vue";
import Vue from "vue";
import {handleSubWindowFocus} from "./util/handleSubWindowFocus";
import {handleWindowFocus} from "./util/handleWindowFocus";
import {handleWindowRender} from "./util/handleWindowRender";
import {updateWindowOverwriteRows} from "./util/updateWindowOverwriteRows";
import {handleCloseAllWindows} from "./util/handleCloseAllWindows";
import {upsertWindow} from "./util/upsertWindow";
import {handleWarehouseChange} from "./util/handleWarehouseChange";
import {handleReferencedWindowFormFieldChange} from "./util/handleReferencedWindowFormFieldChange.js";
import setDocumentTitle from "../../functions/setDocumentTitle";

export default {
  name: "RInterface",
  components: {
    InterfaceHeader,
    "canvas-template": Canvas,
    "action-bar": ActionBar,
    "side-menu": Menu,
    InterfaceEnvironmentTitle,
    AlertPopupManager,
    PortalTarget,
    WebinarHint,
    BackToTop,
    Dialog,
  },
  provide() {
    return {
      translations: Vue.observable(this.translationsWrapper),
    };
  },
  data() {
    return {
      initialized: true,
      activeWindowId: null,
      activeSubWindowId: null,
      session: [],
      settings: {},
      rWindows: {},
      translationsWrapper: {
        translations: {},
      },
    };
  },
  computed: {
    activeRWindow() {
      return this.rWindows[this.activeWindowId];
    },
    activeSubRWindow() {
      if (!this.activeRWindow) return null;
      return this.rWindows[this.activeRWindow.subWindowId];
    },
    activeSubRWindowId() {
      if (!this.activeSubRWindow) return null;
      return this.activeSubRWindow.id;
    },
    activeWarehouse() {
      return this.$store.state.activeWarehouse;
    },
    headless() {
      return this.$store.state.headless;
    },
    token: function () {
      return this.$store.state.accessToken;
    },
    styleOverrides() {
      if (this.$store.state.loading) {
        return "pointer-events none !important;";
      }
      return null;
    },
    selectedWindow() {
      return this.$store.getters.selectedWindow;
    },
    window() {
      return this.$store.state.window;
    },
    webinar() {
      return this.selectedWindow.output?.Options?.Webinar ?? false;
    },
    iframeView() {
      return (
        this.selectedWindow?.output?.Request?.Subject === "Core.virtual_Iframe"
      );
    },
    hideActionBar() {
      if (this.iframeView) return true;

      if (this.simpleInterface === false) return true;
      return this.$store.getters.hideSidebar;
    },
    simpleInterface() {
      return this.$store.getters.simpleInterface;
    },
    showActionBar() {
      return this.$store.getters.showActionBar;
    },
    windowClasses() {
      return getWindowClasses({
        headless: this.headless,
        showActionBar: this.showActionBar,
        hideActionBar: this.hideActionBar,
      });
    },
  },
  mounted() {
    global.eventBus.on("afterFocus", this.handleWindowFocus);
    global.eventBus.on("afterFocusSub", this.handleSubWindowFocus);
    global.eventBus.on("afterRender", this.handleWindowRender);
    global.eventBus.on("settingsUpdated", this.updateSettings);
    global.eventBus.on("afterFetch", this.upsertWindow);
    global.eventBus.on("afterDispose", this.disposeWindowAndSubs);
    global.eventBus.on("afterUpdateWindow", this.updateWindowOverwriteRows);
    global.eventBus.on(
      "afterWindowReload",
      this.updateWindowOverwriteRowsAndInitialState,
    );
    global.eventBus.on(
      "afterInsert",
      this.updateWindowOverwriteRowsAndInitialState,
    );
    global.eventBus.on("tabSelected", this.handleTabSelected);
    global.eventBus.on(
      "referencedWindowFormFieldChange",
      this.handleReferencedWindowFormFieldChange,
    );
  },

  beforeDestroy() {
    global.eventBus.off("afterFocus", this.handleWindowFocus);
    global.eventBus.off("afterFocusSub", this.handleSubWindowFocus);
    global.eventBus.off("afterRender", this.handleWindowRender);
    global.eventBus.off("settingsUpdated", this.updateSettings);
    global.eventBus.off("afterFetch", this.upsertWindow);
    global.eventBus.off("afterDispose", this.disposeWindowAndSubs);
    global.eventBus.off("afterUpdateWindow", this.updateWindowOverwriteRows);
    global.eventBus.off(
      "afterWindowReload",
      this.updateWindowOverwriteRowsAndInitialState,
    );
    global.eventBus.off("tabSelected", this.handleTabSelected);
    global.eventBus.off(
      "referencedWindowFormFieldChange",
      this.handleReferencedWindowFormFieldChange,
    );
  },
  async created() {
    this.session = global.session;
    await this.updateSettings();
    this.setTranslations();
    setDocumentTitle(null, this.activeWarehouse);
  },
  methods: {
    handleCloseAllWindows() {
      handleCloseAllWindows({vueInstance: this});
    },
    handleWarehouseChange(warehouse) {
      handleWarehouseChange({vueInstance: this, newWarehouse: warehouse});
    },
    handleNewJob(job) {
      handleNewJob({
        job,
        vueInstance: this,
        windowId:
          job.windowId ?? this.activeSubRWindow?.id ?? this.activeRWindow.id,
      });
    },
    handleJobCompleted(job) {
      handleJobCompleted({
        job,
        vueInstance: this,
        windowId: job.windowId ?? this.activeSubWindowId ?? this.activeWindowId,
      });
    },
    async updateSettings() {
      this.settings = await getFormattedSettings({cached: true});
    },
    setTranslations() {
      this.translationsWrapper.translations = getTranslations();
    },
    // update if window is found, add if not to this.windows match on window.id
    upsertWindow(window) {
      upsertWindow({vueInstance: this, window});
    },
    updateWindowOverwriteRows(window) {
      updateWindowOverwriteRows({vueInstance: this, window});
    },
    updateWindowOverwriteRowsAndInitialState(window) {
      updateWindowOverwriteRows({
        vueInstance: this,
        window,
        updateInitialState: true,
      });
    },
    handleTabSelected(actionName) {
      handleTabSelected({vueInstance: this, actionName});
    },
    disposeWindowAndSubs(window) {
      disposeWindowAndSubs({vueInstance: this, window});
    },
    handleWindowDataChange({newData, windowId}) {
      handleWindowDataChange({newData, windowId, vueInstance: this});
    },
    handleReferencedWindowFormFieldChange({windowId, rWindowData}) {
      handleReferencedWindowFormFieldChange({
        rWindowData,
        windowId,
        vueInstance: this,
      });
    },
    handleInitialWindowDataChange({newData, windowId}) {
      handleInitialWindowDataChange({newData, windowId, vueInstance: this});
    },
    handleWindowRender(window) {
      handleWindowRender({vueInstance: this, window});
    },
    handleWindowFocus(window) {
      handleWindowFocus({vueInstance: this, window});
    },
    handleSubWindowFocus(window) {
      handleSubWindowFocus({vueInstance: this, window});
    },
  },
};
</script>

<style lang="scss">
.avoid-clicks {
  pointer-events: none !important;
}

.active-sidebar {
  .card {
    border-top-left-radius: 0 !important;
    margin-left: -14px;
  }
}

.card-canvas {
  z-index: 0;
}
</style>
